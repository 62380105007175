import CustomList from "components/CustomList/CustomList";
import IProps from "interfaces/IProps";
import { useState } from "react";
import "./TabularSection.css";
import TabularSectionData from "interfaces/TabularSectionData";



interface Props extends IProps {
  activeHeader: any;
  tabularSectionDataList: TabularSectionData[];
  setActiveHeader: (e: any) => void;
  returnData: any,
  refundsList: any,
  orderDetails: any
}



const TabularSection = ({ tabularSectionDataList, activeHeader, setActiveHeader,returnData, refundsList, orderDetails }: Props) => {
  
  const [canShow, setCanShow] = useState(false);
  const { columnHeadings, tableContent, customContent} = tabularSectionDataList.filter(
    (tabularSectionData) => tabularSectionData.key === activeHeader
  )[0];

  const changeTab = (newTabKey: string) => {
    setActiveHeader(newTabKey);
  };

  return (
    <div className="TabularSection">
      <h3>Order details: </h3>
      <div className="TabHeaders">
        {tabularSectionDataList.map((tabularSectionData) => (
          <div
            className={`Container ${activeHeader === tabularSectionData.key ? "active" : ""
              }`}
            onClick={() => changeTab(tabularSectionData.key)}
          >
            <div className="content">{tabularSectionData.heading}</div>
          </div>
        ))}
      </div>
      <div className="TabContent">
        <div className= "tabContainer">
            {
                !!customContent ?
                    customContent :
                    <CustomList
                      columnHeadings={columnHeadings}
                      listOfItems={tableContent}
                      totalNoPages={1}
                      currentPage={1}
                      changePage={() => { }}
                      changeSortingOrder={() => { }}
                      refundsList={refundsList}
                      orderDetails={orderDetails}
                    />
            }
        </div>
      </div>

     


    </div>
  );
};

export default TabularSection;
