import ColumnHeadings from "interfaces/ColumnHeadings";

const COLUMN_HEADINGS: ColumnHeadings[] = [
    {
        propertyName: "orderNumber",
        displayName: "Order Number",
        id: "orderNumber",
        internalPropertyName: "orderNumber",
        filterOptions: [],
    },
    {
        propertyName: "billingFirstName",
        displayName: "Billing First Name",
        id: "billingFirstName",
        internalPropertyName: "billingFirstName",
        filterOptions: [],
    },
    {
        propertyName: "billingLastName",
        displayName: "Billing Last Name",
        id: "billingLastName",
        internalPropertyName:"billingLastName",
        filterOptions: [], 
    },
    {
        propertyName: "appointmentDate", 
        displayName: "Appointment Date",
        id: "appointmentDate",
        internalPropertyName: "appointmentDate",
        filterOptions: [],
    },
    {
        propertyName: "bookingStatus",
        displayName: "Booking Status",
        id: "bookingStatus",
        internalPropertyName: "bookingStatus",
        filterOptions: ["RESERVED","CUSTOMER_CONFIRMED","INSTALLER_CONFIRMED","INSTALLATION_COMPLETE"],
    },
    {
        propertyName: "salesChannel",
        displayName: "Sales Channel",
        id: "salesChannel",
        internalPropertyName: "salesChannel",
        filterOptions: ["WEBSITE", "EBAY", "PURCHASINGPOWER", "TRANSFORMCO_SEARS","CA_AMAZON","CA_WALMART","CA_EBAY","CA","RAC"],
    },
    {
        propertyName: "bookingDeliveryStatus",
        displayName: "Delivery Status",
        id: "bookingDeliveryStatus",
        internalPropertyName: "bookingDeliveryStatus",
        filterOptions: ["NONE", "DELIVERED", "PARTIAL_ITEM_RECEIVED", "ITEMS_NOT_RECEIVED", "DELIVERY_ISSUE", "NO_ANSWER", "VOICEMAIL", "OTHER"]
    },
    {
        propertyName: "installerName",
        displayName: "Installer Name",
        id: "installerName",
        internalPropertyName: "installerName",
        filterOptions: [],
    },
    {
        propertyName: "paymentMethod",
        displayName: "Payment Method",
        id: "paymentMethod",
        internalPropertyName: "paymentMethod",
        filterOptions: ["CARD", "WEEKLY_ACH", "MONTHLY_ACH"]
    },
    {
        propertyName: "installPaid",
        displayName: "Install Paid",
        id: "installPaid",
        internalPropertyName: "installPaid",
        filterOptions: ["YES", "NO"],
    }
];

export { COLUMN_HEADINGS };