import IProps from "interfaces/IProps";
import SubSectionRowData from "interfaces/SubSectionRowData";
import "./SubSectionRow.css";

interface Props extends IProps {
  subSectionRowData: SubSectionRowData;
}

const SubSectionRow = ({ subSectionRowData }: Props) => {
  return (
    <tr>
      <th className="padTen">
        <div>
          {subSectionRowData.title} :
        </div>
      </th>
      <td className="padTen">
        {subSectionRowData.content}
      </td>
    </tr>
  );
};

export default SubSectionRow;
