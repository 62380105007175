import { useState } from "react";
import "./ActionsSection.css";
//import React-Modal from 'react-modal';
import ModalSection from "../ModalSection/ModalSection";
import IProps from "interfaces/IProps";
interface Props extends IProps {
  noteData: any,
  orderData: any,
  orderStatus: any,
  createNote: (e: any) => void;
  // cancelOrder: (e: any) => void;
  cancelOrder: () => void;
  compensationRequest: (e: any) => void;
  returnRequest: (e: any) => void;
  replacementRequest: (e: any) => void;
}


const ActionsSection = ({
  noteData,
  orderData,
  orderStatus,
  createNote,
  cancelOrder,
  compensationRequest,
  returnRequest,
  replacementRequest,
}: Props) => {
  const [canShow, setCanShow] = useState(false);
  const [action, setAction] = useState('');

  const onCreateNote = () => {
    setCanShow(true);
    setAction('Create');
  }
  const onCancelOrder = () => {
    setCanShow(true);
    setAction('Cancel');
  }
  const onCompensationRequest = () => {
    setCanShow(true);
    setAction('Compensation');
  }
  const onReturnRequest = () => {
    setCanShow(true);
    setAction('Return');
  }
  const onReplacementRequest = () => {
    setCanShow(true);
    setAction('Replacement');
  }
  return (
    <div className="ActionsSection">
      <div className="label">Actions:</div>
      <div className="Actions">
        {orderStatus && <div className="ActionsRow">
          <button className="ActionButton componentLevelStyles" 
        //   disabled={
        //     orderStatus.toLowerCase() != ('completed' ||  'cancelled')
        // }
            onClick={onReplacementRequest}>Replacement Request</button>
          <button className="ActionButton componentLevelStyles" onClick={onCancelOrder} disabled={orderStatus.toLowerCase() == 'cancelled'}>Cancel Order</button>
        </div>}
        {<div className="ActionsRow">
          <button className="ActionButton componentLevelStyles" onClick={onCreateNote} disabled={false}>Create Note</button>
          
        </div> /* suppressed as part ofAxis Stage UI as of 21st sept 2022 */ }
      </div>
      <ModalSection
        canShow={canShow}
        action={action}
        setCanShow={setCanShow}
        createNote={createNote}
        noteData={noteData}
        cancelOrder={cancelOrder}
        orderData={orderData}
        compensationRequest={compensationRequest}
        returnRequest={returnRequest}
        replacementRequest={replacementRequest}
      />
    </div>
  );
};

export default ActionsSection;
