import { useState } from "react";
import "./BookingActionsSection.css";
import BookingModalSection from "../BookingModalSection/BookingModalSection";
import IProps from "interfaces/IProps";

interface Props extends IProps {
  orderData: any,
  orderStatus: any,
  bookingsData: any,
  saveBooking: () => void;
  cancelBooking: () => void;
}

const BookingActionsSection = ({
  orderData,
  orderStatus,
  bookingsData,
  saveBooking,
  cancelBooking,
}: Props) => {
  const [canShow, setCanShow] = useState(false);
  const [action, setAction] = useState('');

  const onCancelBooking = () => {
    setCanShow(true);
    setAction('Cancel');
  }

  const onSaveBooking = () => {
    setCanShow(true);
    setAction('Save');
  }

  return (
    <div className="BookingActionsSection">
      <div className="Actions">
      <div className="label">Actions:</div>
        {orderStatus &&
          <div className="ActionsRow">
            <button className="ActionButton" onClick={onSaveBooking}
                    disabled={orderStatus.toLowerCase() == 'saved'}>
              Confirm Booking
            </button>
            <button className="ActionButton" onClick={onCancelBooking}
                    disabled={orderStatus.toLowerCase() == 'cancelled' ||
                    bookingsData.bookingStatus.toLowerCase() == "cancelled"}>
              Cancel Booking
            </button>
          </div>
        }
      </div>
      <BookingModalSection
        canShow={canShow}
        action={action}
        setCanShow={setCanShow}
        saveBooking={saveBooking}
        cancelBooking={cancelBooking}
        orderData={orderData}
      />
    </div>
  );
};

export default BookingActionsSection;
