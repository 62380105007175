import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const LogIn = () => {
    const { instance } = useMsal();
    instance.handleRedirectPromise().then((response) => {
        if (response && response.account) {
            return response.account;
        }
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    });
    return null;
}
export default LogIn;