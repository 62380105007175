import DropdownOptions from "interfaces/DropdownOptions";
import IProps from "interfaces/IProps";
import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { RETAILER_DROPDOWN_OPTIONS } from "utils/constants/FindOrderPageDropdownOptions";
import ModalSection from "../RetailerPage/ModalSection/ModalSection";
import RestService from "../../services/RestService";
import { toast } from "react-toastify";
import { transpileModule } from "typescript";
import RetailerTabs from "./RetailerTabs";
import "./OrdersPage.css";

// import qs from "qs";

interface Props extends IProps {
    readonly orderNumber: any;
    readonly Tab:any;
}






const RetailerUploadPage = ({
}: Props) => {
    let { id } = useParams<{ id: string }>();
    const [selectedDropdown, setSelectedDropdown] = useState(RETAILER_DROPDOWN_OPTIONS.find(x => x.id == 1));
    // source image
    const [image, setImage] = useState<any>(null);
    const [processedData, setProcessedData] = useState<any>(null);
    const [canShow, setCanShow] = useState(false);
    const [action, setAction] = useState('');
    const [installerData, setInstallerData] = useState<any>();

    const history = useHistory();


    const getInstallerData = () => {
        const successCallback = (response: any) => {
            console.log(response);
            setInstallerData(response);
        }
        const url = "/api/installer-service/installers/" + id;
        //TODO: Manage console.error
        RestService.getRequest(url, successCallback, (err) =>
            console.log(err)
        );
    }
    useEffect(() => {
        getInstallerData();
    }, [])

    const onDropdownChange = (e: any) => {
        const dropdownOption: DropdownOptions | undefined = RETAILER_DROPDOWN_OPTIONS.find(
            (option) => option.id == e.target.value
        );
        setSelectedDropdown(dropdownOption);
    };
    const onImageChange = (event: any) => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (img.size < 5242880) { //check for 5 MB
                setImage(img); //URL.createObjectURL(img)
            } else {
                toast.error("File size should be less than 5mb.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error'
                });
            }
        }
    };

    const uploadImage = (event?: any) => {
        event.preventDefault();
        // setIsLoading(true);
        let formData = new FormData();
        formData.append("retailerId", id);
        formData.append("accountNo", installerData?.parentCompany?.accountNumber);
        formData.append("image", image);
        formData.append("imageType", selectedDropdown?.propertyName ? selectedDropdown?.propertyName : "");
        formData.append("saveImage", "false");
        formData.append("compression", "0.9");
        formData.append("accountLevelImage", "false");
        formData.append("originalImageName", image.name);
        formData.append("processedImageName", image.name);
        formData.append("fallbackImageName", image.name);

        const successCallback = (data: any) => {
            setProcessedData(data);
            setAction("Upload");
            setCanShow(true);
            // setIsLoading(false);
        };

        const url = "/api/installer-service/installers/images";
        //TODO: Manage console.error
        fetch(url, {
            headers: {
                Authorization: "Bearer " + RestService.oauthAccessToken
            },
            method: 'POST',
            body: formData
        })
            .then((result) => {
                if (result.status === 401) {
                    window.location.reload();
                }
                return result.json();
            })
            .then((response) => {
                successCallback(response);
            })
            .catch((error) => { console.log(error); });

    };
    const onCheckBoxChange = (event: any, index: any) => {
        installerData?.media?.map((data: any, ind: any) => {
            if (ind == index) {
                data.active = !data.active;
            }
        })
        setInstallerData({ ...installerData });
    }
    const onTitleChange = (event: any, index: any) => {
        installerData?.media?.map((data: any, ind: any) => {
            if (ind == index) {
                data.title = event.target.value;
            }
        })
        setInstallerData({ ...installerData });
    }
    const onRankChange = (event: any, index: any) => {
        installerData?.media?.map((data: any, ind: any) => {
            if (ind == index) {
                data.rank = event.target.value;
            }
        })
        setInstallerData({ ...installerData });
    }
    const onStoreImagesAndLogoSave = () => {
        const successCallback = () => {
            getInstallerData();
            toast.success("Updated retailer.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success'
            });
        }
        const url = "/api/installer-service/installers/";
        //TODO: Manage console.error
        RestService.putRequest(url, installerData, successCallback, (err) =>
            console.log(err)
        );
    }

   
    return (
          
            <div className="OrderDetailsPage">

                <div className="OrderNumber">
                <h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
            Location Number  : <span className="groupDetails">{installerData?.atdLocationNumber}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
          Installer Id : <span className="groupDetails"> {installerData && JSON.stringify(installerData.id)}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
          Installer Name :  <span className="groupDetails">{installerData && JSON.stringify(installerData.name)}</span>
          </h4><h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
          Payment Group Name :<span className="groupDetails"> {installerData &&  installerData.paymentGroup?.companyName}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
                    Payment Group ID : <span className="groupDetails">{installerData && installerData.paymentGroup?.id}</span> 
                </h4>
                </div>
                
                <div style={{ border: "ridge", width: "100%" }}>
                    <div style={{ margin: '0.9em 0.9em 10em 0.9em' }}>
                        <div style={{ fontSize: "larger", textDecorationLine: "underline", fontWeight: "bold", }}> Upload Images and Logos</div><br></br>
                        <div style={{ width: "50%", float: "left" }}>
                            <select value={selectedDropdown?.id || 0} onChange={onDropdownChange} style={{ width: "75%", marginRight: '2em' }}>
                                {RETAILER_DROPDOWN_OPTIONS.map((option: DropdownOptions) => (
                                    <option key={option.id} value={option.id}>
                                        {option.displayName}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div style={{ width: "50%", float: "right" }}>
                            <div style={{ width: "100%", marginBottom: "0.5em" }}>
                                <input type="file" name="uploadfile" id="img" style={{ display: "none" }} accept=".png, .gif, .jpg" onChange={onImageChange} />
                                <label htmlFor="img" style={{ fontSize: "larger", textDecorationLine: "underline", fontWeight: "bold", }}>Click to upload {selectedDropdown?.propertyName}</label>&nbsp;&nbsp;
                                {image && <span>{image?.name}</span>}
                                {!image && <span>No file chosen</span>}
                                {/* <input className="file-input" id="file-input" type="file" name="myImage" accept=".png, .gif, .jpg" onChange={onImageChange} /> */}
                            </div>
                            <span className="file-msg" style={{ marginTop: "2em" }}>Only .jpg, .png, or .gif format allowed </span><br />
                            <span className="file-msg" style={{ marginTop: "2em" }}>File size limit is up to 5MB</span>

                        </div>
                        <div style={{ float: "right", marginTop: "5em" }}>
                            <button onClick={uploadImage} disabled={!image}> Upload </button>
                        </div>
                    </div><hr></hr>
                    <div style={{ margin: '0.9em 0.9em 1em 0.9em' }}>
                        <div>
                            <span style={{ fontSize: "medium", fontWeight: "bold", }}>Store-Images</span>
                            <br /><br />
                            {installerData?.media?.map((data: any, index: any) => {
                                if (data.category == 'STORE_IMAGE')
                                    return (
                                        <div style={{ display: 'inline-grid', margin: '5px 15px 0px 10px' }}>
                                            <input type="checkbox" checked={installerData.media[index]?.active} onChange={(e) => onCheckBoxChange(e, index)}></input>
                                            <img height='129' width='229' src={installerData?.media[index]?.url} /><br></br>
                                            <span>Title :<input type='text' style={{ marginLeft: '10px' }} value={installerData.media[index]?.title} onChange={(e) => onTitleChange(e, index)} /></span>
                                            <span>Rank :<input type='number' style={{ margin: '4px 0px 0px 5px' }} value={installerData.media[index]?.rank} onChange={(e) => onRankChange(e, index)} /></span>
                                        </div>
                                    );
                            })}

                        </div><br /><br />

                        <div>
                            <span style={{ fontSize: "medium", fontWeight: "bold", }}>Logos</span>
                            <br /><br />
                            {installerData?.media?.map((data: any, index: any) => {
                                if (data.category == 'LOGO')
                                    return (
                                        <div style={{ display: 'inline-grid', margin: '5px 15px 0px 10px' }}>
                                            <input type="checkbox" checked={installerData.media[index]?.active} onChange={(e) => onCheckBoxChange(e, index)}></input>
                                            <img height='129' width='229' src={installerData?.media[index]?.url} /><br></br>
                                            <span>Title :<input type='text' style={{ marginLeft: '10px' }} value={installerData.media[index]?.title} onChange={(e) => onTitleChange(e, index)} /></span>
                                            <span>Rank :<input type='number' style={{ margin: '4px 0px 0px 5px' }} value={installerData.media[index]?.rank} onChange={(e) => onRankChange(e, index)} /></span>

                                        </div>
                                    );
                            })}

                        </div>
                        <button style={{ margin: '50px 0px 0px 20px', width: '10%' }} onClick={onStoreImagesAndLogoSave}>SAVE</button>

                    </div>
                    <ModalSection
                        canShow={canShow}
                        action={action}
                        setCanShow={setCanShow}
                        processedImageData={processedData}
                        setProcessedImageData={setProcessedData}
                        selectedDropdown={selectedDropdown}
                        setImage={setImage}
                        installerData={installerData}
                        getInstallerData={getInstallerData}
                    />

                   
                </div>
            </div>
    );
};

export default RetailerUploadPage;
