import ColumnHeadings from "interfaces/ColumnHeadings";

const COLUMN_HEADINGS: ColumnHeadings[] = [
    {
        propertyName: "id",
        displayName: "Id",
        id: "orderNumber",
        internalPropertyName: "id",
        filterOptions: [],
    },
    {
        propertyName: "companyName",
        displayName: "Payment Group Name",
        id: "companyName",
        internalPropertyName: "companyName",
        filterOptions: [],
    },
    {
        propertyName: "billingContactEmail",
        displayName: "Billing Contact Email",
        id: "billingEmail",
        internalPropertyName: "billingContactEmail",
        filterOptions: [],
    },
    {
        propertyName: "billingContactName",
        displayName: "Billing Contact Name",
        id: "billingName",
        internalPropertyName: "billingContactName",
        filterOptions: [],
    },{
        propertyName: "installerFeesXeroContactId",
        displayName: "Installer Fees Xero Contact ID",
        id: "xeroContactID",
        internalPropertyName: "installerFeesXeroContactId",
        filterOptions: [],
    },
    {
        propertyName: "paymentMethod",
        displayName: "Payment Method",
        id: "paymentMethod",
        internalPropertyName: "paymentMethod",
        filterOptions: [],
    }
];

export { COLUMN_HEADINGS };