import IProps from "../../../../interfaces/IProps";
import Modal from 'react-modal';
import "./ModalMakePayments.css";
import { useEffect, useState } from "react";
import RestService from "services/RestService";
import { toast } from 'react-toastify';
import closeMark  from "../../../../assets/closeMark.png";
import moment from "moment";

interface Props extends IProps {
    canShow: any;
    setCanShow: (e: any) => void;   
    orderData: any; 
    installerId: any; 
    clickedFrom: any; 
    installerLocation: any;
 }
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '50%',
        padding:"0px 20px 0 20px",
        // borderRadius:"25px",
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto'
    },
};



const ModalMakePayments = ({ canShow, setCanShow,  orderData, installerId, clickedFrom, installerLocation}: Props) => {
    const [itemsData, setItemsData] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [cancelSubmitBoolean, setCancelSubmitBoolean] = useState<boolean>(true);
    const [cost, setCost] = useState(0);
    const [paymentNumber,setPaymnetNumber] = useState<any>("");
    const [checkedItems,setCheckedItems] = useState<any>([]);
    const [paidUser,setPaidUser] = useState<any>("");
    const [noOrders, setNoOrders] = useState<any>(false);
    
    const onClear = () => {
        setCanShow(false); 
        setPaymnetNumber("");
        setPaidUser("");
        setCost(0);      
    }

    const onSaveData = () => {
        const data = {
            "totalAmount": cost,
            "paymentReferenceNumber": paymentNumber,
            "paymentDate": (moment(new Date()).format("YYYY-MM-DDThh:mm:ss")).toString(),
            "orderNumbers": checkedItems,
            "paidByUser": paidUser,
            "installerLocationId": parseInt(installerLocation)
        }
        const successCallback = () => {
           onClear();
        //    reload orders detail API
        window.location.reload();
            toast.success("Payment successfully ", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      className: 'toast-success'
                    });

        };
        const errorCallback = (err: any) => {
            toast.error("Payment failed ", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      className: 'toast-error'
                    });
            console.log(err);
        }

        const putUrl = "/api/order-service/orders/updateInstallerFees";
        RestService.putRequest(putUrl, data, successCallback, (err) => errorCallback(err));

    }

    const selectedPaymentNumber = (e: any) =>{
        const error = [];
        if(!!e.target.value && e.target.value != null ){
            setPaymnetNumber(e.target.value);
        } else {
            error.push("Payment Reference number can not be null.");
            setPaymnetNumber("");
        }
        setErrorMessage(error);
    }

    useEffect(() =>{
        let url = "";        

     url = "/api/order-service/orders/installer-payment-pending-orders/"+installerId;
       

    const successCallback  = (data1 : any) => {
        if(data1.length >0 ){
            data1 && data1.map((item: any)=>{
                item.selected = false;
                return item;
               })
                setItemsData(data1); 
        } else {
            setNoOrders(true);
        }
        
    }
  RestService.getRequest(url, successCallback, (err) => console.log(err));
         
    },[])

    const checkedItem = (item: any, index: any) => {
        let cost = 0;
        let cI : any = [];
        const error = [];
        itemsData[index].selected = !item.selected;
        const checkList = itemsData.filter((x: any) => x.selected == true); 
        if(checkList.length <= 0){
            error.push("Select atleast one order to proceede.");
            setCost(0);
        } else {
            checkList.map((item: any)=>{
                cI.push(item.purchaseOrderNumber)
            })
    
            setCheckedItems(cI);   
            checkList.map((item : any)=>{
                cost = cost + item.totalAmount;
            })
            setCost(cost);
        }
        setErrorMessage(error);
    }

    const selectedPaidByUser = (e: any) =>{
        const error = [];
        if(e.target.value != null && !!e.target.value){
            setPaidUser(e.target.value);
        } else {
           error.push("Enter a valid name.");
           setPaidUser("");
        }

        setErrorMessage(error);
    }

    const validationCheck = () => {
        if(!!cost && cost != null && !!paymentNumber && paymentNumber != null && !!paidUser && paidUser != null){
            setCancelSubmitBoolean(false);
            setErrorMessage([]);
        } else {
            setCancelSubmitBoolean(true);
        }

    }

    useEffect(()=>{
        validationCheck();
    },[cost,paymentNumber,paidUser])


    return <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
        <>
        {!noOrders && <div>
                <h3 style={{ textDecorationLine: "underline" }}>Make Payments</h3>
                <span style={{ position: "absolute", right: "10px", top: "15px", display: "block", fontSize: "12px" }}>*Required Fields</span>
                <ul className="containerUl">
                    <li>
                        <div className="listItems">
                            <div className="subHeader">
                               <span >Selected</span>
                                <span >Order Number</span>
                                <span >Installation Date</span>
                                <span>Installater Reference Number</span>
                                <span>Customer Name</span>
                            </div>
                            <ul className="orderedData-list">
                                {itemsData && itemsData.map(({ selected , purchaseOrderNumber, appointmentDate,installerId,customerName } :{ selected: any , purchaseOrderNumber : any, appointmentDate : any,installerId : any,customerName: any } , index: any) => {
                                    return (
                                        <li key={index}>
                                            <div>
                                                <div>
                                                    <input
                                                        style = {{ width: "8%", textAlign: "center"}}
                                                        type="checkbox"
                                                        id={`custom-checkbox-${index}`}
                                                        name={selected}
                                                        value={itemsData[index].selected}
                                                        required
                                                        onChange={(e) => checkedItem(itemsData[index], index)}
                                                    />
                                                    <label htmlFor={`custom-checkbox-${index}`}>{selected}</label>
                                               
                                                
                                                <span className="sectionlayout">{purchaseOrderNumber}</span>  
                                                <span className="sectionlayout">{moment(appointmentDate).format("DD-MM-YYYY")}</span>  
                                                <span className="sectionlayout" style={{width:"36%" }}>{installerId}</span> 
                                                <span className="sectionlayout">{customerName}</span>  
                                                </div>                                       
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4> Payment Reference Number*</h4>
                            <input className="dropDown" name="selectReturns" onChange={(e) => selectedPaymentNumber(e)} required>                               
                            </input>
                        </div>
                    </li>    

                     <li>
                        <div style={{ marginTop:"15px"}}>
                            <h4> Paid by User*</h4>
                            <input className="dropDown"  onChange={(e) => selectedPaidByUser(e)} required>                               
                            </input>
                        </div>
                    </li> 

                    <li>
                        <div className="listItems">
                            <h4>Total Amount to be paid*</h4>
                            <p>${cost}</p>
                        </div>
                    </li>  
                    {errorMessage && <p style={{ color: "red", backgroundColor: "#fff", border: 0 }}>{errorMessage}</p>}                 
                </ul>               
                <button style={{ 'float': 'right', background: "#fff", color: "#0071BC", borderRadius: "5px", border: "1px solid #0071BC" }} onClick={onClear}>Close</button>&nbsp;
               <button style={{ 'float': 'right', marginRight: '10px',marginBottom: '15px', borderRadius: "5px" }} onClick={onSaveData} disabled={cancelSubmitBoolean}>Save</button>&nbsp;
            </div>}
            {!!noOrders && <div style={{ padding: "20px"}}>
              <p style={{ padding: "20px"}}>No Orders Found</p> 
              <button style={{ 'float': 'right', background: "#fff", color: "#0071BC", borderRadius: "5px", border: "1px solid #0071BC", marginBottom: "5px" }} onClick={onClear}>Close</button>&nbsp; 
            </div>}           
        </>
    </Modal>

};

export default ModalMakePayments;