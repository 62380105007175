import { Switch, Redirect, Route } from "react-router-dom";
import routesList from "./routesList";

const Routes = () => {
  return (
    <Switch>
      <Redirect from="/" exact={true} to="/orders/find-order" />
      {routesList.map(({ path, component }) => {
        return (
          <Route exact path={path}
            component={component}
            key={"route-" + path}
          />
        );
      })}
    </Switch>
  );
};

export default Routes;
