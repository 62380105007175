import CustomList from "components/CustomList/CustomList";
//mport { useEffect, useState , } from "react";
import { useEffect, useState } from "react";

import { useParams, useHistory } from "react-router-dom";
import RestService from "services/RestService";
import SortingDirection from "utils/enums/SortingDirection";
import "./OrdersPage.css";
import { COLUMN_HEADINGS } from "utils/constants/NewBookingsListColumnHeadings";
import { JsonTypes } from "@azure/msal-common/dist/utils/Constants";

const NewBookingsListPage = () => {
  let { id } = useParams<{ id: string }>();

  const history = useHistory();
  const [page, setPage] = useState(1);
  const [installerData, setInstallerData] = useState<any>();
  const [totalNoOfPages, setTotalNoOfPages] = useState(1);
  const [bookingsList, setBookingsList] = useState([]);
  const [sortingStatus, setSortingStatus] = useState({
    sortBy: "bookingDate",
    sortDirection: SortingDirection.DESC,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [finalFilterArray, setFinalFilterArray] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");


  const onBookingClick = (bookingReferenceNo:string ,orderNo:string) => {
    if (bookingReferenceNo && bookingReferenceNo !== "") {
      history.push("/bookingdetails/" + bookingReferenceNo + "/" + id  );
    }

  };
  const getInstallerData = () => {
    const successCallback = (response: any) => {
      console.log(response);
      setInstallerData(response);
    }
    const url = "/api/installer-service/installers/" + id;
    RestService.getRequest(url, successCallback, (err) =>
      console.log(err)
    );
  }

  useEffect(() => {
    setIsLoading(true);
  }, [page, sortingStatus, finalFilterArray, searchQuery]);


  useEffect(() => {
    if (!!isLoading) {
      getInstallerData();
      fetchBookingList();
    }

  }, [isLoading,page]);

  const onFilterChange = (filterArray: any[]) => {
    setFinalFilterArray(filterArray);
  };

  const fetchBookingList = () => {
    const filtersEncodedStr: string = encodeURIComponent(
      JSON.stringify(finalFilterArray)
    );

    const url =
     "/api/installer-service/management/installers/"+ id + "/bookings" + "?page_no=" + page; 
     
    
    const bookingsSuccessCallback = (data: any) => {
      setTotalNoOfPages(data.pageCount);
      setBookingsList(data.data);
      setIsLoading(false);
    };

    RestService.getRequest(url, bookingsSuccessCallback, (err) => console.log(err));
  };

  const changeSortingOrder = (headingKey: string) => {
    setSortingStatus((prevSortingStatus) => {
      if (headingKey === prevSortingStatus.sortBy) {
        switch (prevSortingStatus.sortDirection) {
          case SortingDirection.ASC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.DESC,
            };
          case SortingDirection.DESC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
          default:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
        }
      } else {
        return {
          ...prevSortingStatus,
          sortBy: headingKey,
          sortDirection: SortingDirection.ASC,
        };
      }
    });
  };



  const changePage = (newPageNo: number) => {
    setPage(newPageNo);

  };

  const onSearchClick = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  return (
    <div className="OrderDetailsPage"
    >
      <div className="OrderNumber">
      <h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
            Location Number  : <span className="groupDetails">{installerData?.atdLocationNumber}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
          Installer Id : <span className="groupDetails"> {installerData && JSON.stringify(installerData.id)}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
          Installer Name :  <span className="groupDetails">{installerData && JSON.stringify(installerData.name)}</span>
          </h4><h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
          Payment Group Name :<span className="groupDetails"> {installerData &&  installerData.paymentGroup?.companyName}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
                    Payment Group ID : <span className="groupDetails">{installerData && installerData.paymentGroup?.id}</span> 
                </h4>
      </div>

      {isLoading && <div className="loader"></div>}
      {!isLoading &&
        <CustomList
          columnHeadings={COLUMN_HEADINGS}
          listOfItems={bookingsList}
          sortBy={sortingStatus.sortBy}
          sortDirection={sortingStatus.sortDirection}
          totalNoPages={totalNoOfPages}
          currentPage={page}
          changePage={changePage}
          changeSortingOrder={changeSortingOrder}
          onClickOrder={onBookingClick}
        />}
    </div>
  );
};

export default NewBookingsListPage;


