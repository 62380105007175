import DropdownOptions from "interfaces/DropdownOptions";
import { useEffect, useState } from "react";
import RestService from "services/RestService";
import "./InstallerOptions.css";
import SearchSection from "./SearchSection/SearchSection";
import ListSection from "../../components/ListSection/ListSection";
import { COLUMN_HEADINGS } from "utils/constants/InstallerOptionsPageColumnHeadings";
import { INSTALLER_OPTIONS_PAGE_DROPDOWN_OPTIONS } from "utils/constants/InstallerOptionsPageDropdownOptions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const InstallerOptions = () => {
  const history = useHistory();
  const [selectedDropdown, setSelectedDropdown] = useState<DropdownOptions | undefined>(INSTALLER_OPTIONS_PAGE_DROPDOWN_OPTIONS.find(x => x.id == 1));
  const [searchValue, setSearchValue] = useState<string>("");
  const [installersList, setInstallersList] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ((searchValue && searchValue !== "") || selectedDropdown?.propertyName == "date") loadOrderList();
  }, [page]);

  const onDropdownChange = (e: any) => {
    const dropdownOption: DropdownOptions | undefined = INSTALLER_OPTIONS_PAGE_DROPDOWN_OPTIONS.find(
      (option) => option.id == e.target.value
    );
    setSelectedDropdown(dropdownOption);
    setSearchValue("");
  };

  const onSearchValueChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onPageChange = (newPageNo: number) => {
    setPage(newPageNo);
  };

  const loadOrderList = (pageNo?: number) => {
    setIsLoading(true);

    const successCallback = (data: any) => {
      setInstallersList(data);
      setIsLoading(false);
    };

    const errorCallBack = (err: any) => {
      setInstallersList([]);
      setIsLoading(false);
      toast.error("No results found.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error'
      });
      console.log(err);
    }
    const getData = () => {
      const url = "/api/installer-service/installers?locationNumber=" + searchValue;
      //   "/api/order-service/orders?pageNo=" + pageNo + "&filter=";

      //TODO: Manage console.error
      RestService.getRequest(url, successCallback, errorCallBack);
    }

    getData();
  };

  const onSearchClick = () => {
    if ((searchValue && searchValue !== "") || selectedDropdown?.propertyName == 'date') loadOrderList(1);
  };

  const onClickInstaller = (installer: any) => {
   // if (installer) history.push("/retailerupload/installers/" + installer.id);
    if (installer) history.push("/installers/" + installer.id);

  };

  return (
    <div className="InstallerOptions Page">
      <div className="container">
        <h1>Find Installer</h1>
        <SearchSection
          dropdownOptions={INSTALLER_OPTIONS_PAGE_DROPDOWN_OPTIONS}
          selectedDropdownId={selectedDropdown?.id || 0}
          searchValue={searchValue}
          onDropdownChange={onDropdownChange}
          onSearchValueChange={onSearchValueChange}
          onSearchClick={onSearchClick}
        />
        {isLoading && <div className="loader InstallerOptionsLoader"></div>}

        {!isLoading && installersList && installersList.length > 0 && (
          <ListSection
            columnHeadings={COLUMN_HEADINGS}
            listOfItems={installersList}
            totalNoPages={1}
            currentPage={page}
            changePage={onPageChange}
            onClickOrder={onClickInstaller}
          />
        )}
      </div>
    </div>
  );
};

export default InstallerOptions;
