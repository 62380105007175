import Navbar from "components/Navbar/Navbar";
import { useEffect, useState } from "react";
import Routes from "routes/Routes";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import RestService from "services/RestService";

const RouteWrapper = () => {
  const { instance, accounts } = useMsal();
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  const request = {
    ...loginRequest,
    account: accounts[0]
  };
  useEffect(() => {
    // Silently acquires an access token which is then attached to a request
    instance.acquireTokenSilent(request).then((response) => {
      RestService.setOAuthAccessToken(response.accessToken);
      setIsTokenGenerated(true);
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        RestService.setOAuthAccessToken(response.accessToken);
        setIsTokenGenerated(true);
      }).catch((e) => {
        instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
        });
      });
    });
  }, []);
  return (
    isTokenGenerated ?
      <>
        <Navbar />
        <Routes />
      </> : <></>
  );
};

export default RouteWrapper;
