import IProps from "interfaces/IProps";
import SubSectionRowData from "interfaces/SubSectionRowData";
import "./SubSectionRow.css";
import { useHistory } from 'react-router-dom'; 

interface Props extends IProps {
  subSectionRowData: SubSectionRowData;
}

const SubSectionRow = ({ subSectionRowData }: Props) => {
  const history = useHistory();
  let prodURL = "https://axis.tirebuyer.com";
  let stageURL = "https://axis-sandbox.treadsy.com";
  let devURL = "https://bugs.tirebuyer.com";

  const goToParent = (orderID : any) =>{
    history.push("/orders/" + orderID);
    window.location.reload();
  }

  const goToParentOrder = (orderID : any) =>{
    let url;
    if(window.location.origin == prodURL){
     url = prodURL+"/orders/"+orderID;
    } else if(window.location.origin == stageURL){
      url= stageURL+"/orders/"+orderID;
    } else {
      url = devURL+"/orders/"+orderID;
    }
    window.location.href = url;
  }


  return (
    <tr>
      <th className="padTen">
    {  !!subSectionRowData.title && <div>
          {subSectionRowData.title} :
        </div>}
      </th>
      
        {subSectionRowData.title == "Parent Order No" ? <td className="padTen" style={{"textDecorationLine":"underline", 'color': 'blue', 'cursor':'pointer'}} onClick={()=>goToParent(subSectionRowData.content)}>{subSectionRowData.content}</td> : 
       

        subSectionRowData.title == "Related PurchasingPower order" ? 
        <td style={{"textDecorationLine":"underline", 'color': 'blue', 'cursor':'pointer'}} onClick={()=>goToParentOrder(subSectionRowData.content)}>{subSectionRowData.content}</td> :<td>{subSectionRowData.content}</td> }
    
    </tr>
  );
};

export default SubSectionRow;
