
import CustomList from "components/CustomList/CustomList";
import { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import RestService from "services/RestService";
import SortingDirection from "utils/enums/SortingDirection";
import { COLUMN_HEADINGS } from "utils/constants/LegalParentListPageColumnHeadings";
import "./LegalParentList.css"
import ActionBar from "components/ActionBar/ActionBar";
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const customStyles = {
  content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '30%',
      //marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
  },
};
 
const LegalParentsPageList = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalNoOfPages, setTotalNoOfPages] = useState(1);
  const [ordersList, setOrdersList] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const [canShow, setCanShow] = useState(false);
  const [sortingStatus, setSortingStatus] = useState({
    sortBy: "companyName",
    sortDirection: SortingDirection.DESC,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [finalFilterArray, setFinalFilterArray] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [paymentGroupName, setPaymentGroupName] = useState<any>("");
  const [billingContactEmail,setBillingContactEmail] = useState<any>("");
  const [billingContactName,setBillingContactName] = useState<any>("");
  const [InstallerFeeID,setInstallerFeeID] = useState<any>("");
  const [payment,setPaymentMethod] = useState<any>("CARD");
  const [errorCheck, setErrorCheck] = useState(true);
  const [errorMessage,setErrorMessage] = useState<any>([]);
  const [editData,setEditData] = useState<any>();
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const paymentMethod: any  = [{value:"CARD", text:"CARD" },
                               {value:"WEEKLY_ACH",text:"WEEKLY ACH"},
                               {value:"MONTHLY_ACH", text:"MONTHLY ACH"}];

  const onOrderClick = (orderNumber: any) => {
    setEditData(orderNumber);
    setEdit(true);
    setPaymentGroupName(orderNumber.companyName);
    if(!!orderNumber && !orderNumber.billingContactEmail.match(isValidEmail)){
      setErrorMessage("Enter a valid Email.");
    }
    setBillingContactEmail(orderNumber.billingContactEmail);
    setBillingContactName(orderNumber.billingContactName);
    setInstallerFeeID(orderNumber.installerFeesXeroContactId);
    setPaymentMethod(orderNumber.paymentMethod);
    
  };

  const selectedPayment = (e: any) => {
    paymentMethod.map((item: any) =>{
      if(item["text"] == e.target.value){
        setPaymentMethod(item["value"]);
      }
    })   

  };

  useEffect(() => {
    setIsLoading(true);
  }, [page, sortingStatus, finalFilterArray, searchQuery]);

  useEffect(() => {
    
    if (!!isLoading) fetchOrderList();
  }, [isLoading]);

  const onFilterChange = (filterArray: any[]) => {
    setFinalFilterArray(filterArray);
  };

  const handleChange = (type: any, value: any) => {
    const errorText: any = [];
    
    if(type === "name"){
      if(value != "" && value !== null){
        setPaymentGroupName(value);
      } else {
        setPaymentGroupName("");
        errorText.push("Enter a valid Payment Group.");
      }      
    } else if (type === "email"){
      if(value != "" && value !== null){
        setBillingContactEmail(value);
        if(value.match(isValidEmail)){
          setBillingContactEmail(value);
        } else {
          errorText.push("Enter a valid Email."); 
        }
      } else {
        setBillingContactEmail("");
        errorText.push("Enter a valid Email.");
      }      
    } else if (type === "contact"){
      if(value != "" && value !== null){
        setBillingContactName(value);
      } else {
        setBillingContactName("");
        errorText.push("Enter a valid Contact name.");
      }       
    } else {
      setInstallerFeeID(value);
    } 

    setErrorMessage(errorText);
  };

  useEffect(()=>{
    if(!!paymentGroupName && paymentGroupName!= "" && !!billingContactName && billingContactName != "" && !!billingContactEmail && billingContactEmail != "" && billingContactEmail.match(isValidEmail) && !!payment && payment != "" ){
      setErrorCheck(false);
    } else {
      setErrorCheck(true);
    }

  },[paymentGroupName,billingContactName,billingContactEmail,payment])

  const saveData = (e: any) => {
    e.preventDefault();  
    const data = {
        "companyName": paymentGroupName,
        "billingContactName": billingContactName,
        "billingContactEmail": billingContactEmail,
        "installerFeesXeroContactId": InstallerFeeID,
        "paymentMethod": payment
     
  }
 
  if(!edit){
    const successCallback = (data : any) => {
      setCanShow(false);
      ordersList.push({ ...data });
      fetchOrderList();
    };
    const errorCallback = (err: any) => {
      toast.error("New Paymnet Group could not be created.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error'
    });
  }
    const url = "/api/installer-service/management/payment-groups";
    RestService.postRequest(url, data, successCallback, (err) => errorCallback(err));
  } else {

    const successCallback = () => {
      setCanShow(false);
      setEdit(false);
      fetchOrderList();
      toast.success("Payment group has been updated.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error'
    });
    };
    const errorCallback = (err: any) => {
      toast.error("Paymnet Group could not be updated.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error'
    });
  }
    const urlPut = "/api/installer-service/management/payment-groups/"+editData.id;
    RestService.putRequest(urlPut, data, successCallback, (err) => errorCallback(err));   
  }

  }


  const onClear = () => {
    setEdit(false);    
    setCanShow(false);  
    setErrorCheck(true);
    setPaymentGroupName("");
    setBillingContactEmail("");
    setBillingContactName("");
    setInstallerFeeID("");
    setPaymentMethod("");
    setErrorMessage([]);
    }

const handleNewPaymentGroup = () =>{
  setCanShow(true);
}


  const fetchOrderList = () => {
    const url =
    "/api/installer-service/management/payment-groups/?pageNo="+page+"&pageSize=10";  
  const successCallback = (response: any) => {    
    setOrdersList(response.data);
    setTotalNoOfPages(response.pageCount);
    setIsLoading(false);
  };

  //TODO: Manage console.error
  RestService.getRequest(url, successCallback, (err) => console.log(err));
   
  };

  const changeSortingOrder = (headingKey: string) => {
    setSortingStatus((prevSortingStatus) => {
      if (headingKey === prevSortingStatus.sortBy) {
        switch (prevSortingStatus.sortDirection) {
          case SortingDirection.ASC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.DESC,
            };
          case SortingDirection.DESC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
          default:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
        }
      } else {
        return {
          ...prevSortingStatus,
          sortBy: headingKey,
          sortDirection: SortingDirection.ASC,
        };
      }
    });
  };

  const changePage = (newPageNo: number) => {
    setPage(newPageNo);
  };

  const onSearchClick = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };
  
  return (
    <div className="LegalParentListPage Page">
    <div className="headerContainer">  
     <ActionBar 
        title="Payment Group List"
        onSearchClick={onSearchClick}
        columnHeadings={COLUMN_HEADINGS}
        displayCreateOrderButton={true}
        onFilterChange={onFilterChange}
      />
    
      <Button className="newButton" variant="contained" onClick = {handleNewPaymentGroup} >New Payment Group</Button>
    </div>
      {isLoading && <div className="loader"></div>}
      {!isLoading &&
        <CustomList
          columnHeadings={COLUMN_HEADINGS}
          listOfItems={ordersList}
          sortBy={sortingStatus.sortBy}
          sortDirection={sortingStatus.sortDirection}
          totalNoPages={totalNoOfPages}
          currentPage={page}
          changePage={changePage}
          changeSortingOrder={changeSortingOrder}
          onClickOrder={onOrderClick}
        />}

<Modal isOpen={edit} style={customStyles} ariaHideApp={false}>
            {!!editData && <form>
                <h3>Edit Payment Group Details</h3>
                    <div className="formFields">                     
                    <div className="innerContainer">
                        <label>
                            <h4>Payment Group Name:</h4>
                            <input type="text" value = {paymentGroupName} onChange = {(e) => {handleChange("name", e.target.value)}} />
                        </label>                       

                        <label>
                            <h4>Billing Contact Email :</h4>
                            <input type="email"  value = {billingContactEmail} onChange={(e) => {handleChange("email", e.target.value)}} />
                        </label>
                        <label>
                            <h4>Billing Contact Name :</h4>
                            <input type="text" value= {billingContactName} onChange={(e) => {handleChange("contact", e.target.value)}} />
                        </label>
                        <label>
                            <h4>Installer Fees Xero Contact ID:</h4>
                            <input type="text" value={InstallerFeeID} onChange={(e) => {handleChange("id", e.target.value)}} />
                        </label>
                        <label>
                            <h4>Payment Method:</h4>
                                              <select className="dropDown" onChange={(e) => selectedPayment(e)}  required>
                                            {/* <option disabled >{"Select an option "}</option> */}
                                            {paymentMethod.map(function (n: any) {
                                                return (
                                                    <option selected={payment == n['text']} value={n["text"]}>{n["text"]}</option>);
                              })}
                        </select>
                        </label>
                    </div>
                   
                    </div>
                    {!!errorCheck && <span className="errroStyles">{errorMessage}</span>}
                    <div className="submitSection">
                            <button style={{ 'float': 'right', marginTop: "10px", marginRight: '10px', borderRadius: "5px" }} disabled={errorCheck} onClick={(e)=>saveData(e)}>Submit</button>&nbsp;
                    <button style={{ 'float': 'right', marginTop: "10px", background: "#fff", color: "#0071BC", border: "1px solid #0071BC", borderRadius: "5px" }} onClick={onClear}>Cancel</button>&nbsp;

                    </div>
                </form>}
            </Modal>
            {/* new payment group creation form -----------------------------------------------*/}
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
            { <form>
                <h3>Enter new Payment Group Details</h3>
                    <div className="formFields">                     
                    <div className="innerContainer">
                        <label>
                            <h4>Payment Group Name:</h4>
                            <input type="text" onChange={(e) => {handleChange("name", e.target.value)}} />
                        </label>                       

                        <label>
                            <h4>Billing Contact Email :</h4>
                            <input type="Email" onChange={(e) => {handleChange("email", e.target.value)}} required />
                        </label>
                        <label>
                            <h4>Billing Contact Name :</h4>
                            <input type="text" onChange={(e) => {handleChange("contact", e.target.value)}} />
                        </label>
                        <label>
                            <h4>Installer Fees Xero Contact ID:</h4>
                            <input type="text" onChange={(e) => {handleChange("id", e.target.value)}} />
                        </label>
                        <label>
                            <h4>Payment Method:</h4>
                            <select className="dropDown" onChange={(e) => selectedPayment(e)} required>
                            {/* <option disabled selected>{"Select an option "}</option> */}
                                {paymentMethod.map(function (n: any) {
                                    return (<option selected={payment === n['text']} value={n['text']}>{n['text']}</option>);
                                })}
                            </select>
                        </label>
                    </div>
                   
                    </div>
                    {!!errorCheck && <span className="errroStyles">{errorMessage}</span>}
                    <div className="submitSection">
                            <button style={{ 'float': 'right', marginTop: "10px", marginRight: '10px', borderRadius: "5px" }} disabled={errorCheck} onClick={(e)=>saveData(e)}>Submit</button>&nbsp;
                    <button style={{ 'float': 'right', marginTop: "10px", background: "#fff", color: "#0071BC", border: "1px solid #0071BC", borderRadius: "5px" }} onClick={onClear}>Cancel</button>&nbsp;

                    </div>
                    
                </form>}
            </Modal>
    </div>
  );
};

export default LegalParentsPageList;
