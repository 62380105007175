import DropdownOptions from "interfaces/DropdownOptions";

const FIND_ORDER_DROPDOWN_OPTIONS: DropdownOptions[] = [
  {
    propertyName: "orderNumber",
    displayName: "Order Number",
    id: 1,
    databasePropertyName: "purchaseOrderNumber",
  },
  {
    propertyName: "partnerOrderNo",
    displayName: "Partner Order Number",
    id: 2,
    databasePropertyName: "partnerOrderNo",
  },
  {
    propertyName: "email",
    displayName: "Email Address",
    id: 3,
    databasePropertyName: "billingAddress.email",
  },
  {
    propertyName: "firstName",
    displayName: "First Name",
    id: 4,
    databasePropertyName: "billingAddress.firstName",
  },
  {
    propertyName: "lastName",
    displayName: "Last Name",
    id: 5,
    databasePropertyName: "billingAddress.lastName",
  },
  {
    propertyName: "phone",
    displayName: "Phone Number",
    id: 6,
    databasePropertyName: "billingAddress.phone",
  },

  {
    propertyName: "partnerPoNo",
    displayName: "Partner PO Number",
    id: 7,
    databasePropertyName: "partnerPoNo",
  },

  // TODO: Fillup the below
  {
    propertyName: "PAYPAL",
    displayName: "Paypal Transaction Number",
    id: 8,
    databasePropertyName: "",
  },
  // TODO: Fillup the below
  {
    propertyName: "CYBERSOURCE",
    displayName: "Cybersource Request Id",
    id: 9,
    databasePropertyName: "",
  },
  {
    propertyName: "date",
    displayName: "Date",
    id: 10,
    databasePropertyName: "date",
  },
  {
    propertyName: "marketplaceOrderNumber",
    displayName: "Marketplace Order Number",
    id: 11,
    databasePropertyName: "marketplaceOrderNumber",
  },
  // // TODO: Fillup the below
  // {
  //   propertyName: "",
  //   displayName: "Partner Order Buyer User Id",
  //   id: 3,
  //   databasePropertyName: "",
  // },
];
const RETAILER_DROPDOWN_OPTIONS: DropdownOptions[] = [
  {
    propertyName: "STORE_IMAGE",
    displayName: "Store Image",
    id: 1,
    databasePropertyName: "image",
  },
  {
    propertyName: "LOGO",
    displayName: "Logo",
    id: 2,
    databasePropertyName: "logo",
  },
];

export { FIND_ORDER_DROPDOWN_OPTIONS, RETAILER_DROPDOWN_OPTIONS };
