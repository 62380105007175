import ColumnHeadings from "interfaces/ColumnHeadings";

const COLUMN_HEADINGS: ColumnHeadings[] = [
    {
        propertyName: "orderNumber",
        displayName: "Order Number",
        id: "orderNumber",
        internalPropertyName: "orderNumber",
        filterOptions: [],
    },
    {
        propertyName: "billingFirstName",
        displayName: "Customer First Name ",
        id: "billingFirstName",
        internalPropertyName: "billingAddress.firstName",
        filterOptions: [],
    },
    {
        propertyName: "billingLastName",
        displayName: "Customer Last Name",
        id: "billingLastName",
        internalPropertyName: "billingAddress.lastName",
        filterOptions: [], 
    },
    {
        propertyName: "orderStatus", 
        displayName: "Order Status",
        id: "orderStatus",
        internalPropertyName: "orderStatus",
        filterOptions: ["ORDER_SAVED", "SUBMIT_TO_SUPPLIER_PENDING", "SUBMITTED_TO_SUPPLIER", "OPEN", "COMPLETED", "CANCELLED", "WAIT_FRAUD_MANUAL_CHECK",
            "FULFILLMENT_FAILED", "PRODUCT_NOT_FOUND", "FAILED", "GIFT_CARD_ORDER", "TAX_ON_REVIEW","ORDER_ON_HOLD"],
    },
    {
        propertyName: "orderDate",
        displayName: "Date",
        id: "orderDate",
        internalPropertyName: "createDate",
        filterOptions: [],
    },
    {
        propertyName: "orderSource",
        displayName: "Source",
        id: "orderSource",
        internalPropertyName: "orderSource",
        filterOptions: ["TIREBUYER", "OMS"],
    },
    {
        propertyName: "salesChannel",
        displayName: "Sales Channel",
        id: "salesChannel",
        internalPropertyName: "salesChannel",
        filterOptions: ["WEBSITE", "EBAY", "PURCHASINGPOWER", "TRANSFORMCO_SEARS","CA_AMAZON","CA_WALMART","CA_EBAY","CA","RAC"],
    },
    {
        propertyName: "orderType",
        displayName: "Type",
        id: "orderType",
        internalPropertyName: "orderType",
        filterOptions: [],
    },
    {
        propertyName: "paymentStatus",
        displayName: "Payment Status",
        id: "paymentStatus",
        internalPropertyName: "transactions.paymentStatus",
        // filterOptions: ["PENDING", "AUTHORISED", "SETTLED", "REFUNDED", "CHARGEBACK", "REJECTED", "REVIEW", "REVERSED", "FAILED"],
        filterOptions: [],
    },
    {
        propertyName: "bookingStatus",
        displayName: "Booking Status",
        id: "bookingStatus",
        internalPropertyName: "bookingStatus",
        filterOptions: ["RESERVED","CUSTOMER_CONFIRMED","INSTALLER_CONFIRMED","INSTALLATION_COMPLETE"],
    },
    {
        propertyName: "installerFeeStatus",
        displayName: "Installer Fee Status",
        id: "installerFeeStatus",
        internalPropertyName: "installerFeeStatus",
        filterOptions: ["Paid", "Pending", "Payment Pending - Credit Card"],
    },
];

export { COLUMN_HEADINGS };