import SubSectionData from "interfaces/SubSectionData";
import { SetStateAction, useEffect, useState } from "react";
import ActionsSection from "./ActionsSection/ActionsSection";
import "./BookingDetailsPage.css";
import BookingDetailsSection from "./BookingDetailsSection/BookingDetailsSection";

import RestService from "services/RestService";
import { useHistory, useParams } from "react-router-dom";
import TabularSectionData from "interfaces/TabularSectionData";
import CustomObjectType from "interfaces/CustomObjectType";
import ColumnHeadings from "interfaces/ColumnHeadings";
import { COLUMN_HEADINGS } from "utils/constants/OrderDetailsPageColumnHeadings";
import { toast } from 'react-toastify';
import moment from 'moment'
import DeliveryStatus from "../../utils/enums/DeliveryStatus";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'max-content',
    transform: 'translate(-50%, -50%)',
  },
};
const BookingDetailsPage = () => {

  let { id, id2 } = useParams<{ id: string, id2: string }>();

  const [liveDiaryCalendar, setLiveDiaryCalendar] = useState<CustomObjectType[]>([]);
  const [liveDiaryCalendarDates, setLiveDiaryCalendarDates] = useState<CustomObjectType[]>([]);
  const [liveDiaryList, setLiveDiaryList] = useState<CustomObjectType[]>([]);

  const [canShow, setCanShow] = useState(false);

  const bookingStatusValues = [
    {
      propertyName: "BLANK",
      displayName: " ",
      id: "1",
    },
    {
      propertyName: "RESERVED",
      displayName: "Reserved",
      id: "2",
    },
    {
      propertyName: "CUSTOMER_CONFIRMED",
      displayName: "Customer Confirmed",
      id: "3",
    },
    {
      propertyName: "INSTALLER_CONFIRMED",
      displayName: "Installer Confirmed",
      id: "4",
    },
    {
      propertyName: "INSTALLATION_COMPLETE",
      displayName: "Installer Complete",
      id: "5",
    },
    {
      propertyName: "MISSED",
      displayName: "Installation Missed",
      id: "6",
    },
    {
      propertyName: "FULLY_BOOKED",
      displayName: "Fully Booked",
      id: "7",
    },
    {
      propertyName: "INSTALLER_CLOSED",
      displayName: "Installer Closed",
      id: "8",
    },
    {
      propertyName: "UNABLE_TO_INSTALL_TIRES",
      displayName: "Unable to Install Tires",
      id: "9",
    },
    {
      propertyName: "NO_ANSWER_FOLLOW_UP",
      displayName: "No Answer - Followup",
      id: "10",
    },
    {
      propertyName: "VOICE_MAIL_FOLLOW_UP",
      displayName: "Voicemail - Followup",
      id: "11",
    },
    {
      propertyName: "OTHER",
      displayName: "Other",
      id: "12",
    },
  ];


  const [orderNumber, setOrderNumber] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedSlotType, setSelectedSlotType] = useState<string>("");
  const [previousBookingDate, setPreviousBookingDate] = useState<string>();
  const [previousBookingSlotType, setPreviousBookingSlotType] = useState<string>();

  const [selected, setSelected] = useState<string>(""); // initialise string for the calendar data here
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState<string>("");
  const [selectedBookingStatus, setSelectedBookingStatus] = useState<string>(""); // initialise string for the booking status selection here
  const [itemsData, setItemsData] = useState<CustomObjectType[]>([]);
  const [installdata, setInstallData] = useState<CustomObjectType[]>([]);

  const [data, setData] = useState<any>();
  const [orderData, setOrderData] = useState<TabularSectionData>();
  const [activeHeader, setActiveHeader] = useState<string>("Summary");
  const [orderStatus, setOrderStatus] = useState<string>("SUBMITTED_TO_SUPPLIER"); // this is set purely for development demo purposes
  const [orderType, setOrderType] = useState<string>();
  const [bookingReference, setBookingReference] = useState<string>(id);
  const [installer, setInstaller] = useState<string>(id2);
  const [count, setCount] = useState<number>(0);

  const [cancelCount, setCancelCount] = useState<number>(0);


  const [bookingsData, setBookingsData] = useState<any>();


  const history = useHistory();
  const cancelOrderColumnHeadings: ColumnHeadings[] = [{
    propertyName: "selected",
    displayName: "Selected",
    id: "11",
    internalPropertyName: "selected",
    filterOptions: [],
  }, {
    propertyName: "cancelQuantity",
    displayName: "CancelQuantity",
    id: "12",
    internalPropertyName: "cancelQuantity",
    filterOptions: [],
  },
  {
    propertyName: "reason",
    displayName: "Reason",
    id: "13",
    internalPropertyName: "reason",
    filterOptions: [],
  }, {
    propertyName: "notes",
    displayName: "Notes",
    id: "14",
    internalPropertyName: "notes",
    filterOptions: [],
  }]
  useEffect(() => {
    getData();
  }, [orderNumber]);


  useEffect(() => {
    getBookingsData();
    getLiveDiaryCalendar();

  }, []);




  useEffect(() => {
  }, [liveDiaryList, bookingStatusValues]);

  useEffect(() => {
    getBookingsData();
    getLiveDiaryCalendar();

    setSelectedDate(selected.substring(0, 10));
    setSelectedSlotType(selected.substring(11, 13));

  }, [selected, selectedBookingStatus, selectedDate, selectedSlotType, count]);



  const getLiveDiaryCalendar = () => {
    var tempDate = new Date();
    let currDate = moment(tempDate).format('YYYY-MM-DD');

    const url = "/api/installer-service/management/installers/" + installer + "/bookings/availability?delivery-date=" + currDate;

    const successCallbackLiveDiary = (liveDiaryCalendar: any) => {
      setLiveDiaryCalendar(liveDiaryCalendar);

      var i = 0;
      var j = -1;
      let newLiveDiaryList: any[] = []
      for (i = 0; i < liveDiaryCalendar.dates.length; i++) {
        if (liveDiaryCalendar.dates[i].noOfAmSlots > 0) {
          j++;
          newLiveDiaryList[j] =
          {
            date: liveDiaryCalendar.dates[i].date,
            slotType: 'AM'
          };
        }

        if (liveDiaryCalendar.dates[i].noOfPmSlots > 0) {
          j++;
          newLiveDiaryList[j] =
          {
            date: liveDiaryCalendar.dates[i].date,
            slotType: 'PM'
          };
        }

        setLiveDiaryList(liveDiaryList => [...liveDiaryList, newLiveDiaryList]);
      }


    };
    RestService.getRequest(url, successCallbackLiveDiary, (err) => console.log('Error Get Live Diary err:', err));

  }

  const handleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
    setSelected(e.target.value)
    console.log('in handleChange selected , e.target.value:', JSON.stringify(selected),
      JSON.stringify(e.target.value));

  };

  const openSendConfirmationModal = () => {
    setCanShow(true);
  }

  const handleSendConfirmation = () => {
    saveBooking(true);
    setCanShow(false);
  }

  const handleDeliveryChange = (e: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedDeliveryStatus(e.target.value);
    console.log("in handleDeliveryChange selected , e.target.value:", JSON.stringify(selectedDeliveryStatus),
      JSON.stringify(e.target.value));

  };

  const handleChangeBookingStatus = (e: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedBookingStatus(e.target.value)
    console.log('in handleChangeBookingStatus selectedBookingStatus  , e.target.value:',
      JSON.stringify(selectedBookingStatus),
      JSON.stringify(e.target.value));
  };

  const getBookingsData = () => {
    const url = "/api/installer-service/management/installers/" + installer + "/bookings/" + bookingReference;

    const successCallback = (bookingsData: any) => {
      setBookingsData(bookingsData);
      setOrderNumber(bookingsData.orderNumber);
      setSelectedDeliveryStatus(bookingsData.deliveryStatus);
    };
    RestService.getRequest(url, successCallback, (err) => console.log(err));

  }

  const getData = () => {
    const url = "/api/order-service/orders/get-details/" + orderNumber;
    const successCallback = (data: any) => {
      setData(data);
      setOrderType(data['orderType'])
      setOrderStatus(data['orderStatus'] || "SUBMITTED_TO_SUPPLIER"); // use of default status SUBMITTED_TO_SUPPLIER is only for development purposes only
      setItemsData(data.items);

    };

    //TODO: Manage console.error
    RestService.getRequest(url, successCallback, (err) => console.log(err));
  }
  const tabularSectionDataList: TabularSectionData[] = [
    {
      heading: "Install",
      key: "install",
      columnHeadings: COLUMN_HEADINGS.installColumnHeadings,
      tableContent: installdata,
    },

  ];

  const subSectionDataList: SubSectionData[] = [
    {
      heading: "Summary",
      subSectionRowDataList: [
        { title: "Booking Date", content: bookingsData && bookingsData.bookingDate },
        { title: "Booking Slot", content: bookingsData && bookingsData.bookingSlot },
        { title: "Total Number of Lines", content: bookingsData && bookingsData.lineItems.length/* totalNoOfLines */ },
      ],
    },{
      heading: "Customer",
      subSectionRowDataList: [
        { title: "Name", content: bookingsData && bookingsData.customerDetails.firstName + " " + bookingsData.customerDetails.lastName },
        {
          title: "Address",
          content: (bookingsData ? bookingsData.customerDetails.addressLine2 ?
            <div style={{ display: 'inline' }}>
              {bookingsData.customerDetails.addressLine1},&nbsp;
              {bookingsData.customerDetails.addressLine2},&nbsp;
              {bookingsData.customerDetails.city},&nbsp;
              {bookingsData.customerDetails.state},&nbsp;
              {bookingsData.customerDetails.zipCode}
            </div> : <div style={{ display: 'inline' }}>
              {bookingsData.customerDetails.addressLine1},&nbsp;
              {bookingsData.customerDetails.city},&nbsp;
              {bookingsData.customerDetails.state},&nbsp;
              {bookingsData.customerDetails.zipCode}

            </div> : ''
          ),
        },
        { title: "Email", content: bookingsData && bookingsData.customerDetails.email },
        { title: "Phone", content: bookingsData && bookingsData.customerDetails.phone },
      ],
    },
  ];
  if(bookingsData && (bookingsData.mobileInstallationDetails.firstName !== null)){
    subSectionDataList.push({
      heading: "Mobile Installation",
    subSectionRowDataList: [
      { title: "First Name", content: bookingsData && bookingsData.mobileInstallationDetails.firstName },
      { title: "Last Name", content: bookingsData && bookingsData.mobileInstallationDetails.lastName },
      { title: "Address Line 1", content: bookingsData && bookingsData.mobileInstallationDetails.addressLine1 },
      { title: "Address Line 2", content: bookingsData && bookingsData.mobileInstallationDetails.addressLine2 },
      { title: "City", content: bookingsData && bookingsData.mobileInstallationDetails.city },
      { title: "State", content: bookingsData && bookingsData.mobileInstallationDetails.state },
      { title: "Zip", content: bookingsData && bookingsData.mobileInstallationDetails.zipCode },
      { title: "Email", content: bookingsData && bookingsData.mobileInstallationDetails.email },
      { title: "Phone", content: bookingsData && bookingsData.mobileInstallationDetails.phone },
      { title: "Extension", content: bookingsData && bookingsData.mobileInstallationDetails.phoneExtension },
    ],
    })
  }
  if (data) {
    subSectionDataList.push({
      heading: "Fees",
      subSectionRowDataList: [
        { title: "Run flat Fee", content: bookingsData && '$ ' + bookingsData.installationFee.runFlatFee },
        { title: "Shop Fee", content: bookingsData && '$ ' + bookingsData.installationFee.shopFee },
        { title: "TPMS Fee", content: bookingsData && '$ ' + bookingsData.installationFee.tpmsFee },
        { title: "Valve Stem Fee", content: bookingsData && '$ ' + bookingsData.installationFee.valveStemFee },
        { title: "Wheel Installation Fee", content: bookingsData && '$ ' + bookingsData.installationFee.wheelInstallationFee },
        { title: "Total Installation Fee", content: bookingsData && '$ ' + bookingsData.installationFee.totalInstallationFees },
      ],
    })
  }
  if (data && bookingsData) {
    const lineItems = [];
    if (bookingsData.lineItems) {
      for (let i = 0; i <= bookingsData.lineItems.length; i++) {
        if (bookingsData.lineItems[i]) {
          lineItems.push(
            { title: "Manufacturer SKU", content: bookingsData.lineItems[i].mfgNo, },
            { title: "Quantity", content: bookingsData.lineItems[i].qty, },
            { title: "Brand Name", content: bookingsData.lineItems[i].brandName, },
          )
        }
      }
    }

    subSectionDataList.push({
      heading: "Items",
      subSectionRowDataList: lineItems,
    })


  }
  if (data && data.shippingAddress && orderType !== 'CNC') {
    subSectionDataList.push({
      heading: "Home Address",
      subSectionRowDataList: [
        { title: "Name", content: data && data.shippingAddress.firstName + " " + data.shippingAddress.lastName },
        {
          title: "Address",
          content: (data ? data.shippingAddress.addressLine2 ?
            <div style={{ display: 'inline' }}>
              {data.shippingAddress.addressLine1},&nbsp;
              {data.shippingAddress.addressLine2},&nbsp;
              {data.shippingAddress.city},&nbsp;
              {data.shippingAddress.zip}.
            </div> : <div style={{ display: 'inline' }}>
              {data.shippingAddress.addressLine1},&nbsp;
              {data.shippingAddress.city},&nbsp;
              {data.shippingAddress.zip}.
            </div> : ''
          ),
        },
        { title: "Email", content: data && data.shippingAddress.email },
        { title: "Phone", content: data && data.shippingAddress.phone },
      ],
    })
  }

  useEffect(() => {
    const supplierLineItems: any[] = [];
    itemsData?.map((cli: any, cliIndex: number) => { cli.supplierLineItems?.map((sli: any, sliIndex: number) => { supplierLineItems.push(sli) }) });
    const items = {
      heading: "Items",
      key: "items",
      columnHeadings: COLUMN_HEADINGS.supplierLineItemColumnHeadings,
      tableContent: supplierLineItems,
    };
    items?.tableContent?.map((supplierLineItem: any, i: number) => {
      const checkedData = ({ ...supplierLineItem, selected: false, cancelQuantity: 0, replaceQuantity: 0, notes: '' });
      items.tableContent[i] = checkedData;
    });
    setOrderData(items);
  }, [itemsData]);


  const cancelBooking = () => {
    const data = {

    };

    const url = "/api/installer-service/management/installers/" + installer + "/bookings/" + bookingReference + "/cancel"; // this is a demo on development for installer id booking ref 24 only
    const successCallbackCancel = () => {
      toast.success("Cancelled booking successfully ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-success'
      });
      setCancelCount(cancelCount + 1);

    };
    const errorCallback = (response: any) => {
      const errorMessage = "The booking has been cancelled in OMS, but could not be cancelled with the installer. Please contact them to cancel the booking directly."
      if(response.status !== 200 && response.status !== 500){
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      } else if(response.status == 500) {
        toast.error("Internal Server Error, cancel bookings.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      }

    }
    RestService.putRequest(url, data, successCallbackCancel, (err) => errorCallback(err));
    //TODO: Manage console.error
  }


  const onClear = () => {
    setCanShow(false);
  }

  const saveBooking = (shouldResendConfirmation = false) => {

    const data = {

      bookingDate: selectedDate,
      bookingSlot: selectedSlotType,
      shouldResendConfirmation: shouldResendConfirmation
      // bookingStatus: selectedBookingStatus,
    };

    const statusData = {
      bookingStatus: selectedBookingStatus,
    }

    // if (statusData.bookingStatus != "" && (statusData.bookingStatus != new String('BLANK'))){

    //   const url = "/api/installer-service/management/installers/" + installer +
    //     "/bookings/" + bookingReference + "/status";

    //   const successCallbackSaveStatus = () => {

    //     toast.success("Saved booking status successfully ", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: 'toast-success'
    //     });
    //     setCount(count + 1);

    //   };
    //   const errorCallbackSaveStatus = (responseStatus: any) => {
    //     console.log('error booking status:',responseStatus);
    //     toast.error("Saved booking status failed ", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: 'toast-error'
    //     });
    //   }
    //   RestService.putRequest(url, statusData, successCallbackSaveStatus, (errSaveStatus) => errorCallbackSaveStatus(errSaveStatus));


    //   const url1 = "/api/installer-service/management/installers/" + installer +
    //     "/bookings/" + bookingReference;

    //   const successCallbackSave = () => {
    //     console.log('Saved booking successfully');
    //     toast.success("Saved booking successfully ", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: 'toast-success'
    //     });
    //     setCount(count + 1);


    //   };


    //   const errorCallbackSave = (response: any) => {
    //     console.log('error save booking:',response);
    //     toast.error("Saved booking failed ", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: 'toast-error'
    //     });
    //   }

    //   RestService.putRequest(url1, data, successCallbackSave, (errSave) => errorCallbackSave(errSave));

    // } 

    // else if (selectedBookingStatus == 'BLANK' || statusData.bookingStatus == new String('BLANK')) {

    //   toast.error("Saved booking failed - no change made to diary slot ", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     className: 'toast-error'
    //   });
    // }
    // else {


      

    // }
    if (selectedDeliveryStatus != bookingsData.deliveryStatus) {
      const url = "/api/installer-service/management/installers/" + installer +
        "/bookings/" + bookingReference + "/delivery-status";

      const successCallbackSaveStatus = () => {

        toast.success("Saved delivery status successfully ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-success"
        });
        setCount(count + 1);

      };
      const errorCallbackSaveStatus = (responseStatus: any) => {
        console.log("error delivery status:", responseStatus);
        toast.error("Saved delivery status failed ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error"
        });
      };

      RestService.putRequest(
        url,
        { deliveryStatus: selectedDeliveryStatus },
        successCallbackSaveStatus,
        (errSaveStatus) => errorCallbackSaveStatus(errSaveStatus)
      );
    }

    if (statusData.bookingStatus != "") {

      const url = "/api/installer-service/management/installers/" + installer +
        "/bookings/" + bookingReference + "/status";

      const successCallbackSaveStatus = () => {

        toast.success("Saved booking status successfully ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success'
        });
        setCount(count + 1);

      };
      const errorCallbackSaveStatus = (responseStatus: any) => {
        console.log('error booking status:',responseStatus);
        toast.error("Saved booking status failed ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      }
      RestService.putRequest(url, statusData, successCallbackSaveStatus, (errSaveStatus) => errorCallbackSaveStatus(errSaveStatus));

    }

    if (data.bookingDate == "" && data.bookingSlot == "" ) {

      // toast.error("Saved booking failed - no change made to diary slot ", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      //   className: 'toast-error'
      // });
    }
    else {


      const url = "/api/installer-service/management/installers/" + installer +
        "/bookings/" + bookingReference;

      const successCallbackSave = () => {
        console.log('Saved booking successfully');
        toast.success("Saved booking successfully ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success'
        });
        setCount(count + 1);


      };


      const errorCallbackSave = (response: any) => {
        console.log('error save booking:',response);
        toast.error("Saved booking failed ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      }

      RestService.putRequest(url, data, successCallbackSave, (errSave) => errorCallbackSave(errSave));

    }
  }

  return (
    <div className="BookingDetailsPage Page">
      <div className="BookingStatus">
        <b style={{ textDecorationLine: "underline" }}>Purchase Order : {orderNumber}</b>
        {data && <span  >Order Status: {orderStatus}</span>}
        {data && <span className="BookingField"   >Booking Reference Number: {bookingReference}</span>}

        {(bookingsData && bookingsData.bookingStatus !== 'CANCELLED') &&

          <div style={{
            padding: "2px", height: "25%", width: "45%",
            display: "flex", marginLeft: "65%", flexDirection: "column", position: "relative", left: "1%"
          }}>Booking Status:


            <select value={selectedBookingStatus || bookingsData.bookingStatus} onChange={handleChangeBookingStatus} >

              {bookingStatusValues.map((item: any, id: any) => (

                <option value={[item.propertyName]}>
                  {
                    item.displayName
                  }

                </option>
              ))
              }
            </select>



          </div>
        }
      </div>




      {!data && <div className="loader"></div>}


      {data && bookingsData && <div style={{ display: "flex:", flexDirection: "row", padding: "0 20px", left: "1%" }}>
        <div className='Section'>

          <BookingDetailsSection subSectionDataList={subSectionDataList}
            activeHeader={activeHeader} setActiveHeader={setActiveHeader} />
          <ActionsSection orderStatus={orderStatus}

            orderData={orderData} saveBooking={saveBooking} cancelBooking={cancelBooking} noteData={undefined} bookingsData= {bookingsData}

            compensationRequest={function (e: any): void {
              throw new Error("Function not implemented.");
            }} returnRequest={function (e: any): void {
              throw new Error("Function not implemented.");
            }} replacementRequest={function (e: any): void {
              throw new Error("Function not implemented.");
            }}
          />


          <div style={{
            border: "1px", borderColor: "#0059A1", borderStyle: "solid", padding: "28px", height: "75%", width: "10%",
            display: "flex", marginLeft: "10%", flexDirection: "column", position: "relative", left: "1%"
          }}>
            <span style={{ fontWeight: "bold" }}> Diary booking slot</span>


            {liveDiaryList[0] &&
              <select value={selected} onChange={handleChange} >

                {liveDiaryList[0].map((item: any, id: any) => (

                  <option value={[item.date, item.slotType]}>
                    {moment(item.date).format('ddd MMM DD YYYY ') +
                      item.slotType
                    }

                  </option>
                ))
                }

              </select>
            }

            { !!selected &&
              <>
                <button style={ { marginTop: "10px" } } onClick={ openSendConfirmationModal }> Send
                  Confirmation
                </button>
                <Modal isOpen={ canShow } style={ customStyles } ariaHideApp={ false }>
                  <div style={ { marginBottom: "10px" } }>
                    Do you want to save the new booking slot and send a confirmation? <br />This will also
                    change the booking status to Installer Confirmed.
                  </div>


                  <div className="action">
                    <button onClick={ handleSendConfirmation } style={ { padding: "7px 12px" } }>
                      Send Confirmation
                    </button>
                    &nbsp;&nbsp;
                    <button onClick={ onClear } style={ { padding: "7px 12px" } }>Close</button>
                  </div>
                </Modal></>
          }
          </div>

          <div style={{
            height: "75%",
            width: "14%",
            display: "flex",
            marginLeft: "10%",
            marginTop: "15px",
            flexDirection: "column",
            position: "relative",
            left: "1%"
          }}>
            <div style={{
              border: "1px",
              borderColor: "#0059A1",
              borderStyle: "solid",
              display: "flex",
              marginBottom: "10px",
              padding: "28px",
              flexDirection: "column",
              position: "relative",
              left: "1%"
            }}>
              <span style={{ fontWeight: "bold" }}> Delivery Status</span>
              <select value={selectedDeliveryStatus} onChange={handleDeliveryChange}>
                {
                  Object.values(DeliveryStatus).map((item: any, id: any) => (
                      <option value={[item]} key={item}>
                        {item}
                      </option>
                    )
                  )
                }
              </select>
            </div>

            <button className="ActionButton" onClick={() => {
              setSelectedDeliveryStatus(DeliveryStatus.DELIVERED);
            }} disabled={selectedDeliveryStatus == DeliveryStatus.DELIVERED}>Delivered
            </button>
          </div>
        </div>

      </div>

      }


    </div >
  );
};

export default BookingDetailsPage;
