import ActionBar from "components/ActionBar/ActionBar";
import CustomList from "components/CustomList/CustomList";
import { useEffect, useState } from "react";
import {useHistory} from "react-router-dom"
import RestService from "services/RestService";
import SortingDirection from "utils/enums/SortingDirection";
import { COLUMN_HEADINGS } from "utils/constants/ReturnsPageColumnHeadings";
import "./ReturnsPage.css"

const ReturnsPage = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalNoOfPages, setTotalNoOfPages] = useState(1);
  const [ordersList, setOrdersList] = useState([]);
  const [sortingStatus, setSortingStatus] = useState({
    sortBy: "createDate",
    sortDirection: SortingDirection.DESC,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [finalFilterArray, setFinalFilterArray] = useState<any[]>([[{key: 'returnStatusEnumId.enumKey', operation: 'EQUAL_TO', value: 'ONGOING'}]]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const onOrderClick = (orderNumber: string) => {
    if (orderNumber && orderNumber !== "") 
    history.push("/returns/" + orderNumber);
  };

  useEffect(() => {
    setIsLoading(true);
    
  }, [page, sortingStatus, finalFilterArray, searchQuery]); 
 

  useEffect(() => {   
  if (!!isLoading) fetchOrderList();  
  }, [isLoading]);  

  const onFilterChange = (filterArray: any[]) => {
    setFinalFilterArray(filterArray);
  };

  const fetchOrderList = () => {  
    const filtersEncodedStr: string = encodeURIComponent(
      JSON.stringify(finalFilterArray)
    );
    const url =
    "/api/order-service/returns?pageNo=" +
      page +
      "&sortBy=" +
      sortingStatus.sortBy +
      "&sortDirection=" +
      sortingStatus.sortDirection +
      "&search=" +
      searchQuery +
      "&filter=" +
      filtersEncodedStr;

    const successCallback = (data: any) => {
      setTotalNoOfPages(data.totalNoOfPages);
      setOrdersList(data.items);
      setIsLoading(false);
    };

    //TODO: Manage console.error
    RestService.getRequest(url, successCallback, (err) => console.log(err));
  };

  const changeSortingOrder = (headingKey: string) => {
    setSortingStatus((prevSortingStatus) => {
      if (headingKey === prevSortingStatus.sortBy) {
        switch (prevSortingStatus.sortDirection) {
          case SortingDirection.ASC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.DESC,
            };
          case SortingDirection.DESC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
          default:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
        }
      } else {
        return {
          ...prevSortingStatus,
          sortBy: headingKey,
          sortDirection: SortingDirection.ASC,
        };
      }
    });
  };

  const changePage = (newPageNo: number) => {
    setPage(newPageNo);
  };

  const onSearchClick = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  return (
    <div className="ReturnsPage Page">
      <ActionBar
        title="Returns"
        onSearchClick={onSearchClick}
        columnHeadings={COLUMN_HEADINGS}
        displayCreateOrderButton={true}
        onFilterChange={onFilterChange}
      />
      {isLoading && <div className="loader"></div>}
      {!isLoading &&
        <CustomList
          columnHeadings={COLUMN_HEADINGS}
          listOfItems={ordersList}
          sortBy={sortingStatus.sortBy}
          sortDirection={sortingStatus.sortDirection}
          totalNoPages={totalNoOfPages}
          currentPage={page}
          changePage={changePage}
          changeSortingOrder={changeSortingOrder}
          onClickOrder={onOrderClick}
        />}
    </div>
  );
};

export default ReturnsPage;
