import ColumnHeadings from "interfaces/ColumnHeadings";
//#region ColumnHeadings
const itemsColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "productNumber",
        displayName: "Product Description",
        id: "1",
        internalPropertyName: "productNumber",
        filterOptions: [],
    },
    {
        propertyName: "productName",
        displayName: "Product Name",
        id: "2",
        internalPropertyName: "productName",
        filterOptions: [],
    },
    {
        propertyName: "quantity",
        displayName: "Quantity",
        id: "3",
        internalPropertyName: "quantity",
        filterOptions: [],
    },
    {
        propertyName: "unitPrice",
        displayName: "Unit Price",
        id: "4",
        internalPropertyName: "unitPrice",
        filterOptions: [],
    },
    {
        propertyName: "subTotal",
        displayName: "Sub-Total",
        id: "5",
        internalPropertyName: "subTotal",
        filterOptions: [],
    },
    {
        propertyName: "discount",
        displayName: "Discount",
        id: "6",
        internalPropertyName: "discount",
        filterOptions: [],
    },
    {
        propertyName: "installationFee",
        displayName: "Install",
        id: "7",
        internalPropertyName: "installationFee",
        filterOptions: [],
    },
    {
        propertyName: "specialPricing",
        displayName: "Special Pricing",
        id: "8",
        internalPropertyName: "specialPricing",
        filterOptions: [],
    },
    {
        propertyName: "tax",
        displayName: "Tax",
        id: "9",
        internalPropertyName: "tax",
        filterOptions: [],
    },
    {
        propertyName: "totalPrice",
        displayName: "Total Price",
        id: "10",
        internalPropertyName: "totalPrice",
        filterOptions: [],
    },
    {
        propertyName: "discountCode",
        displayName: "Disc. Code",
        id: "11",
        internalPropertyName: "discountCode",
        filterOptions: [],
    },
    {
        propertyName: "tireProtectionPlan",
        displayName: "Tire Protection Plan",
        id: "12",
        internalPropertyName: "tireProtectionPlan",
        filterOptions: [],
    },
    {
        propertyName: "lineItemStatus",
        displayName: "Line Item Status",
        id: "13",
        internalPropertyName: "lineItemStatus",
        filterOptions: [],
    },
    {
        propertyName: "replacedOrderNumber",
        displayName: "Replaced Order Number",
        id: "14",
        internalPropertyName: "replacedOrderNumber",
        filterOptions: [],
    }
];

const supplierLineItemColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "selected",
        displayName: "Selected",
        id: "1",
        internalPropertyName: "selected",
        filterOptions: [],
    },
    {
        propertyName: "supplierIdentifier",
        displayName: "Supplier",
        id: "2",
        internalPropertyName: "supplierIdentifier",
        filterOptions: [],
    },
    {
        propertyName: "supplierProductNumber",
        displayName: "Product Description",
        id: "3",
        internalPropertyName: "supplierProductNumber",
        filterOptions: [],
    },
    {
        propertyName: "totalQuantity",
        displayName: "Quantity",
        id: "4",
        internalPropertyName: "totalQuantity",
        filterOptions: [],
    },
    {
        propertyName: "replaceQuantity",
        displayName: "ReplaceQuantity",
        id: "5",
        internalPropertyName: "replaceQuantity",
        filterOptions: [],
    },
    {
        propertyName: "reason",
        displayName: "Reason",
        id: "6",
        internalPropertyName: "reason",
        filterOptions: [],
    },
    {
        propertyName: "newProduct",
        displayName: "Enter new TV number",
        id: "7",
        internalPropertyName: "newProduct",
        filterOptions: [],
    },
    // {
    //     propertyName: "search",
    //     displayName: "Search",
    //     id: "7",
    //     internalPropertyName: "search",
    //     filterOptions: [],
    // }
    ];

const shippingColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "supplier",
        displayName: "Supplier",
        id: "1",
        internalPropertyName: "supplier",
        filterOptions: [],
    },
    {
        propertyName: "deliveryCostsChargedBySupplier",
        displayName: "Delivery Cost by Supplier",
        id: "2",
        internalPropertyName: "deliveryCostsChargedBySupplier",
        filterOptions: [],
    },
    {
        propertyName: "deliveryMethod",
        displayName: "Delivery Method",
        id: "3",
        internalPropertyName: "deliveryMethod",
        filterOptions: [],
    },
    {
        propertyName: "deliveryService",
        displayName: "Delivery Service",
        id: "4",
        internalPropertyName: "deliveryService",
        filterOptions: [],
    },
    {
        propertyName: "deliveryStatus",
        displayName: "Delivery Status",
        id: "5",
        internalPropertyName: "deliveryStatus",
        filterOptions: [],
    },
    {
        propertyName: "estimatedDeliveryDate",
        displayName: "Estimated Delivery Date",
        id: "6",
        internalPropertyName: "estimatedDeliveryDate",
        filterOptions: [],
    },
    {
        propertyName: "fulfillmentStatus",
        displayName: "Fulfillment Status",
        id: "7",
        internalPropertyName: "fulfillmentStatus",
        filterOptions: [],
    },
    {
        propertyName: "supplierFulfillmentOrderNumber",
        displayName: "Supplier Fulfillment Order Number",
        id: "8",
        internalPropertyName: "supplierFulfillmentOrderNumber",
        filterOptions: [],
    },
    {
        propertyName: "supplierSource",
        displayName: "Supplier Source",
        id: "9",
        internalPropertyName: "supplierSource",
        filterOptions: [],
    },
    {
        propertyName: "supplierSourceDc",
        displayName: "Supplier Source DC",
        id: "10",
        internalPropertyName: "supplierSourceDc",
        filterOptions: [],
    },
    {
        propertyName: "supplierStatus",
        displayName: "Supplier Status",
        id: "11",
        internalPropertyName: "supplierStatus",
        filterOptions: [],
    },
    {
        propertyName: "trackingNumber",
        displayName: "Tracking Number",
        id: "12",
        internalPropertyName: "trackingNumber",
        filterOptions: [],
    },
    {
        propertyName: "trackingURL",
        displayName: "Tracking URL",
        id: "13",
        internalPropertyName: "trackingURL",
        filterOptions: [],
    }
];

const installColumnHeadings: ColumnHeadings[] = [
    
    {
        propertyName: "locationNumber",
        displayName: "Location Number",
        id: "1",
        internalPropertyName: "locationNumber",
        filterOptions: [],
    },
    {
        propertyName: "installerName",
        displayName: "Installer",
        id: "2",
        internalPropertyName: "installerName",
        filterOptions: []
    },
    {
        propertyName: "email",
        displayName: "Email address",
        id: "3",
        internalPropertyName: "email",
        filterOptions: [],
    },
    {
        propertyName: "phone",
        displayName: "Phone number",
        id: "4",
        internalPropertyName: "phone",
        filterOptions: [],
    },
    {
        propertyName: "address",
        displayName: "Address",
        id: "5",
        internalPropertyName: "address",
        filterOptions: [],
    },
    {
        propertyName: "zipCode",
        displayName: "Zip Code",
        id: "6",
        internalPropertyName: "zipCode",
        filterOptions: [],
    },
    {
        propertyName: "appointmentDate",
        displayName: "Appointment Date",
        id: "7",
        internalPropertyName: "appointmentDate",
        filterOptions: [],
    },
    {
        propertyName: "appointmentTime",
        displayName: "Appointment time",
        id: "8",
        internalPropertyName: "appointmentTime",
        filterOptions: [],
    }

];


const clickAndCollectColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "locationNumber",
        displayName: "Location Number",
        id: "1",
        internalPropertyName: "locationNumber",
        filterOptions: [],
    },
    {
        propertyName: "address",
        displayName: "Address",
        id: "2",
        internalPropertyName: "address",
        filterOptions: [],
    },
    {
        propertyName: "zipCode",
        displayName: "Zip Code",
        id: "3",
        internalPropertyName: "zipCode",
        filterOptions: [],
    },
    {
        propertyName: "estimatedReadyForCollectionDate",
        displayName: "Estimated Collection Date",
        id: "4",
        internalPropertyName:   "estimatedReadyForCollectionDate" ,
        filterOptions: [],
    },
 

];

const vehicleColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "year",
        displayName: "Year",
        id: "1",
        internalPropertyName: "year",
        filterOptions: [],
    },
    {
        propertyName: "make",
        displayName: "Make",
        id: "2",
        internalPropertyName: "make",
        filterOptions: [],
    },
    {
        propertyName: "model",
        displayName: "Model",
        id: "3",
        internalPropertyName: "model",
        filterOptions: [],
    },
    {
        propertyName: "VIN",
        displayName: "VIN",
        id: "4",
        internalPropertyName: "VIN",
        filterOptions: [],
    },
    {
        propertyName: "Mileage",
        displayName: "Mileage",
        id: "5",
        internalPropertyName: "Mileage",
        filterOptions: [],
    },
    {
        propertyName: "licensePlate",
        displayName: "License Plate",
        id: "6",
        internalPropertyName: "licensePlate",
        filterOptions: [],
    },
    {
        propertyName: "notes",
        displayName: "Notes",
        id: "7",
        internalPropertyName: "notes",
        filterOptions: [],
    },

];

const paymentsColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "pspReference",
        displayName: "PSPReference",
        id: "1",
        internalPropertyName: "pspReference",
        filterOptions: [],
    },
    {
        propertyName: "paymentDate",
        displayName: "PaymentDate",
        id: "2",
        internalPropertyName: "paymentDate",
        filterOptions: [],
    },
    {
        propertyName: "amount",
        displayName: "Amount",
        id: "3",
        internalPropertyName: "amount",
        filterOptions: [],
    },
    {
        propertyName: "method",
        displayName: "Method",
        id: "4",
        internalPropertyName: "method",
        filterOptions: [],
    },
    {
        propertyName: "paymentStatus",
        displayName: "Status",
        id: "5",
        internalPropertyName: "paymentStatus",
        filterOptions: [],
    },
    {
        propertyName: "type",
        displayName: "Type",
        id: "6",
        internalPropertyName: "type",
        filterOptions: [],
    },
    {
        propertyName: "channel",
        displayName: "Channel",
        id: "7",
        internalPropertyName: "channel",
        filterOptions: [],
    }
];
const creditCardColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "timeCreated",
        displayName: "Time Created",
        id: "1",
        internalPropertyName: "timeCreated",
        filterOptions: [],
    },
    {
        propertyName: "cardType",
        displayName: "Card Type",
        id: "2",
        internalPropertyName: "cardType",
        filterOptions: [],
    },
    {
        propertyName: "creditCardNumber",
        displayName: "Credit Card Number",
        id: "3",
        internalPropertyName: "creditCardNumber",
        filterOptions: [],
    },
    {
        propertyName: "creditCardOwnerName",
        displayName: "Credit Card Owner Name",
        id: "4",
        internalPropertyName: "creditCardOwnerName",
        filterOptions: [],
    },
    {
        propertyName: "creditCardValidToMonth",
        displayName: "Valid Till Month",
        id: "5",
        internalPropertyName: "creditCardValidToMonth",
        filterOptions: [],
    },
    {
        propertyName: "creditCardValidToYear",
        displayName: "Valid Till Year",
        id: "6",
        internalPropertyName: "creditCardValidToYear",
        filterOptions: [],
    },

];


const returnColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "rmaNumber",
        displayName: "RMA Number",
        id: "1",
        internalPropertyName: "rmaNumber",
        filterOptions: [],
    },
    {
        propertyName: "returnId",
        displayName: "Return ID",
        id: "2",
        internalPropertyName: "returnId",
        filterOptions: [],
    },
    {
        propertyName: "goodsToReturn",
        displayName: "Goods to return",
        id: "3",
        internalPropertyName: "goodsToReturn",
        filterOptions: [],
    },
    {
        propertyName: "reason",
        displayName: "Reason to return",
        id: "4",
        internalPropertyName: "reason",
        filterOptions: [],
    },
    {
        propertyName: "method",
        displayName: "Methods to return",
        id: "5",
        internalPropertyName: "method",
        filterOptions: [],
    },
    {
        propertyName: "returnFees",
        displayName: "Return Fees",
        id: "6",
        internalPropertyName: "returnFees",
        filterOptions: [],
    },
    {
        propertyName: "courierTrackingNumber",
        displayName: "Courier Tracking Number",
        id: "7",
        internalPropertyName: "courierTrackingNumber",
        filterOptions: [],
    },
    {
        propertyName: "returnSpecialist",
        displayName: "Return Specialist",
        id: "8",
        internalPropertyName: "returnSpecialist",
        filterOptions: [],
    },   
    {
        propertyName: "createDate",
        displayName: "Created Date",
        id: "9",
        internalPropertyName: "createDate",
        filterOptions: [],
    },{
        propertyName: "returnStatus",
        displayName: "Status",
        id: "10",
        internalPropertyName: "returnStatus",
        filterOptions: [],
    },{
        propertyName: "itemsReplaced",
        displayName: "Replaced?",
        id: "11",
        internalPropertyName: "itemsReplaced",
        filterOptions: [],
    },{
        propertyName: "action",
        displayName: "Action",
        id: "12",
        internalPropertyName: "action",
        filterOptions: [],
    }      

];

const refundColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "refundId",
        displayName: "Refund Number",
        id: "1",
        internalPropertyName: "refundId",
        filterOptions: [],
    },{
        propertyName: "purchaseOrderNumber",
        displayName: "Order Number",
        id: "2",
        internalPropertyName: "purchaseOrderNumber",
        filterOptions: [],
    },
    {
        propertyName: "goodsNotDelivered",
        displayName: "Goods not Delivered",
        id: "3",
        internalPropertyName: "goodsNotDelivered",
        filterOptions: [],
    },
    {
        propertyName: "amount",
        displayName: "Amount",
        id: "4",
        internalPropertyName: "amount",
        filterOptions: [],
    },
    {
        propertyName: "paymentType",
        displayName: "Payment Type",
        id: "5",
        internalPropertyName: "paymentType",
        filterOptions: [],
    },{
        propertyName: "rmaId",
        displayName: "RMA Id",
        id: "6",
        internalPropertyName: "rmaId",
        filterOptions: [],
    },{
        propertyName: "note",
        displayName: "Note",
        id: "7",
        internalPropertyName: "note",
        filterOptions: [],
    },{
        propertyName: "reason",
        displayName: "Reason",
        id: "8",
        internalPropertyName: "reason",
        filterOptions: [],
    },{
        propertyName: "refundStatus",
        displayName: "Status",
        id: "9",
        internalPropertyName: "refundStatus",
        filterOptions: [],
    },{
        propertyName: "closedDate",
        displayName: "Closed Date",
        id: "10",
        internalPropertyName: "closedDate",
        filterOptions: [],
    },{
        propertyName: "action",
        displayName: "Action",
        id: "11",
        internalPropertyName: "action",
        filterOptions: [],
        
    }   

];

const costsColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "supplier",
        displayName: "Supplier",
        id: "1",
        internalPropertyName: "supplier",
        filterOptions: [],
    },
    {
        propertyName: "costType",
        displayName: "cost Type",
        id: "2",
        internalPropertyName: "costType",
        filterOptions: [],
    },
    {
        propertyName: "invoice",
        displayName: "Invoice",
        id: "3",
        internalPropertyName: "invoice",
        filterOptions: [],
    },
    {
        propertyName: "date",
        displayName: "Date",
        id: "4",
        internalPropertyName: "date",
        filterOptions: [],
    },
    {
        propertyName: "dueDate",
        displayName: "DueDate",
        id: "5",
        internalPropertyName: "dueDate",
        filterOptions: [],
    },
    {
        propertyName: "quantity",
        displayName: "Quantity",
        id: "6",
        internalPropertyName: "quantity",
        filterOptions: [],
    },
    {
        propertyName: "cost",
        displayName: "Cost",
        id: "7",
        internalPropertyName: "cost",
        filterOptions: [],
    },
    {
        propertyName: "invoiceTotal",
        displayName: "InvoiceTotal",
        id: "8",
        internalPropertyName: "invoiceTotal",
        filterOptions: [],
    }
];

const eventsColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "event",
        displayName: "Event",
        id: "1",
        internalPropertyName: "event",
        filterOptions: [],
    },
    {
        propertyName: "date",
        displayName: "Date",
        id: "2",
        internalPropertyName: "date",
        filterOptions: [],
    },
    {
        propertyName: "notes",
        displayName: "Notes",
        id: "3",
        internalPropertyName: "notes",
        filterOptions: [],
    }
];

const notesColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "createdBy",
        displayName: "Created By",
        id: "1",
        internalPropertyName: "createdBy",
        filterOptions: [],
    },
    {
        propertyName: "createdDate",
        displayName: "Created Date",
        id: "2",
        internalPropertyName: "createdDate",
        filterOptions: [],
    },
    {
        propertyName: "note",
        displayName: "Note",
        id: "3",
        internalPropertyName: "note",
        filterOptions: [],
    },
    {
        propertyName: "noteUrl",
        displayName: "NoteUrl",
        id: "4",
        internalPropertyName: "noteUrl",
        filterOptions: [],
    }
];

const documentsColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "name",
        displayName: "Name",
        id: "1",
        internalPropertyName: "name",
        filterOptions: [],
    },
    {
        propertyName: "Type",
        displayName: "Type",
        id: "2",
        internalPropertyName: "Type",
        filterOptions: [],
    },
    {
        propertyName: "Date",
        displayName: "Date",
        id: "3",
        internalPropertyName: "Date",
        filterOptions: [],
    }, {
        propertyName: "downloadUrl ",
        displayName: "DownloadUrl ",
        id: "4",
        internalPropertyName: "downloadUrl ",
        filterOptions: [],
    }
];
const supplierColumnHeadings: ColumnHeadings[] = [{
    propertyName: "supplierOrderNumber",
    displayName: "SupplierOrderNumber",
    id: "1",
    internalPropertyName: "supplierOrderNumber",
    filterOptions: [],
}, {
    propertyName: "supplierLocationNumber",
    displayName: "SupplierLocationNumber",
    id: "2",
    internalPropertyName: "supplierLocationNumber",
    filterOptions: [],
},
{
    propertyName: "orderType",
    displayName: "OrderType",
    id: "3",
    internalPropertyName: "orderType",
    filterOptions: [],
}, {
    propertyName: "orderStatus",
    displayName: "OrderStatus",
    id: "4",
    internalPropertyName: "orderStatus",
    filterOptions: [],
}, {
    propertyName: "supplierIdentifier",
    displayName: "SupplierIdentifier",
    id: "5",
    internalPropertyName: "supplierIdentifier",
    filterOptions: [],
    // }, {
    //     propertyName: "supplierProductNumber",
    //     displayName: "SupplierProductNumber",
    //     id: "6",
    //     internalPropertyName: "supplierProductNumber",
    //     filterOptions: [],
}, {
    propertyName: "totalQuantity",
    displayName: "TotalQuantity",
    id: "7",
    internalPropertyName: "totalQuantity",
    filterOptions: [],
}, {
    propertyName: "lineItemStatus",
    displayName: "LineItemStatus",
    id: "8",
    internalPropertyName: "lineItemStatus",
    filterOptions: [],
}, {
    propertyName: "shippedQuantity",
    displayName: "ShippedQuantity",
    id: "9",
    internalPropertyName: "shippedQuantity",
    filterOptions: [],
}, {
    propertyName: "deliveredQuantity",
    displayName: "DeliveredQuantity",
    id: "10",
    internalPropertyName: "deliveredQuantity",
    filterOptions: [],
},
];
const shipmentColumnHeadings: ColumnHeadings[] = [{
    propertyName: "supplierFulfillmentOrderNumber",
    displayName: "FulfillmentOrderNumber",
    id: "1",
    internalPropertyName: "supplierFulfillmentOrderNumber",
    filterOptions: [],
},
{
    propertyName: "quantity",
    displayName: "Quantity",
    id: "2",
    internalPropertyName: "quantity",
    filterOptions: [],
}, {
    propertyName: "deliveryMethod",
    displayName: "DeliveryMethod",
    id: "3",
    internalPropertyName: "deliveryMethod",
    filterOptions: [],
}, {
    propertyName: "deliveryService",
    displayName: "DeliveryService",
    id: "4",
    internalPropertyName: "deliveryService",
    filterOptions: [],
}, {
    propertyName: "deliveryStatus",
    displayName: "DeliveryStatus",
    id: "5",
    internalPropertyName: "deliveryStatus",
    filterOptions: [],
}, {
    propertyName: "estimatedDeliveryDate",
    displayName: "EstimatedDeliveryDate",
    id: "6",
    internalPropertyName: "estimatedDeliveryDate",
    filterOptions: [],
}, {
    propertyName: "fulfillmentStatus",
    displayName: "FulfillmentStatus",
    id: "7",
    internalPropertyName: "fulfillmentStatus",
    filterOptions: [],
}, {
    propertyName: "supplierSource",
    displayName: "SupplierSource",
    id: "8",
    internalPropertyName: "supplierSource",
    filterOptions: [],
}, {
    propertyName: "supplierSourceDC",
    displayName: "SupplierSourceDC",
    id: "9",
    internalPropertyName: "supplierSourceDC",
    filterOptions: [],
}, {
    propertyName: "trackingNumber",
    displayName: "TrackingNumber",
    id: "10",
    internalPropertyName: "trackingNumber",
    filterOptions: [],
}, {
    propertyName: "trackingURL",
    displayName: "TrackingURL",
    id: "11",
    internalPropertyName: "trackingURL",
    filterOptions: [],
}
    //     , {
    //     propertyName: "isActive",
    //     displayName: "isActive",
    //     id: "12",
    //     internalPropertyName: "isActive",
    //     filterOptions: [],
    // }
];
const errorColumnHeadings: ColumnHeadings[] = [
    {
        propertyName: "createDate",
        displayName: "Time Stamp",
        id: "1",
        internalPropertyName: "createDate",
        filterOptions: [],
    },
    {
        propertyName: "processName",
        displayName: "Job Name",
        id: "2",
        internalPropertyName: "processName",
        filterOptions: [],
    },
    {
        propertyName: "methodName",
        displayName: "Service Method",
        id: "3",
        internalPropertyName: "methodName",
        filterOptions: [],
    },
    {
        propertyName: "error",
        displayName: "Error",
        id: "4",
        internalPropertyName: "error",
        filterOptions: [],
    }
];
//#endregion


const COLUMN_HEADINGS = {
    itemsColumnHeadings, shippingColumnHeadings, installColumnHeadings,clickAndCollectColumnHeadings, vehicleColumnHeadings,
    paymentsColumnHeadings, creditCardColumnHeadings, returnColumnHeadings, refundColumnHeadings, costsColumnHeadings, eventsColumnHeadings, notesColumnHeadings, documentsColumnHeadings,
    supplierColumnHeadings, shipmentColumnHeadings, errorColumnHeadings, supplierLineItemColumnHeadings
};

export { COLUMN_HEADINGS };