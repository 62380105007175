enum DeliveryStatus {
  NONE = "",
  DELIVERED = "Delivered",
  PARTIAL_ITEM_RECEIVED = "Partial Item Received",
  ITEMS_NOT_RECEIVED = "Items Not Received",
  DELIVERY_ISSUE = "Delivery Issue",
  NO_ANSWER = "No Answer - Followup",
  VOICEMAIL = "Voicemail - Followup",
  OTHER = "Other",
}

export default DeliveryStatus;
