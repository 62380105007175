import { useEffect, useState, SetStateAction } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import RestService from "../../services/RestService";
import "./OrdersPage.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const InsuranceListPage = () => {
  const date = new Date();
  let { id } = useParams<{ id: string }>();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [dealerDate, setDealerDate] = useState(date);
  const [liabilityAmount, setLiabilityAmount] = useState("");
  const [dealerName, setDealerName] = useState("");
  const [check, setChecked] = useState(false);
  const [checkId, setCheckID] = useState(false);

  const history = useHistory();
  const [toggle, setToggle] = useState(1);
  const [installerData, setInstallerData] = useState<any>();
  const [insuranceData, setInsuranceData] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [link, setLink] = useState<any>();


  const [isLoading, setIsLoading] = useState(true);

  const toggleTab = (index: SetStateAction<number>) => {
    setToggle(index);

    console.log('in RetailerTabs toggleTab index:', index);

  };

  const handleChangeFile = (e: any) => {
		setSelectedFile(e.target.files[0]);

	};

  const getInsuranceData = () => {
    const successCallback = (response: any) => {
      if(response.error){
        toast.error("Insurance details not found", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
        setDealerName("");
        setLiabilityAmount("");
        setEndDate(date);
        setStartDate(date);
        setDealerDate(date);
        setInsuranceData({});
        setChecked(false);
        setCheckID(true);
      } else {
        setInsuranceData(response);
        setDealerName(response.nameOnDealerPledge);
        setLiabilityAmount(response.liabilityAmount);
        setEndDate(new Date(response.expiryDate));
        setStartDate(new Date(response.startDate));
        setDealerDate(new Date(response.dateOfDealerPledge));
        setLink(response.liabilityDocumentUrl)
        response.ownerType == "LOCATION" ? setChecked(false) : setChecked(true);
      }
    }

    const url = "/api/installer-service/installers/management/liability-documents/" + id;
    RestService.getRequest(url, successCallback, (err) =>
      console.log(err)
    );
  }


  const saveInsuranceData = () => {
    const successCallback = (response : any) => {
      if(response.error){
        toast.error("Insurance Upadation failed for reason  "+ response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      } else {
        setCheckID(false);
        getInsuranceData();
        setInsuranceData(response);        
        toast.success("Updated Insurance Details.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success'
        });
        // cancelData();
        setToggle(1);
      }
    }
    const errorCallBack = () =>{
      toast.error("Insurance Upadation failed ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error'
      });
    }
    if(check === true){
      insuranceData.ownerType = "PARENT_COMPANY";
    } else {
      insuranceData.ownerType = "LOCATION";
    }
    var fd = new FormData();
    // fd.append("liabilityDocument",selectedFile);
    fd.append("ownerId",id);
    fd.append("ownerType",insuranceData.ownerType);
    fd.append("startDate",moment(startDate).format('YYYY-MM-DD'));
    fd.append("expiryDate",moment(endDate).format('YYYY-MM-DD'));
    fd.append("liabilityAmount",liabilityAmount);
    fd.append("dateOfDealerPledge",moment(dealerDate).format('YYYY-MM-DD'));
    fd.append("nameOnDealerPledge",dealerName);
    // fd.append("insuranceId",insuranceData.id || null);
    if(!checkId){      
      fd.append("insuranceId",insuranceData.id);
    }
    if(!!selectedFile){
      fd.append("liabilityDocument",selectedFile);

    }
    // let data = {
    //   "ownerId": id,
    //   "ownerType": insuranceData.ownerType,
    //   "startDate": startDate,
    //   "expiryDate": endDate,
    //   "liabilityAmount": liabilityAmount,
    //   "dateOfDealerPledge": dealerDate,
    //   "nameOnDealerPledge": dealerName,
    //   "insuranceId": insuranceData.id || null,
    //   "liabilityDocument": selectedFile
    // }
    console.log(fd)
    const url = "/api/installer-service/installers/management/liability-documents";
    fetch(url, {
      headers: {
          Authorization: "Bearer " + RestService.oauthAccessToken
      },
      method: 'POST',
      body: fd
  })
      .then((result) => {
          if (result.status === 401) {
              window.location.reload();
          }
          return result.json();
      })
      .then((response) => {
          successCallback(response);
      })
      .catch((error) => { console.log(error); });
    }

  const getInstallerData = () => {
    const successCallback = (response: any) => {

      setInstallerData(response);
    }
    const url = "/api/installer-service/installers/" + id;
    RestService.getRequest(url, successCallback, (err) =>
      console.log(err)
    );


  }

  const handleChange = (e: any, type: any) => {
    if (type == "amount") {
      setLiabilityAmount(e.target.value);
    } else {
      setDealerName(e.target.value);
    }
  }

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const onChangeDate = (date: any, type: any) => {
    if (type == "start") {
      setStartDate(date);
    } else if (type == "end") {
      setEndDate(date);
    } else {
      setDealerDate(date);
    }

  }

  useEffect(() => {
    if (!!isLoading) {
      getInsuranceData();
      getInstallerData();
    }

  }, [isLoading]);
  
  const cancelData  = () =>{
    toggleTab(1);
    if(!!insuranceData.id) {
      setDealerName(insuranceData?.nameOnDealerPledge);
      setLiabilityAmount(insuranceData?.liabilityAmount);
      setEndDate(new Date(insuranceData?.expiryDate));
      setStartDate(new Date(insuranceData?.startDate));
      setDealerDate(new Date(insuranceData?.dateOfDealerPledge));
      setLink(insuranceData.liabilityDocumentUrl)
      insuranceData.ownerType == "LOCATION" ? setChecked(false) : setChecked(true);
  } else {
        setDealerName("");
        setLiabilityAmount("");
        setEndDate(date);
        setStartDate(date);
        setDealerDate(date);
        setInsuranceData({});
        setChecked(false);
        setLink("");

  }
  console.log(startDate,insuranceData)
  }

  return (
    <div className="OrderDetailsPage"
    >
      <div className="OrderNumber">
      <h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
            Location Number  : <span className="groupDetails">{installerData?.atdLocationNumber}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
          Installer Id : <span className="groupDetails"> {installerData && JSON.stringify(installerData.id)}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
          Installer Name :  <span className="groupDetails">{installerData && JSON.stringify(installerData.name)}</span>
          </h4><h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
          Payment Group Name :<span className="groupDetails"> {installerData &&  installerData.paymentGroup?.companyName}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
                    Payment Group ID : <span className="groupDetails">{installerData && installerData.paymentGroup?.id}</span> 
                </h4>
      </div>
      {toggle === 1 && insuranceData && <div className="insuranceDetails">
        <div style={{ display: "inline-block", width: "45%" }}>
          <p>Insurance Start Date : <span>{insuranceData.startDate}</span></p><br></br>
          <p>Insurance End Date : <span>{insuranceData.expiryDate}</span></p><br></br>
          <p>Liability Amount : <span>${insuranceData.liabilityAmount}</span></p><br></br>
          <p style={{ maxWidth: "100%"}}>Liability Document Url: <a style={{ color: "#0059A1", textDecoration: "underline" }} download href= {insuranceData.liabilityDocumentUrl} target="__blank">{insuranceData.liabilityDocumentUrl}</a></p>
        </div>
        <div style={{ display: "inline-block", width: "45%", verticalAlign: "top" }}>
          <p>Date of Dealer Pledge: <span>{insuranceData.dateOfDealerPledge}</span></p><br></br>
          <p>Name of Dealer Pledge : <span>{insuranceData.nameOnDealerPledge}</span></p>
        </div>
        <button onClick={() => toggleTab(2)} className="editButton">Edit</button>
      </div>}

      { toggle === 2 && insuranceData && 
        <div className="insuranceDetails">
          <div style={{ display: "inline-block", width: "45%", verticalAlign: "top" }}>
            <div style={{ display: "inline-block", width: "70%" }}>
              <p style={{ maxWidth: "50%", float: "left" }}>Insurance Start Date : </p>
              <DatePicker
                dateFormat='yyyy/MM/dd'
                selected={startDate}
                startDate={startDate}
                onChange={(date) => onChangeDate(date, "start")}
                isClearable={true}
                className="dateStyles"
              />
              {/* <i className ="fa fa-calendar calenderStyles" aria-hidden="true"></i> */}
            </div><br></br>
            <div style={{ display: "inline-block", width: "70%" }}>
              <p style={{ maxWidth: "50%", float: "left" }}>Insurance End Date : </p>
              <DatePicker
                dateFormat='yyyy/MM/dd'
                selected={endDate}
                startDate={endDate}
                onChange={(date) => onChangeDate(date, "end")}
                isClearable={true}
                className="dateStyles"
              />
              {/* <i className ="fa fa-calendar calenderStyles" aria-hidden="true"></i> */}
            </div><br></br>
            <div style={{ display: "inline-block", width: "63%" }}>
              <p style={{ maxWidth: "40%", float: "left" }}>Liability Amount :</p>
              <input type="text" value={liabilityAmount} onChange={(e) => handleChange(e, "amount")} className="dateStyles"></input>
            </div><br></br>
            <div style={{ display: "inline-block", width: "70%" }}>
              <p style={{ maxWidth: "50%", float: "left" }}>Upload a file :</p>
              <input type="file" accept = ".png, .pdf, .jpg" name="file" onChange={(e) => handleChangeFile(e)}/>
            </div><br></br>
          </div>
          <div style={{ display: "inline-block", width: "45%", verticalAlign: "top" }}>
            <div style={{ display: "inline-block", width: "70%" }}>
              <p style={{ maxWidth: "59.5%", float: "left" }}>Date of Dealer Pledge: </p>
              <DatePicker
                dateFormat='yyyy/MM/dd'
                selected={dealerDate}
                startDate={dealerDate}
                onChange={(date) => onChangeDate(date, "dealer")}
                isClearable={true}
                className="dateStyles"
              />
              {/* <i className ="fa fa-calendar calenderStylesDealers" aria-hidden="true"></i> */}
            </div>
            <div style={{ display: "inline-block", width: "70%" }}>
              <p style={{ maxWidth: "50%", float: "left" }}>Name of Dealer Pledge :</p>
              <input type="text" value={dealerName} onChange={(e) => handleChange(e, "name")} className="dateStyles"></input>
            </div><br></br>
            <div style={{ display: "inline-block", width: "70%" }}>
              <p style={{ maxWidth: "50%", float: "left" }}>isParent Company :</p>
              
               <input type="checkbox"  defaultChecked = {check} onChange = {(e) => setChecked(!check)}  
                 className="dateStyles checkstyles"/>
            </div><br></br>
          
          </div><br></br>
          <button className="editButton" onClick={() => saveInsuranceData()} style={{ display: "inline-block" }}>Save</button>
          <button onClick={() => cancelData()} className="cancle">Cancel</button>
        </div>
      }
    </div>
  );
};

export default InsuranceListPage;