import { useIsAuthenticated } from "@azure/msal-react";
import LogIn from "components/LogIn";
import { BrowserRouter as Router } from "react-router-dom";
import RouteWrapper from "routes/RouteWrapper";
import { ToastContainer } from 'react-toastify';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './ApplicationInsightsService';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";

function App() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router>
        <div className="App">
          {!!isAuthenticated ? <RouteWrapper /> : <LogIn />}
          <ToastContainer hideProgressBar />
        </div>
      </Router>
    </AppInsightsContext.Provider>
  );
}

export default App;
