import ColumnHeadings from "interfaces/ColumnHeadings";

const COLUMN_HEADINGS: ColumnHeadings[] = [
    {
        propertyName: "purchaseOrderNumber",
        displayName: "Order Number",
        id: "orderNumber",
        internalPropertyName: "purchaseOrderNumber",
        filterOptions: [],
    },
    {
        propertyName: "refundId",
        displayName: "Refund Number",
        id: "refundNumber",
        internalPropertyName: "refundId",
        filterOptions: [],
    },
    {
        propertyName: "reason",
        displayName: "Reason",
        id: "reason",
        internalPropertyName:"refundReason.enumKey",
        filterOptions: ["TIRES_RETURNED","OUT_OF_STOCK","ORDER_CANCELLED_NEVER_SHIPPED","WARRANTY_APPROVED","CSAT_DELIVERY_ISSUE","CSAT_WEBSITE_PROMOTION","CSAT_CUSTOMER_SERVICE","CSAT_OUT_OF_STOCK","CSAT_WARRANTY_DECLINED","CSAT_INSTALLER_ISSUE","CSAT_TAX_REFUND"], 
    },
    {
        propertyName: "refundStatus", 
        displayName: "Status",
        id: "status",
        internalPropertyName: "refundStatus",
        filterOptions: ["PENDING","CREDIT APPLIED","REFUND APPLIED","AUTHORIZATION REVERSAL","NEED REVIEW"],
    },
    {
        propertyName: "paymentType",
        displayName: "Payment Type",
        id: "action",
        internalPropertyName: "paymentType.enumKey",
        filterOptions: ["PAYTOMORROW","CREDITCARD","GOOGLEPAY","PAYPAL","AFFIRM","PURCHASINGPOWER"],
    },
    {
        propertyName: "closedDate",
        displayName: "Closed Date",
        id: "action",
        internalPropertyName: "closedDate",
        filterOptions: [],
    },
    {
        propertyName: "note",
        displayName: "Notes",
        id: "action",
        internalPropertyName: "note",
        filterOptions: [],
    }
];

export { COLUMN_HEADINGS };