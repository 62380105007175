import DropdownOptions from "interfaces/DropdownOptions";
import { useEffect, useState } from "react";
import RestService from "services/RestService";
import "./FindOrder.css";
import SearchSection from "./SearchSection/SearchSection";
import FilterSpec from "interfaces/FilterSpec";
import ListSection from "./ListSection/ListSection";
import { COLUMN_HEADINGS } from "utils/constants/OrdersPageColumnHeadings";
import { FIND_ORDER_DROPDOWN_OPTIONS } from "utils/constants/FindOrderPageDropdownOptions";
import { toast } from "react-toastify";
import {useHistory} from "react-router-dom";

const FindOrder = () => {
  const history = useHistory();
  const [selectedDropdown, setSelectedDropdown] = useState<DropdownOptions | undefined>(FIND_ORDER_DROPDOWN_OPTIONS.find(x => x.id == 1));
  const [searchValue, setSearchValue] = useState<string>("");
  const [ordersList, setOrdersList] = useState([]);
  const [totalNoPages, setTotalNoPages] = useState(0);
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = [...dateRange];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ((!!searchValue && searchValue !== "") || selectedDropdown?.propertyName == "date") loadOrderList();
  }, [page]);

  const onDropdownChange = (e: any) => {
    const dropdownOption: DropdownOptions | undefined = FIND_ORDER_DROPDOWN_OPTIONS.find(
      (option) => option.id == e.target.value
    );
    setSelectedDropdown(dropdownOption);
    setDateRange([null, null]);
    setSearchValue("");
  };

  const onSearchValueChange = (e: any) => {
    setSearchValue(e.target.value);
  };
  const onDateChange = (e: any) => {
    setDateRange(e);
  }

  const onPageChange = (newPageNo: number) => {
    setPage(newPageNo);
  };

  const loadOrderList = (pageNo?: number) => {
    setIsLoading(true);
    const searchValueLength = searchValue.length;
    if (!!selectedDropdown) {
      pageNo = pageNo || page;
      const filterItem: FilterSpec = {
        key: selectedDropdown.databasePropertyName,
        operation: "LIKE",
        value: searchValue,
      };
      const filtersEncodedStr: string = encodeURIComponent(
        JSON.stringify([[filterItem]])
      );

      const successCallback = (data: any) => {
        setTotalNoPages(data.totalNoOfPages);
        setOrdersList(data.items);
        setIsLoading(false);
      };
      const getData = () => {
        const url =
          "/api/order-service/orders?pageNo=" + pageNo + "&filter=" + filtersEncodedStr;

        //TODO: Manage console.error
        RestService.getRequest(url, successCallback, (err) => console.log(err));
      }
      const clearData = () => {
        setTotalNoPages(0);
        setOrdersList([]);
        setIsLoading(false);
      }

      if (selectedDropdown.propertyName == 'CYBERSOURCE') {
        const url =
          "/api/order-service/orders/by-transaction-id?payment-channel=CYBERSOURCE" + "&transaction-id=" + searchValue;
        //TODO: Manage console.error
        RestService.getRequest(url, successCallback, (err) => console.log(err));
      } else if (selectedDropdown.propertyName == 'PAYPAL') {
        const url =
          "/api/order-service/orders/by-transaction-id?payment-channel=CYBERSOURCE&&payment-method=" + selectedDropdown.propertyName + "&transaction-id=" + searchValue;
        //TODO: Manage console.error
        RestService.getRequest(url, successCallback, (err) => console.log(err));
      } else if (selectedDropdown?.propertyName == 'date') {
        if (!startDate || !endDate) {
          toast.error("Select Start & End dates.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error'
          });
          setIsLoading(false);
        }
        const check = startDate && endDate ? Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1 : null;
        if (check && check <= 30) {
          const url = "/api/order-service/orders/by-date-range?startDate=" + toDateFormat(startDate) + "&endDate=" + toDateFormat(endDate) + "&pageNo=" + pageNo;
          //TODO: Manage console.error
          RestService.getRequest(url, successCallback, (err) => console.log(err));
        } else {
          toast.error("Date range should be less than 30.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error'
          });
          clearData();
        }
      } else if (selectedDropdown?.propertyName == 'email') {
        const emailCheck = new RegExp(/[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(searchValue);
        if (emailCheck) {
          getData();
        } else {
          toast.error("Enter valid email address.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error'
          });
          clearData();
        }

      } else if (selectedDropdown?.propertyName == 'orderNumber') {
        if (searchValueLength >= 5) {
          getData();
        } else {
          toast.error("Minimum of 5 characters required.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error'
          });
          clearData();
        }

      } else if (selectedDropdown?.propertyName == 'firstName' || selectedDropdown?.propertyName == 'lastName') {
        if (searchValueLength >= 3) {
          getData();
        } else {
          toast.error("Minimum of 3 characters required.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error'
          });
          clearData();
        }

      } else {
        getData();
      }
    }
  };
  const toDateFormat = (date: any) => {
    const dateObj = new Date(date);
    const dateMonth = dateObj.getMonth() + 1;
    const twoDigitMonth = ("0" + dateMonth).slice(-2);
    const twoDigitDate = ("0" + dateObj.getDate()).slice(-2);
    return dateObj.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
  }

  const onSearchClick = () => {
    if ((!!searchValue && searchValue !== "") || selectedDropdown?.propertyName == 'date') loadOrderList(1);
  };

  const onOrderClick = (orderNumber: string) => {
    if (orderNumber && orderNumber !== "") history.push("/orders/" + orderNumber);
  };

  return (
    <div className="FindOrder Page">
      <div className="container">
        <h1>Find Order</h1>
        <SearchSection
          dropdownOptions={FIND_ORDER_DROPDOWN_OPTIONS}
          selectedDropdownId={selectedDropdown?.id || 0}
          searchValue={searchValue}
          onDropdownChange={onDropdownChange}
          onSearchValueChange={onSearchValueChange}
          onSearchClick={onSearchClick}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
        />
        {isLoading && <div className="loader FindOrderLoader"></div>}

        {!isLoading &&
          !!ordersList && ordersList.length > 0 && (
            <ListSection
              columnHeadings={COLUMN_HEADINGS}
              listOfItems={ordersList}
              totalNoPages={totalNoPages}
              currentPage={page}
              changePage={onPageChange}
              onClickOrder={onOrderClick}
            />
          )}
      </div>
    </div>
  );
};

export default FindOrder;
