import CustomObjectType from "interfaces/CustomObjectType";
import ColumnHeadings from "interfaces/ColumnHeadings";
import IProps from "interfaces/IProps";
import React, { useEffect, useState } from "react";
import SortingDirection from "utils/enums/SortingDirection";
import "./CustomList.css";
import Pagination from "./Pagination";
import { useHistory } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import ModalPopUp from "components/ModalPopUp/ModalPopUp";
import ModalReturns from "pages/OrderDetailsPage/TabularSection/ModalPopUP/ModalReturns";
import ModalRefund from "pages/OrderDetailsPage/ModalSection/ModalPopUP/ModalRefund"
import ModalNotes from "components/ModalPopUp/ModalNotes";
import moment from "moment";
import RestService from "../../services/RestService";

interface Props extends IProps {
  sortBy?: string;
  sortDirection?: SortingDirection;
  columnHeadings: ColumnHeadings[];
  listOfItems: CustomObjectType[];
  totalNoPages: number;
  currentPage: number;
  changePage: (newPageNo: number) => void;
  changeSortingOrder: (internalPropertyName: string) => void;
  onClickOrder?: any;
  refundsList?: any,
  orderDetails?: any
}
const showShipmentColumnHeadings: ColumnHeadings[] = [{
  propertyName: "showShipment",
  displayName: "ShowShipment",
  id: "11",
  internalPropertyName: "showShipment",
  filterOptions: [],
}];
const CustomList = ({
  columnHeadings,
  listOfItems,
  sortBy,
  sortDirection,
  totalNoPages,
  currentPage,
  changePage,
  changeSortingOrder,
  onClickOrder,
  refundsList,
  orderDetails
}: Props) => {
  const [canShow, setCanShow] = useState(false);
  const [canShowRR, setCanShowRR] = useState(false);
  const [canShowRefund, setCanShowRefund] = useState(false);
  const [productNumber, setProductNumber] = useState(0);
  const [supplierData, setSupplierData] = useState<CustomObjectType[]>([]);
  const history = useHistory();
  const [returnsRefund, setReturnsRedund] = useState<any>();
  const [returnData, setReturnData] = useState(false);
  const [refundData, setRefundData] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [notes, setNotes] = useState('');
  const [afterClose, setAfterClose] = useState(false);



  const limit = 150;
  const onProductNumberClick = (order: any) => {
    setCanShow(true);
    setProductNumber(order.productNumber);
    setSupplierData(order.supplierLineItems);
  }
  

  const onBookingReferance = (order: any) => {
    if (order && order.bookingReferenceNumber !== "") {
      history.push("/bookingdetails/" + order.bookingReferenceNumber + "/" + order.installerId);
    }
  }

  const onReplacementIdClicked = (order: any) => {
    history.push("/orders/" + order.replacedOrderNumber);
    window.location.reload();
  }
  const showMore = (order: any) => {
    setShowAll(true);
    setNotes(order['note']);
  }




  const openModal = (order: any) => {

    let data = [];
    order.edit = true;
    order.createRefund = false;
    data.push(order)
    setReturnsRedund(data);

    if (order.refundId != null) {
      setRefundData(true);
      setCanShowRefund(true);
    } else {
      setReturnData(true);
      setCanShowRR(true);
    }

  }
  const onLegalParentClick = (order : any) =>{
    console.log("clicked")

  }

  const onInstallerClick = (order: any) => {
    const successCallback = (response: any) => {
      if (response.length > 0) {
        history.push("/installers/" + response[0].id);
      }
    }
    const url = "/api/installer-service/installers?locationNumber=" + order.locationNumber;
    RestService.getRequest(url, successCallback, (err) =>
      console.log(err));
  }

  const toggleShipmentInfo = (index: any) => {
    const newSdata: any = supplierData.map((item, i) => {
      if (index === i) {
        item = { ...item, showShipment: !item["showShipment"] };
      }
      return item;
    });
    setSupplierData(newSdata);
  }
  return (
    <div className="CustomList">
      <table>
        <thead>
          <tr>
            {columnHeadings.map((heading: ColumnHeadings, index: number) => (
              <th
                onClick={changeSortingOrder.bind(
                  this,
                  heading.internalPropertyName
                )}
                key={heading.id}
              >
                <div>
                  {heading.displayName}
                  {sortBy === heading.internalPropertyName && (
                    <React.Fragment>
                      {sortDirection === SortingDirection.ASC && (
                        <span className="fa fa-angle-up" />
                      )}
                      {sortDirection === SortingDirection.DESC && (
                        <span className="fa fa-angle-down" />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {listOfItems?.map((order: CustomObjectType) => {
            return (
              <tr key={order["id"]}>
                {columnHeadings.map((heading: ColumnHeadings) => (
                  heading.propertyName === 'estimatedReadyForCollectionDate' && order[heading.propertyName] ?
                    <td key={heading.id} >
                      {moment(order[heading.propertyName]).format('DD MMM YYYY')}</td> :
                    heading.propertyName == 'noteUrl' ?
                      !!order.noteUrl ? <td key={heading.id} className={order.noteUrl ? "Clickable" : ''}>
                        <a href={order[heading.propertyName]} target="_blank" style={{ 'color': 'blue', 'textDecorationLine': 'underline' }}>
                          Download Link</a></td> : <td key={heading.id}>
                      </td> :

                      heading.propertyName == 'note' ?
                        <td key={heading.id} className={order.note ? "Clickable" : ''}>
                          <div style={{
                            'maxWidth': '300px', 'textOverflow': 'ellipsis', 'height': 'auto', 'display': 'inlineBlock', 'overflow': 'hidden', "overflowWrap": "break-word"
                          }}>
                            {order[heading.propertyName].length <= limit ? order[heading.propertyName] : <div>{order[heading.propertyName].substring(0, limit) + "..."}  <span style={{ "textDecorationLine": "underline", 'color': 'blue' }} onClick={() => showMore(order)}>Read More</span> </div>}</div></td> :
                        heading.propertyName == 'productNumber' ?
                          <td key={heading.id}>
                            <span className={order.productNumber ? "Clickable" : ''} onClick={() => onProductNumberClick(order)}
                              style={{ 'color': 'blue', 'textDecorationLine': 'underline' }}>
                              {order[heading.propertyName]}</span></td> :
                          heading.propertyName == 'bookingReferenceNumber' ?
                            <td key={heading.id}>
                              <span className={order.bookingReferenceNumber ? "Clickable" : ''} onClick={() => onBookingReferance(order)}
                                style={{ 'color': 'blue', 'textDecorationLine': 'underline' }}>
                                {order[heading.propertyName]}</span></td> :

                            heading.propertyName == 'locationNumber' ?
                              <td key={heading.id}>
                                <span className={order.locationNumber ? "Clickable" : ''} onClick={() => onInstallerClick(order)}
                                  style={{ 'color': 'blue', 'textDecorationLine': 'underline' }}>
                                  {order[heading.propertyName]}</span></td> :

                              heading.propertyName == 'createdDate' ?
                                <td key={heading.id}>{new Date(order[heading.propertyName]).toUTCString()}</td> :

                                heading.propertyName == 'createDate' ?
                                  <td key={heading.id}>{new Date(order[heading.propertyName]).toUTCString()}</td> :


                                  heading.propertyName == 'replacedOrderNumber' ?
                                    <td key={heading.id}>
                                      <span className={order.productNumber ? "Clickable" : ''} onClick={() => onReplacementIdClicked(order)}
                                        style={{ 'color': 'blue', 'textDecorationLine': 'underline' }}>
                                        {order[heading.propertyName]}</span></td> :


                                    // (!!order.refundStatus && heading.propertyName == 'action' && (order.refundStatus).toUpperCase() !== ("CREDIT APPLIED") ) ?
                                    //   <td key={heading.id}>
                                    //     <button className={order.productNumber ? "Clickable" : ''} onClick={() => openModal(order)}
                                    //       style={{ borderRadius: "5px" }}>
                                    //        {"Edit"}</button></td> :

                                      (!!order.refundStatus &&  heading.propertyName == 'action' && ((order.refundStatus).toUpperCase() == ("CREDIT APPLIED") ||(order.refundStatus).toUpperCase() == ("CANCELLED") ||
                                      (order.refundStatus).toUpperCase() == ("REFUND APPLIED") )) ?
                                        <td key={heading.id}>
                                          <p></p>
                                        </td> :

                                         (!!order.returnStatus &&  heading.propertyName == 'action' && ((order.returnStatus).toUpperCase() == ("COMPLETED WITH REFUND")|| (order.returnStatus).toUpperCase() == ("COMPLETED WITHOUT REFUND") ||(order.returnStatus).toUpperCase() == ("CANCELLED"))) ?
                                         <td key={heading.id}>
                                           <p></p>
                                         </td> :

                                        (heading.propertyName == 'action') ?
                                          <td key={heading.id}>
                                            <button className={order.productNumber ? "Clickable" : ''} onClick={() => openModal(order)}
                                              style={{ borderRadius: "5px" }}>
                                                {"Edit"}
                                              </button></td> :

                                          (heading.propertyName == "installPaid") ?
                                            <td key={heading.id}>
                                              {order.installPaid ? "Yes" : "No"}
                                            </td> :

                                         

                                          order.bookingReferenceNo && (
                                            <td key={heading.id} className={(order.bookingReferenceNo) ? "Clickable" : ''}
                                              onClick={() => order.bookingReferenceNo ? onClickOrder(order.bookingReferenceNo) :
                                                order.bookingReferenceNo ? onClickOrder(order) : ''}>  {order[heading.propertyName]} </td>)
                                                 ||

                                          order.companyName && (
                                            <td key={heading.id} className={(order.companyName) ? "Clickable" : ''}
                                              onClick={() => order.companyName ? onClickOrder(order) :
                                                ''}>
                                              {order[heading.propertyName]} </td>) ||

                                               order.purchaseOrderNumber && (
                                                <td key={heading.id} className={(order.purchaseOrderNumber) ? "Clickable" : ''}
                                                  onClick={() => order.purchaseOrderNumber ? onClickOrder(order.purchaseOrderNumber) :
                                                    order.purchaseOrderNumber ? onClickOrder(order) : ''}>
                                                  {order[heading.propertyName]} </td>)


                                          ||
                                          !order.bookingReferenceNo && (
                                            <td key={heading.id} className={(order.orderNumber || order.orderNo || order.number) ? "Clickable" : ''}
                                              onClick={() => order.orderNumber || order.orderNo ? onClickOrder(order.orderNumber || order.orderNo) :
                                                order.number ? onClickOrder(order) : ''}>
                                              {order[heading.propertyName]} </td>)
                ))}

              </tr>
            );
          })}
        </tbody>
      </table>
      {totalNoPages !== 1 && (
        <Pagination
          currentPage={currentPage}
          totalNoPages={totalNoPages}
          changePage={changePage}
        />
      )}
      <div>
        <ModalPopUp
          productNumber={productNumber}
          canShow={canShow}
          setCanShow={setCanShow}
          setProductNumber={setProductNumber}
          supplierData={supplierData}
          setSupplierData={setSupplierData}
          toggleShipmentInfo={toggleShipmentInfo}
        /></div>
      <div><ModalNotes canShow={showAll} setCanShow={setShowAll} note={notes} /></div>
      { !!returnsRefund && canShowRefund && !!refundData && <div>
        <ModalRefund
          canShow={canShowRefund}
          setCanShow={setCanShowRefund}
          refundData={returnsRefund}
          orderData={listOfItems}
          edit={true}
          refundsList={refundsList}
          orderDetails={orderDetails}
        />
      </div>}
      { !!returnsRefund && canShowRR && !!returnData && <div>
        <ModalReturns
          canShow={canShowRR}
          setCanShow={setCanShowRR}
          returnData={returnsRefund}
          orderData={listOfItems}
          edit={true}
          afterClose={false}
          setAfterClose={setAfterClose}
        />
      </div>}
    </div>
  );
};

export default CustomList;
