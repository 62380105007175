import CustomList from "components/CustomList/CustomList";
import ColumnHeadings from "interfaces/ColumnHeadings";
import CustomObjectType from "interfaces/CustomObjectType";
import "./ListSection.css";
import IProps from "interfaces/IProps";

interface Props extends IProps {
  columnHeadings: ColumnHeadings[];
  listOfItems: CustomObjectType[];
  totalNoPages: number;
  currentPage: number;
  changePage: (newPageNo: number) => void;
  onClickOrder?: any;
}

const ListSection = ({
  columnHeadings,
  listOfItems,
  totalNoPages,
  currentPage,
  changePage,
  onClickOrder,
}: Props) => {
  return (
    <div className="ListSection">
      <CustomList
        columnHeadings={columnHeadings}
        listOfItems={listOfItems}
        totalNoPages={totalNoPages}
        currentPage={currentPage}
        changePage={changePage}
        changeSortingOrder={() => {}}
        onClickOrder={onClickOrder}
      />
    </div>
  );
};

export default ListSection;
