import ColumnHeadings from "interfaces/ColumnHeadings";

const COLUMN_HEADINGS: ColumnHeadings[] = [
    {
        propertyName: "purchaseOrderNumber",
        displayName: "Order Number",
        id: "orderNumber",
        internalPropertyName: "purchaseOrderNumber",
        filterOptions: [],
    },
    {
        propertyName: "rmaNumber",
        displayName: "RMA Number",
        id: "rmaNumber",
        internalPropertyName: "rmaNumber",
        filterOptions: [],
    },{
        propertyName: "reason",
        displayName: "Reason",
        id: "reason",
        internalPropertyName: "returnReasonEnumId.enumKey",
        filterOptions: ["CUSTOMER_ERR_WRONG_SIZE_ORDERED","CUSTOMER_ERR_WRONG_SPEED_LOAD","CUSTOMER_ERR_WRONG_BRAND_ORDERED","CUSTOMER_ERR_NO_LONGER_NEEDED","WEBSITE_ERR_SUGGESTED_WRONG_ITEMS","INSTALLER_ISSUE_UNABLE_TO_INSTALL","INSTALLER_ISSUE_PERMANTLY_CLOSED","NSTALLER_ISSUE_REFUSED_TO_INSTALL","ATD_ERR_WRONG_ITEM","ATD_ERR_PRODUCT_CONDITION","FEDEX_ERR_SHIPPING_TRANSIT_DAMAGE","DELAYED_SHIPPING_DELIVERY","TIRES_NEVER_SHIPPED","DISCOUNT_NOT_APPLIED"],
    },{
        propertyName: "createDate",
        displayName: "Created Date",
        id: "createdDate",
        internalPropertyName: "createDate",
        filterOptions: [],
    },{
        propertyName: "method",
        displayName: "Return Method",
        id: "returnMethod",
        internalPropertyName: "returnMethodEnumId.enumKey",
        filterOptions: ["CUSTOMER_DROP_AT_COURIER","ATD_ROUTE_PICKUP","COURIER_PICKUP_FROM_CUSTOMER","FREE_RETURN_DROP","FREE_RETURN_PICKUP","REFUSED_DELIVERY"],
    },{
        propertyName: "returnSpecialist",
        displayName: "Return Specialist",
        id: "returnSpecialist",
        internalPropertyName: "returnSpecialist",
        filterOptions: [],
    },{
        propertyName: "returnStatus", 
        displayName: "Status",
        id: "status",
        internalPropertyName: "returnStatusEnumId.enumKey",
        filterOptions: ["ONGOING","COMPLETE","CREATED","LABELS CREATED","LOST IN TRANSIT", "COMPLETED WITH REFUND","COMPLETED WITHOUT REFUND", "CANCELLED"],
    }
];

export { COLUMN_HEADINGS };