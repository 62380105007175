import FilterBox from "components/FilterBox/FilterBox";
import ColumnHeadings from "interfaces/ColumnHeadings";
import CustomObjectType from "interfaces/CustomObjectType";
import IProps from "interfaces/IProps";
import { useEffect, useState } from "react";
import "./ActionBar.css";

interface Props extends IProps {
  title: string;
  columnHeadings: ColumnHeadings[];
  displayCreateOrderButton?: boolean;
  onSearchClick: (searchQuery: string) => void;
  onFilterChange: (filterArray: any[]) => void;
}

const ActionBar = ({
  title,
  columnHeadings,
  onSearchClick,
  onFilterChange,
  displayCreateOrderButton = false,
}: Props) => {
  const [isFilterVisible, setFilterVisibility] = useState<Boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [checkedStatus, setCheckedStatus] = useState<CustomObjectType>({});

  const openFilterBox = () => setFilterVisibility(true);
  const closeFilterBox = () => setFilterVisibility(false);
  const onSearchStringChange = (e: any) => setSearchQuery(e.target.value);
  const onSearchClickHandler = () => {
    onSearchClick(searchQuery);
  };
  const handleKeypress = (e: { key: string; }) => {
    if (e.key === "Enter") {
      onSearchClickHandler();
    }
  };


  useEffect(() =>{
    if(title  == "Returns"){
      setCheckedStatus({ONGOING: true});
      // onFilterChange([{key: 'returnStatusEnumId.enumKey', operation: 'EQUAL_TO', value: 'ONGOING'}]);
    } else if(title == "Refunds"){
      setCheckedStatus({PENDING: true,"AUTHORIZATION REVERSAL": true,"NEED REVIEW": true});
    }
  },[])

  const toggleCheckbox = (filterOption: string, propertyName: string, columnHeading: any) => {
    if (propertyName === "installerFeeStatus") {
      if (!!checkedStatus.Paid) {
        setCheckedStatus({"Pending": false})
      } else {
        setCheckedStatus({"Paid": false})
      }
    }
    setCheckedStatus((prevCheckedStatus) => {
      const newCheckedStatus: CustomObjectType = {
        [filterOption]: !!!prevCheckedStatus[filterOption],
      };
      Object.keys(prevCheckedStatus).forEach(
        (key) =>
        (newCheckedStatus[key] =
          key === filterOption
            ? !!!prevCheckedStatus[key]
            : prevCheckedStatus[key])
      );
      return newCheckedStatus;
    });
  };
  return (
    <div className="ActionBar">
      <div className="title">
       <h1>{title}</h1>
      </div> 
      <div className="action-items">
        {/* {displayCreateOrderButton ? (
          <div className="create-order">
            <button>Create Order</button>
          </div>
        ) : (
          <div />
        )} */}
         {(title != "Payment Group List" ) && <div className="search-filter">
         <input
            type="text"
            className="search"
            placeholder="Search"
            value={searchQuery}
            onChange={onSearchStringChange}
            onKeyPress={handleKeypress}
          /> 
          <button
            className="search-button fa fa-search"
            onClick={onSearchClickHandler}
          ></button>
          <span
            className="filter-button fa fa-filter"
            onClick={openFilterBox}
          ></span> 
          {!!isFilterVisible && (
            <FilterBox
              closeFilterBox={closeFilterBox}
              columnHeadings={columnHeadings}
              onFilterChange={onFilterChange}
              checkedStatus={checkedStatus}
              setCheckedStatus={setCheckedStatus}
              toggleCheckbox={toggleCheckbox}
            />
          )}
        </div> }
      </div> 
    </div>
  );
};

export default ActionBar;
