import { useMsal } from "@azure/msal-react";
const LogOut = () => {

    const { instance, accounts } = useMsal();
    const logoutRequest = {
        account: accounts[0],
        postLogoutRedirectUri: "/orders/find-order"
    };
    instance.logoutRedirect(logoutRequest).catch((e: any) => {
        console.error(e);
    });

    return null;
}

export default LogOut;