import IProps from "../../../../interfaces/IProps";
import Modal from 'react-modal';
import "./ShowBookingCreation.css";
import { SetStateAction,useEffect, useState } from "react";
import RestService from "services/RestService";
import { toast } from 'react-toastify';
import closeMark  from "../../../../assets/closeMark.png";
import moment from "moment";
import CustomObjectType from "interfaces/CustomObjectType";

interface Props extends IProps {
    canShow: any;
    setCanShow: (e: any) => void;   
    installerId: any;
    orderNumber: any;
 }
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '30%',
        padding:"0px 20px 0 20px",
        // borderRadius:"25px",
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto'
    },
};



const ShowBookingCreation = ({ canShow, setCanShow, installerId, orderNumber}: Props) => {
    const [liveDiaryList, setLiveDiaryList] = useState<CustomObjectType[]>([]);
    const [liveDiaryCalendar, setLiveDiaryCalendar] = useState<CustomObjectType[]>([]);
    const [selected, setSelected] = useState<string>((moment(new Date).format('YYYY-MM-DD,A')).toString());

    const onClear = () => {
        setCanShow(false); 
        setSelected(""); 
    }

    useEffect(()=>{
        getLiveDiaryCalendar();
    },[])

    useEffect(() => {
    }, [liveDiaryList]);

    const getLiveDiaryCalendar = () => {
        var tempDate = new Date();
        let currDate = moment(tempDate).format('YYYY-MM-DD');
    
        const url = "/api/installer-service/management/installers/" + installerId + "/bookings/availability?delivery-date=" + currDate;
    
        const successCallbackLiveDiary = (liveDiaryCalendar: any) => {
          setLiveDiaryCalendar(liveDiaryCalendar);
    
          var i = 0;
          var j = -1;
          let newLiveDiaryList: any[] = []
          for (i = 0; i < liveDiaryCalendar.dates.length; i++) {
            if (liveDiaryCalendar.dates[i].noOfAmSlots > 0) {
              j++;
              newLiveDiaryList[j] =
              {
                date: liveDiaryCalendar.dates[i].date,
                slotType: 'AM'
              };
            }
    
            if (liveDiaryCalendar.dates[i].noOfPmSlots > 0) {
              j++;
              newLiveDiaryList[j] =
              {
                date: liveDiaryCalendar.dates[i].date,
                slotType: 'PM'
              };
            }
    
            setLiveDiaryList(liveDiaryList => [...liveDiaryList, newLiveDiaryList]);
          }
    
    
        };
        RestService.getRequest(url, successCallbackLiveDiary, (err) => console.log('Error Get Live Diary err:', err));
    
      }
    
      const handleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setSelected(e.target.value);
    
      };

    const onSaveData = () =>{
        let arr = selected.split(",");
        const url = "/api/order-service/orders/create-booking";
        const data = {
            "purchaseOrderNumber": orderNumber,
            "appointmentDate":arr[0],
            "appointmentSlot":arr[1]
        };
    const successCallback = (response: any) => {
     window.location.reload();
    };
    const errorCallback = (response: any) => {      
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error'
      });
    }
    RestService.postRequest(url, data, successCallback, (err) => errorCallback(err));
    };    


    return <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
        <>
        {
             <div style={{ padding: "28px 0 0px 0", height: "75%", width: "100%",
                display: "flex", flexDirection: "column", position: "relative", left: "1%"
              }}>
                <span style={{ fontWeight: "bold", marginBottom:"10px" }}> Booking Slot</span>
    
    
                {liveDiaryList[0] &&
                  <select style={{width: "45%",height:"40px"}} value={selected} onChange={handleChange} >
    
                    {liveDiaryList[0].map((item: any, id: any) => (
    
                      <option value={[item.date, item.slotType]}>
                        {moment(item.date).format('ddd MMM DD YYYY ') +
                          item.slotType
                        }
    
                      </option>
                    ))
                    }
    
                  </select>
                }
                <div style={{ paddingBottom: "0"}}>
                <button style={{ 'float': 'right', background: "#fff", color: "#0071BC", borderRadius: "5px", border: "1px solid #0071BC" }} onClick={onClear}>Close</button>&nbsp;
               <button style={{ 'float': 'right', marginRight: '10px',marginBottom: '15px', borderRadius: "5px" }} onClick={onSaveData} >Save</button>&nbsp;

               </div>
              </div>
        }           
        </>
    </Modal>

};

export default ShowBookingCreation;