import React, { SetStateAction, useEffect, useState, } from "react";
import "./RetailerTabs.css";
import "./RetailerUploadPage"
import RetailerUploadPage from "./RetailerUploadPage";
import NewBookingsListPage from "./NewBookingsListPage";
import InsuranceListPage from "./InsuranceListPage";
import LegalParentsPage from "./LegalParentsPage";
import RestService from "../../services/RestService";
import { useParams } from "react-router-dom";
import ModalMakePayments from "../OrderDetailsPage/TabularSection/ModalPopUP/ModalMakePayments";

const RetailerTabs = () => {
  const [toggleState, setToggleState] = useState(1);
  const [canPayment, setPayment] = useState(false);
  const [installerData, setInstallerData] = useState<any>();
  let { id } = useParams<{ id: string }>();
  const [showPayment, setShowPayment] =  useState(false);

  const toggleTab = (index: SetStateAction<number>) => {
    setToggleState(index);

    console.log('in RetailerTabs toggleTab index:', index);

  };

  useEffect(() =>{
    getInstallerData();
  },[]);

  const getInstallerData = () => {
    const successCallback = (data: any) => {
        setInstallerData(data);
        if(data.paymentGroup == null || data.paymentGroup.paymentMethod == "CARD"){
          setPayment(true);
        } else {
          setPayment(false);
        }
    }
    const url = "/api/installer-service/installers/" + id;
    //TODO: Manage console.error
    RestService.getRequest(url, successCallback, (err) =>
        console.log(err)
    );
}

const openModalMakePayment = () =>{
  setShowPayment(true);
}


  return (
    <div className="container">
      <div className="filler" />

      <div className="content-tabs">
        <div className="filler" />
        <div className="bloc-tabs">
          <span style={{ width: "30px" }}></span>
          <button className="retailertabs"
            onClick={() => toggleTab(1)}

          > VIP</button>
          <button className="retailertabs"
            onClick={() => toggleTab(2)}
          >Booking</button>
            <button className="retailertabs"
            onClick={() => toggleTab(3)}
          >Insurance</button>
           <button className="retailertabs"
            onClick={() => toggleTab(4)}
          >Payment Group</button>
        </div>

        {   installerData &&  canPayment    &&  <button className = "makePaymentButton" onClick={() => openModalMakePayment()} >Make Payment</button> }

        {toggleState === 1 && <div className={toggleState === 1 ? "content1  active-content" : "content1"} >
          <RetailerUploadPage orderNumber={undefined} Tab={undefined} />
        </div>}

        {toggleState === 2 && <div className={toggleState === 2 ? "content1  active-content" : "content1"} >
 
            <NewBookingsListPage />
        

        </div>}


        {toggleState === 3 && <div className={toggleState === 3 ? "content1  active-content" : "content1"} >
         
            <InsuranceListPage />
      

        </div>}

        {toggleState === 4 && <div className={toggleState === 4 ? "content1  active-content" : "content1"} >
         
            <LegalParentsPage />
          

        </div>}
 
      </div>
      <div>
     { installerData && showPayment && <ModalMakePayments
       canShow={showPayment}
       setCanShow={setShowPayment}
       orderData={installerData}
       installerId = {id }
       clickedFrom = {"installer"}
       installerLocation = {installerData?.atdLocationNumber}
      />}
      </div>
    </div>
  );
}

export default RetailerTabs;