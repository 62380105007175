import "./Navbar.css";
import { useEffect, useState } from "react";
import logo from "assets/images/logos/tirebuyer-logo-driven.svg";
import NavMenu from "./Menu/NavMenu";
import { useIsAuthenticated } from "@azure/msal-react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useHistory } from 'react-router-dom';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const Navbar = () => {
  // const isAuthenticated = useIsAuthenticated();
  // return (
  //   <div className="Navbar">
  //     <img className="logo" src={logo} alt="Logo" />
  //     <span className="menus">
  //       { /* <a href="/dashboard">Dashboard</a> */ }
  //       <a href="/orders/find-order">Find-Order</a>
  //       <a href="/orders">Orders</a>
  //       <a href="/installers/find-installer">Installers</a>
  //       <a href="/returns">Returns</a>
  //       <a href="/refunds">Refunds</a>
  //       {/* <a href="/supplier-invoices">Supplier Invoices</a>
  //       <a href="/reports">Reports</a>
  //       <a href="/settings">Settings</a> */}
  //     </span>
  //     {isAuthenticated ? <a className="logout" href="/log-out">LogOut</a> : ''}

  //   </div>
  // );
  const isAuthenticated = useIsAuthenticated();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElInsatller, setAnchorElInsatller] = useState(null);
  const open = Boolean(anchorEl);
  const openInsatller = Boolean(anchorElInsatller);
  const history = useHistory();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickInstaller = (event: any) => {
    setAnchorElInsatller(event.currentTarget);
  }
  const handleClose = (link : any) => {
    history.push(link);
    setAnchorEl(null);
    setAnchorElInsatller(null);
  };

  return (
    <>
      <div className="Navbar">
      <img className="logo" src={logo} alt="Logo" />
      <Button
        id="fade-button-orders"
        aria-controls={open ? 'fade-menu-order' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Orders
        <ArrowDropDownOutlinedIcon className="downArrow"></ArrowDropDownOutlinedIcon>
      </Button>
      <Button
        id="fade-button-insatller"
        aria-controls={openInsatller ? 'fade-menu-insatller' : undefined}
        aria-haspopup="true"
        aria-expanded={openInsatller ? 'true' : undefined}
        onClick={handleClickInstaller}
      >
        Installers
        <ArrowDropDownOutlinedIcon className="downArrow"></ArrowDropDownOutlinedIcon>
      </Button>
      {isAuthenticated ?  <Button
        id="fade-button"
        className ="logout"
        aria-haspopup="false"
        onClick={ ()=>{ history.push("/log-out")}}>
        LogOut
      </Button>: "" }
      </div>
     
      <Menu
        id="fade-menu-order"
        MenuListProps={{
          'aria-labelledby': 'fade-button-orders',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>handleClose("/orders")}>Orders</MenuItem>
        <MenuItem onClick={()=>handleClose("/orders/find-order")}>Find-Order</MenuItem>
        <MenuItem onClick={()=>handleClose("/returns")}>Returns</MenuItem>
        <MenuItem onClick={()=>handleClose("/refunds")}>Refunds</MenuItem>
        <MenuItem onClick={()=>handleClose("/installBookings")}>Install Bookings</MenuItem>
      </Menu>

      <Menu
        id="fade-menu-insatller"
        MenuListProps={{
          'aria-labelledby': 'fade-button-insatller',
        }}
        anchorEl={anchorElInsatller}
        open={openInsatller}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>handleClose("/installers/find-installer")}>Find Installer</MenuItem>
        <MenuItem onClick={()=>handleClose("/payment-group-list")}>Payment Group List</MenuItem>
        <MenuItem onClick={()=>handleClose("/rms-data")}>RMS Data</MenuItem>
      </Menu>
    </>
  );
};

export default Navbar;
