import { useEffect, useState } from "react";
import IProps from "interfaces/IProps";
import Modal from 'react-modal';
import "./ModalSection.css";
import RestService from "services/RestService";
import ColumnHeadings from "interfaces/ColumnHeadings";
import CustomObjectType from "interfaces/CustomObjectType";
interface Props extends IProps {
    canShow: any;
    action: any;
    noteData: any;
    orderData: any;
    setCanShow: (e: any) => void;
    createNote: (e: any) => void;
    // cancelOrder: (e: any) => void;
    cancelOrder: () => void;
    compensationRequest: (e: any) => void;
    returnRequest: (e: any) => void;
    replacementRequest: (e: any) => void;
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: 'max-content',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const ModalSection = ({ canShow, action, noteData, orderData, setCanShow,
    createNote, cancelOrder, compensationRequest, returnRequest, replacementRequest }: Props) => {
    const [note, setNote] = useState('');
    const [file, setFile] = useState('');
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [compensationReasonOptions, setCompensationReasonOptions] = useState(['']);
    const [selectedCompensationReason, setSelectedCompensationReason] = useState<string>(' ');
    const [compensationAmount, setCompensationAmount] = useState<number>(0);
    const [cancelSubmitBoolean, setCancelSubmitBoolean] = useState<boolean>(true);
    const [returnReasonOptions, setReturnReasonOptions] = useState(['']);
    const [replacementReasonOptions, setReplacementReasonOptions] = useState(['']);
    const [orderStatus,setOrderStatus] = useState('');
    const [lineItemStatus,setLineItemsStatus] = useState('');
    const [fullReplacement, setFullReplacement] = useState(true);
    const [checked, setChecked] = useState(false);
    const [replace, setReplaced] = useState(false);
    const [options, setOptions] = useState(false);
    useEffect(() => {
        const compensationReasonURL = "/api/order-service/compensation/reasons";
        RestService.getRequest(compensationReasonURL, (data) => setCompensationReasonOptions(data.reasons), (err) => console.log(err));

        const returnReasonURL = "/api/order-service/return/reasons";
        RestService.getRequest(returnReasonURL, (data) => setReturnReasonOptions(data.reasons), (err) => console.log(err));

        const replaceReasonURL = "/api/order-service/replacement/reasons";
        RestService.getRequest(replaceReasonURL, (data) => setReplacementReasonOptions(data.reasons), (err) => console.log(err));
    }, []);
    const onSubmit = () => {
        if (action == 'Create') {
            noteData.note = note;
            noteData.attachment = file;
  
            createNote(noteData);
        } else if (action == 'Cancel') {
            const itemsToCancel: any[] = [];
            orderData.tableContent.map((data: any, index: any) => {
                if (data.selected == true) {
                    const item = {
                        orderItemId: data.orderItemId,
                        quantity: data.cancelQuantity,
                        reason: data.reason
                    };
                    itemsToCancel.push(item);
                }

            });
            const cancelItems = {
                itemsToCancel: itemsToCancel,
                note: note,
            }
            //cancelOrder(cancelItems);
        } else if (action == 'Compensation') {
            const object = {
                "note": note,
                "reason": selectedCompensationReason,
                "amount": compensationAmount,
            }
            compensationRequest(object);
        } else if (action == 'Return') {
            const itemsToReturn: any[] = [];
            orderData.tableContent.map((data: any, index: any) => {
                if (data.selected == true) {
                    const item = {
                        orderItemId: data.orderItemId,
                        quantity: data.cancelQuantity,
                        reason: data.reason
                    };
                    itemsToReturn.push(item);
                }
            });
            const returnItems = {
                itemsToReturn: itemsToReturn,
                note: note,
            }
            returnRequest(returnItems);
        } else if (action == 'Replacement') {
            const lineItems: any[] = [];
            orderData.tableContent.map((data: any, index: any) => {
                if (data.selected == true) {
                    const item = {
                        itemId:data.orderItemId,
                        newProductNumber: data.newProduct,
                        qty: parseInt(data.replaceQuantity),
                        reason: data.reason,
                        // note: data.notes, 
                    };
                    lineItems.push(item);
                }
            });
            const replaceItems = {
                lineItems: lineItems,
            }
            replacementRequest(replaceItems);
        }
        onClear();
    }
    const onClear = () => {
        setCanShow(false);
        setOptions(false);
        if (action == 'Create') {
            setNote('');
            setFile('');
            setErrorMessage([]);
        } else if (action == 'Cancel' || action == 'Return' || action == 'Replacement') {
            orderData.tableContent.map((data: any) => {
                data.selected = false;
                data.cancelQuantity = 0;
                data.reason = '';
                data.notes = '';
                data.checked = false;
                data.newProductNumber = '';
                data.newProduct ='';

            })
            setNote('');
            setCancelSubmitBoolean(true);
            setFullReplacement(true);
        } else if (action == 'Compensation') {
            setNote('');
            setSelectedCompensationReason(' ');
            setCompensationAmount(0);
        }
    }
    const setNewProducts = (productNumber:any,order:any) =>{
        order.newProduct = productNumber;
        const errorCheck = [];
        if(productNumber.substr(0,2) == "TV"){
            setErrorMessage([]);
        } else {
            errorCheck.push("Please enter a valid TV number");
            setErrorMessage(errorCheck);
        }
        
        cancelSubmitBooleanCheck();
    }
    const fileUpload = (event: any) => {
        setFile(''); setErrorMessage([]);
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // const check = ['jpg', 'jpeg', 'png', 'pdf'];
            // || !check.includes(fileExtension.toLowerCase())
            const fileExtension = selectedFile.name.split('.').pop();
            if (selectedFile.size > 1e7) {
                const errCheck = [];
                if (selectedFile.size > 1e7) errCheck.push('File size should be 10 MB or less!');
                // if (!check.includes(fileExtension)) errCheck.push("Invalid file extension '" + fileExtension + "', Please upload file formats of type pdf,jpeg,png,jpg");
                setErrorMessage(errCheck);
            }
            else {
                setFile(event.target.files[0]);
            }
        }
    }
    const onSelectCheck = (order: any, index: any) => {
        orderData.tableContent[index].selected = !orderData.tableContent[index].selected;
        cancelSubmitBooleanCheck();      
        setReplaced(orderData.tableContent[index].selected);
        // if(orderData.tableContent[index].selected == false){
        //     orderData.tableContent[index].replaceQuantity = 0;
        //     order.replaceQuantity = 0;
        // }  else {
        //     orderData.tableContent[index].replaceQuantity = orderData.tableContent[index].totalQuantity;
        // }
        if(replace === true){
            setReplaced(false);
        }  else {
            setReplaced(true);
        }
    }

    const setFilter = (e: any, index:any, order: any) =>{
        setChecked(e);
        orderData.tableContent[index].checked = e;
        if(checked === true){
            setChecked(false);
        }  else {
            setChecked(true);
        }        
    };


    useEffect(()=>{
    },[checked, replace])

    const numberCheck = (index: any, e: any) => {
        orderData.tableContent[index].replaceQuantity = e.target.value > orderData.tableContent[index].quantity ? orderData.tableContent[index].quantity : e.target.value;
        e.target.value = orderData.tableContent[index].replaceQuantity;
        cancelSubmitBooleanCheck();
    }
    const cancelReasonUpdate = (index: any, e: any) => {
        orderData.tableContent[index].reason = e.target.value;
        cancelSubmitBooleanCheck();
    }
    const amountCheck = (e: any) => {
        var decimal_pos = e.target.value.indexOf("."); let value: any;
        if (e.target.value.indexOf(".") >= 0) {
            var beforeDecimal = e.target.value.substring(0, decimal_pos);
            var afterDecimal = e.target.value.substring(decimal_pos);
            beforeDecimal = beforeDecimal.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            afterDecimal = afterDecimal.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            afterDecimal = afterDecimal.substring(0, 2);
            value = beforeDecimal + "." + afterDecimal;
        } else {
            value = e.target.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        e.target.value = value;
        value = value.replaceAll(',', "");
        setCompensationAmount(value);
    }

    const onSearchClick = () => {
        console.log("searched")
      };

    const cancelSubmitBooleanCheck = () => {
        setCancelSubmitBoolean(true);
        const checkList = orderData.tableContent.filter((x: any) => x.selected == true);
        const selectedLength = checkList.length;
        let checkedLength = 0;
        checkList.map((data: any, index: any) => {
            if (!!data.selected && data.replaceQuantity > 0 && data.reason && data.reason != "" && data.newProduct != "" && data.newProduct && errorMessage.length == 0) {
                checkedLength++;
            } else {
            }
        });
        selectedLength == checkedLength && selectedLength > 0 ? setCancelSubmitBoolean(false) : setCancelSubmitBoolean(true);
    }
    const onCancelOrder = () => {
        cancelOrder();
        setCanShow(false);
    }
    if (action == 'Create' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <h3>Create Note</h3>
                <div>
                    <label className="labelStyle"> Note: </label>

                    <textarea
                        name="note"
                        className="Note"
                        placeholder="Enter notes"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        autoComplete="off"
                        // rows={5}
                        // cols={45}
                        style={{ marginLeft: '50px', overflowY: 'scroll',
                        height: '150px',
                        resize: 'none',width:"80%" }}
                    />
                </div>
                <div style={{ paddingTop: '10px' }}>
                    <label className="labelStyle"> Attachment: </label>
                    <div style={{ marginLeft: '120px' }}>
                        <input
                            type="file"
                            onChange={(e) => fileUpload(e)}
                            // accept=".jpeg,.png,.jpg,.pdf" 
                            />
                        {errorMessage && (
                            errorMessage.map((e: any) =>
                                <p className="error"> {e} </p>
                            ))}

                    </div>

                </div>
                <div className="action">
                <button onClick={onSubmit} style={{marginRight:"5px"}} disabled={false}>
                        Submit&nbsp;
                    </button>&nbsp;
                    <button style={{marginRight:"5px"}} onClick={onClear}>Close&nbsp;</button>
                </div>
            </Modal>
        );
    }
    if (action == 'Cancel' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
            <div style={{ marginBottom: '10px' }}>
                Do you want to cancel order ?
                {/* <div>
                    <input type="radio" id="full" name="cancel" value="full"
                          onClick={() =>setFullReplacement(true)} style = {{marginTop: '10px', cursor: "pointer"}}/>
                        <label htmlFor="full">Full Replacement</label>
                </div> */}
                    {/* <div onClick={() => {setFullReplacement(false);setOptions(true)}}>
                        <input type="radio" id="partial" name="cancel" value="cancel"  style = {{marginTop: '10px', cursor: "pointer"}}/>
                            <label htmlFor="partial">Replacement</label>
                    </div>

                    <div onClick={() =>{setFullReplacement(true); setOptions(true) }}>
                        <input type="radio" id="cancel" name="cancel" value="cancel"  style = {{marginTop: '10px', cursor: "pointer"}}/>
                            <label htmlFor="partial">Full Cancellation</label>
                    </div> */}
            </div>

            {/* { !fullReplacement && <div>
            <h3>Order List</h3>
            <div className="CustomList">
                <table>
                    <thead>
                        <tr>
                            {orderData.columnHeadings.map((heading: ColumnHeadings) => (
                                <th style={{ paddingRight: '10px' }}>
                                    {heading.displayName}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {orderData?.tableContent?.map((order: CustomObjectType, index: number) => {
                            return (
                                <tr key={order["id"]}>
                                    {orderData.columnHeadings.map((heading: ColumnHeadings) => (
                                        heading.displayName == 'Selected' ?
                                            <td><input
                                                type="checkbox"
                                                value={orderData.tableContent[index].selected}
                                                onChange={() => onSelectCheck(order, index)}
                                            /></td> :
                                            heading.displayName == 'CancelQuantity' ?
                                                <td> <input type='number' min='1' max={orderData.tableContent[index].quantity} style={{ width: '80%' }}
                                                    onChange={(e) => numberCheck(index, e)} placeholder="Required"
                                                /> </td> :
                                                heading.displayName == 'Reason' ?
                                                    <td>
                                                        <select onChange={(e) => cancelReasonUpdate(index, e)} required>
                                                            <option value=" " disabled selected hidden>Required</option>
                                                            {replacementReasonOptions.map((option: any) => (
                                                                <option key={option.id} value={option.value}>
                                                                    {option.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td> :
            
                                                    heading.displayName == 'Search for Different Product' ?
                                                    <td><input
                                                        type="checkbox"
                                                        value={orderData.tableContent[index].checked} 
                                                        // defaultChecked={orderData.tableContent[index].checked}
                                                        onChange={() => setFilter(!orderData.tableContent[index].checked, index, order)}
                                                    />
                                                   { !!orderData.tableContent[index].checked  &&
                                                   <>
                                                    <input type="text" onClick={onSearchClick}/>
            
                                                    <span style={{position: "relative",right: '17px'}} className="fa fa-search"></span>
                                                   </> 
                                                    }
                                                    </td> :
                                                    <td key={heading.id} style={{ paddingRight: '10px' }}>
                                                        {order[heading.propertyName]}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="action">
                <button onClick={onClear}>Close</button>&nbsp;
                <button onClick={onSubmit} disabled={cancelSubmitBoolean}>
                    Submit&nbsp;
                </button>
            </div>
            </div>} */
            } 
            { !options && <div className="action">
                <button onClick={onCancelOrder} style={{ padding: '7px 12px' }}>
                    Cancel
                </button>&nbsp;&nbsp;
                <button onClick={onClear} style={{ padding: '7px 12px' }}>Close</button>
            </div> }
            </Modal>
        );
    }
    if (action == 'Compensation' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <h3>Compensation Request</h3>
                <div>
                    <div style={{ paddingTop: '10px' }}>
                        <label className="labelStyle" >Amount:</label>
                        <span style={{ marginLeft: '75px' }}>$<input type="text" placeholder="Required"
                            onChange={(e) => amountCheck(e)} /></span>
                        <label className="labelStyle" style={{ marginLeft: '15px' }}> Reason: </label>
                        <select onChange={(e) => setSelectedCompensationReason(e.target.value)} style={{ marginLeft: '85px' }} required>
                            <option value=" " disabled selected hidden>Required</option>
                            {compensationReasonOptions.map((option: any) => (
                                <option key={option.id} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </select>

                    </div>

                    <div style={{ paddingTop: '10px' }}>
                        <label className="labelStyle"> Note: </label>
                        <textarea name="note" className="Note" placeholder="Enter notes" value={note}
                            onChange={(e) => setNote(e.target.value)}
                            autoComplete="off"
                            rows={5}
                            cols={45}
                            style={{ marginLeft: '50px' }}
                        />
                    </div>
                </div>
                <div className="action">
                    <button onClick={onClear}>Close</button>&nbsp;
                    <button onClick={onSubmit} disabled={selectedCompensationReason === " " || !(compensationAmount > 0)}>
                        Submit&nbsp;
                    </button>
                </div>
            </Modal>
        );
    }
    if (action == 'Return' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <h3>Return Request</h3>
                <div className="CustomList">
                    <table>
                        <thead>
                            <tr>
                                {orderData.columnHeadings.map((heading: ColumnHeadings) => (
                                    heading.displayName != 'CancelQuantity' ? <th style={{ paddingRight: '10px' }}>
                                        {heading.displayName}
                                    </th> :
                                        <th style={{ paddingRight: '10px' }}>
                                            ReturnQuantity
                                        </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {orderData?.tableContent?.map((order: CustomObjectType, index: number) => {
                                return (
                                    <tr key={order["id"]}>
                                        {orderData.columnHeadings.map((heading: ColumnHeadings) => (
                                            heading.displayName == 'Selected' ?
                                                <td><input
                                                    type="checkbox"
                                                    value={orderData.tableContent[index].selected}
                                                    onChange={() => onSelectCheck(order, index)}
                                                /></td> :
                                                heading.displayName == 'CancelQuantity' ?
                                                    <td> <input type='number' min='1' max={orderData.tableContent[index].quantity} style={{ width: '80%' }}
                                                        onChange={(e) => numberCheck(index, e)} placeholder="Required"
                                                    /> </td> :
                                                    heading.displayName == 'Reason' ?
                                                        <td>
                                                            <select onChange={(e) => cancelReasonUpdate(index, e)} required>
                                                                <option value=" " disabled selected hidden>Required</option>
                                                                {returnReasonOptions.map((option: any) => (
                                                                    <option key={option.id} value={option.value}>
                                                                        {option.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td> :
                                                        <td key={heading.id} style={{ paddingRight: '10px' }}>
                                                            {order[heading.propertyName]}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <br></br>
                    <div>
                        <label className="labelStyle"> Note: </label>
                        <textarea
                            name="note"
                            className="Note"
                            placeholder="Enter notes"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            autoComplete="off"
                            rows={5}
                            cols={60}
                            style={{ marginLeft: '50px' }}
                        />
                    </div>
                </div>

                <div className="action">
                    <button onClick={onClear}>Close</button>&nbsp;
                    <button onClick={onSubmit} disabled={cancelSubmitBoolean}>
                        Submit&nbsp;
                    </button>
                </div>
            </Modal>
        );
    }
    if (action == 'Replacement' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <h3>Replacement Request</h3>
                <div className="CustomList">
                    <table>
                        <thead>
                            <tr>
                                {orderData.columnHeadings.map((heading: ColumnHeadings) => (
                                    <th style={{ paddingRight: '10px' }}>
                                        {heading.displayName}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {orderData?.tableContent?.map((order: CustomObjectType, index: number) => {
                                return (
                                    <>
                                    <tr key={order["id"]}>
                                        {orderData.columnHeadings.map((heading: ColumnHeadings) => (
                                            heading.displayName == 'Selected' ?
                                                <td><input
                                                    type="checkbox"
                                                    value={orderData.tableContent[index].selected}
                                                    onChange={() => onSelectCheck(order, index)}
                                                /></td> :
                                                heading.displayName == 'ReplaceQuantity' ?
                                                    <td>                                                    
                                                  <input type='number' min='1' max={orderData.tableContent[index].quantity} style={{ width: '80%' }}
                                                    onChange={(e) => numberCheck(index, e)} placeholder="Required"
                                                />
                                                    </td> :
                                                    heading.displayName == 'Reason' ?
                                                        <td>
                                                            <select onChange={(e) => cancelReasonUpdate(index, e)} required>
                                                                <option value=" " disabled selected hidden>Required</option>
                                                                {replacementReasonOptions.map((option: any) => (
                                                                    <option key={option.id} value={option.value}>
                                                                        {option.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td> :
                                                        
                                                    heading.propertyName == 'newProduct' ?
                                                    <td>
                                                        {/* <input
                                                        type="checkbox"
                                                        value={orderData.tableContent[index].checked} 
                                                        // defaultChecked={orderData.tableContent[index].checked}
                                                        onChange={() => setFilter(!orderData.tableContent[index].checked, index, order)}
                                                    />
                                                   { !!orderData.tableContent[index].checked  &&
                                                   <>
                                                    <input type="text" onClick={onSearchClick}/>
            
                                                    <span style={{position: "relative",right: '17px'}} className="fa fa-search"></span>
                                                   </> 
                                                    } */}
                                                    <input type="text" required value ={orderData.newProduct} onChange={(e) => setNewProducts(e.target.value, order) }/>
                                                    </td>
                                                    :
                                                            <td key={heading.id} style={{ paddingRight: '10px' }}>
                                                                {order[heading.propertyName]}</td>
                                        ))}
                                    </tr>
                                   
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                    <br></br>
                </div>
                { !!errorMessage && <div>
                                    <span style={{color:"red", backgroundColor:"#fff", border:0 }}>{errorMessage}</span>
                                    </div>}
                <div className="action">
                    <button onClick={onClear}>Close</button>&nbsp;
                    <button onClick={onSubmit} disabled={cancelSubmitBoolean}>
                        Submit&nbsp;
                    </button>
                </div>
            </Modal>
        );
    }
    return null;
};

export default ModalSection;