import DropdownOptions from "interfaces/DropdownOptions";
import IProps from "interfaces/IProps";
import 'font-awesome/css/font-awesome.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props extends IProps {
  dropdownOptions: DropdownOptions[];
  selectedDropdownId: number;
  searchValue: string;
  startDate: any;
  endDate: any;
  onDropdownChange: (e: any) => void;
  onSearchValueChange: (e: any) => void;
  onSearchClick: () => void;
  onDateChange: (e: any) => void;
}

const SearchSection = ({
  dropdownOptions,
  selectedDropdownId,
  searchValue,
  startDate,
  endDate,
  onDropdownChange,
  onSearchValueChange,
  onSearchClick,
  onDateChange,
}: Props) => {
  return (
    <div className="SearchSection">
      <div className="input">
        <select value={selectedDropdownId} onChange={onDropdownChange}>
          {dropdownOptions.map((option: DropdownOptions) => (
            <option key={option.id} value={option.id}>
              {option.displayName}
            </option>
          ))}
        </select>
        {selectedDropdownId != 10 && <input
          type="text"
          className="search"
          placeholder="Value"
          value={searchValue}
          onChange={onSearchValueChange}
        />}
        {selectedDropdownId == 10 &&
          <DatePicker
            dateFormat='yyyy/MM/dd'
            placeholderText=" Start Date - End Date"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            isClearable={true}
          />
        }
      </div>
      <div className="action search">
        <button onClick={onSearchClick}>
          Search&nbsp;
          <span className="fa fa-search"></span>
        </button>
      </div>
    </div >
  );
};

export default SearchSection;
