import LogIn from "components/LogIn";
import LogOut from "components/LogOut";
import FindOrder from "pages/FindOrder/FindOrder";
import RetailerUploadPage from "pages/RetailerPage/RetailerUploadPage";
import RetailerTabs from "pages/RetailerPage/RetailerTabs";
import { ComponentType } from "react";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import OrderDetailsPage from "../pages/OrderDetailsPage/OrderDetailsPage";
import BookingDetailsPage from "../pages/BookingDetailsPage/BookingDetailsPage";

import OrdersPage from "../pages/OrdersPage/OrdersPage";
import ReportsPage from "../pages/ReportsPage/ReportsPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import SupplierInvoicesPage from "../pages/SupplierInvoicesPage/SupplierInvoicesPage";
import InstallerOptions from "../pages/InstallerOptions/InstallerOptions";

import ReturnsPage from "../pages/ReturnsDetailsPage/ReturnsPage";
import RefundsPage from "pages/RefundDetailsPage/RefundsPage";
import LegalParentsPageList from "pages/LegalParentPage/LegalParentList";
import PpordersPage from "pages/PpOrderDetails/PpordersPage";
import RmsDataPage from "pages/RmsDataPage/RmsDataPage";

interface RouteListType {
  path: string;
  component: ComponentType<any>;
}

const routesList: RouteListType[] = [
  // { path: "/dashboard", component: DashboardPage },
  { path: "/orders/find-order", component: FindOrder },
  { path: "/installers/find-installer", component: InstallerOptions },
  { path: "/orders/:id", component: OrderDetailsPage },
  { path: "/bookingdetails/:id/:id2", component: BookingDetailsPage },

  { path: "/orders", component: OrdersPage },
  { path: "/supplier-invoices", component: SupplierInvoicesPage },
  { path: "/reports", component: ReportsPage },
  { path: "/settings", component: SettingsPage }, 
  
   { path: "/retailerupload/installers/:id", component: RetailerUploadPage },
   { path: "/installers/:id", component: RetailerTabs },

   { path: "/returns", component: ReturnsPage },
   { path: "/returns/:id", component: OrderDetailsPage },

   { path: "/refunds", component: RefundsPage },
   { path: "/refunds/:id", component: OrderDetailsPage },

   { path: "/payment-group-list", component: LegalParentsPageList },
   { path: "/rms-data", component: RmsDataPage }, // TODO: enable rms-bulk-upload here

   { path: "/installBookings", component: PpordersPage },
{ path: "/installBookings/:id", component: OrderDetailsPage },

  { path: "/log-in", component: LogIn },
  { path: "/log-out", component: LogOut },
  { path: "*", component: NotFoundPage },
];

export default routesList;
