import CustomObjectType from "interfaces/CustomObjectType";
import FilterSpec from "interfaces/FilterSpec";
import ColumnHeadings from "interfaces/ColumnHeadings";
import IProps from "interfaces/IProps";
import { useEffect, useState } from "react";
import "./FilterField.css";

interface Props extends IProps {
  columnHeading: ColumnHeadings;
  checkedStatus: any;
  setCheckedStatus: (data: CustomObjectType) => void;
  toggleCheckbox: (filterOption: string, propertyName: string, columnHeading: any) => void;
}

const FilterField = ({ columnHeading, checkedStatus, setCheckedStatus, toggleCheckbox }: Props) => {
  const [isExpanded, setExpandedStatus] = useState(false);

  const toggleExpandStatus = () => {
    setExpandedStatus((prevExpandedStatus) => !prevExpandedStatus);
  };

  return (
    <li className="FilterField">
      <div
        className={`filter-field-heading ${isExpanded ? "bottom-border" : ""}`}
        onClick={toggleExpandStatus}
      >
        <span className="fa fa-angle-down" />
        <span>{columnHeading.displayName}</span>
      </div>
      {!!isExpanded && (
        <div className="filter-field-content">
          {columnHeading.filterOptions.map((filterOption) => (
            <div className="filter-field-options" key={filterOption}>
              <input
                type="checkbox"
                name={filterOption}
                id={filterOption}
                value={filterOption}
                onChange={() => toggleCheckbox(filterOption, columnHeading.internalPropertyName, columnHeading)}
                checked={!!checkedStatus[filterOption]}
              />
              <label htmlFor={filterOption}>{filterOption}</label>
              <br />
            </div>
          ))}
        </div>
      )}
    </li>
  );
};

export default FilterField;
