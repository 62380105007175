import { useEffect, useState } from "react";
import IProps from "interfaces/IProps";
import Modal from 'react-modal';
import { notEqual } from "assert";
import "./ModalSection.css";
import RestService from "services/RestService";
import ColumnHeadings from "interfaces/ColumnHeadings";
import CustomObjectType from "interfaces/CustomObjectType";
interface Props extends IProps {
    canShow: any;
    action: any;
    setCanShow: (e: any) => void;
    setProcessedImageData: (e: any) => void;
    processedImageData: any;
    selectedDropdown: any;
    setImage: (e: any) => void;
    getInstallerData: () => void;
    installerData: any
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: 'max-content',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const ModalSection = ({ canShow, action, setCanShow, processedImageData, setProcessedImageData, selectedDropdown, setImage, getInstallerData,
    installerData }: Props) => {
    const [compressionRatio, setCompressionRatio] = useState<number>(0.9);
    const onSubmit = () => {
        onClear();
    }
    const onClear = () => {
        //setImage(null);
        //getInstallerData();
        setCanShow(false);
    }

    const onConfirmUpload = () => {
        let formData = new FormData();
        formData.append("retailerId", processedImageData?.retailerId);
        formData.append("accountNo", installerData?.parentCompany?.accountNumber);
        formData.append("compression", compressionRatio.toString());
        formData.append("saveImage", "true");
        formData.append("imageType", selectedDropdown?.propertyName ? selectedDropdown?.propertyName : "");
        formData.append("originalImageName", processedImageData?.originalImage?.name);
        formData.append("processedImageName", processedImageData?.processedImage?.name);
        formData.append("fallbackImageName", processedImageData?.fallbackImage?.name);

        const successCallback = (data: any) => {
            setImage(null);
            getInstallerData();
            setCanShow(false);
            // setIsLoading(false);
        };
        const url = "/api/installer-service/installers/images";
        //TODO: Manage console.error
        fetch(url, {
            headers: {
                Authorization: "Bearer " + RestService.oauthAccessToken
            },
            method: 'POST',
            body: formData
        })
            .then((result) => {
                if (result.status === 401) {
                    window.location.reload();
                }
                return result.json();
            })
            .then((response) => {
                successCallback(response);
            })
            .catch((error) => { console.log(error); });
    }

    const onCompressFurther = () => {
        let formData = new FormData();
        formData.append("retailerId", processedImageData?.retailerId);
        formData.append("accountNo", installerData?.parentCompany?.accountNumber);
        formData.append("imageType", selectedDropdown?.propertyName ? selectedDropdown?.propertyName : "");
        formData.append("compression", compressionRatio.toString());
        formData.append("saveImage", "false");
        formData.append("originalImageName", processedImageData?.originalImage?.name);
        formData.append("processedImageName", processedImageData?.processedImage?.name);
        formData.append("fallbackImageName", processedImageData?.fallbackImage?.name);

        const successCallback = (data: any) => {
            setProcessedImageData(data);
            console.log("Uploaded successfully. Data: ", data);
            // setIsLoading(false);
        };
        const url = "/api/installer-service/installers/images";
        //TODO: Manage console.error
        fetch(url, {
            headers: {
                Authorization: "Bearer " + RestService.oauthAccessToken
            },
            method: 'POST',
            body: formData
        })
            .then((result) => {
                if (result.status === 401) {
                    window.location.reload();
                }
                return result.json();
            })
            .then((response) => {
                successCallback(response);
            })
            .catch((error) => { console.log(error); });
    }

    const fileUpload = (event: any) => {
        // setFile(''); setErrorMessage([]);
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const check = ['jpg', 'jpeg', 'png', 'pdf'];
            const fileExtension = selectedFile.name.split('.').pop();
            if (selectedFile.size > 1e6 || !check.includes(fileExtension.toLowerCase())) {
                const errCheck = [];
                if (selectedFile.size > 1e6) errCheck.push('File size should be 1 MB or less!');
                if (!check.includes(fileExtension)) errCheck.push("Invalid file extension '" + fileExtension + "', Please upload file formats of type pdf,jpeg,png,jpg");
            }
            else {
                // setFile(event.target.files[0]);
            }
        }
    }

    const onCompressionRatioChange = (event: any) => {
        console.log('event', event.target.value);
        setCompressionRatio(event.target.value);
    }
    return (
        <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
            <h3 style={{ textDecorationLine: "underline" }}>Image/Logo Upload</h3>
            <div>
                <span>Original Image ({processedImageData?.originalImage?.size} Kb)</span>
                <br />
                <img height='129' width='229' src={processedImageData?.originalImage?.url} />
                <br />
                <span>Processed Image ({processedImageData?.processedImage?.size} Kb)</span>
                <br />
                <img height='129' width='229' src={processedImageData?.processedImage?.url} />
                {/* <br />
                <span>Fallback Image ({processedImageData?.fallbackImage?.size} Kb)</span>
                <br />
                <img height='129' width='229' src={processedImageData?.fallbackImage?.url} /> */}

                <div className="action">
                    <div style={{ marginBottom: "2em" }}>
                        <label>Compression ratio: </label>
                        <select name="compressionRatio" id="compressionRatio" onChange={onCompressionRatioChange} defaultValue="0.9">
                            <option value="0.1">0.1</option>
                            <option value="0.2">0.2</option>
                            <option value="0.3">0.3</option>
                            <option value="0.4">0.4</option>
                            <option value="0.5">0.5</option>
                            <option value="0.6">0.6</option>
                            <option value="0.7">0.7</option>
                            <option value="0.8">0.8</option>
                            <option value="0.9">0.9</option>
                            <option value="1.0">1.0</option>
                        </select>
                    </div>
                    <button onClick={onConfirmUpload}>Confirm Upload</button>
                    &nbsp;
                    <button onClick={onCompressFurther}> Compress Further&nbsp;</button>
                    &nbsp;
                    <button onClick={onClear}>Cancel</button>
                    &nbsp;
                </div>
            </div>
        </Modal>
    );

};

export default ModalSection;