import { useEffect, useState } from "react";
import IProps from "interfaces/IProps";
import Modal from 'react-modal';
import { notEqual } from "assert";
import "./ModalSection.css";
import RestService from "services/RestService";
import ColumnHeadings from "interfaces/ColumnHeadings";
import CustomObjectType from "interfaces/CustomObjectType";
interface Props extends IProps {
    canShow: any;
    action: any;
    noteData: any;
    orderData: any;
    setCanShow: (e: any) => void;
    saveBooking: () => void;
    cancelBooking: () => void;
    compensationRequest: (e: any) => void;
    returnRequest: (e: any) => void;
    replacementRequest: (e: any) => void;
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: 'max-content',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const ModalSection = ({ canShow, action, noteData, orderData, setCanShow,
    saveBooking, cancelBooking,  }: Props) => {
    const [note, setNote] = useState('');
    const [file, setFile] = useState('');
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [cancelReasonOptions, setCancelReasonOptions] = useState<string[]>([" "]);
    const [compensationReasonOptions, setCompensationReasonOptions] = useState(['']);
    const [selectedCompensationReason, setSelectedCompensationReason] = useState<string>(' ');
    const [compensationAmount, setCompensationAmount] = useState<number>(0);
    const [cancelSubmitBoolean, setCancelSubmitBoolean] = useState<boolean>(true);
    const [returnReasonOptions, setReturnReasonOptions] = useState(['']);
    const [replacementReasonOptions, setReplacementReasonOptions] = useState(['']);
    useEffect(() => {
        const cancelReasonURL = "/api/order-service/cancel/reasons";
        RestService.getRequest(cancelReasonURL, (data) => setCancelReasonOptions(data.reasons), (err) => console.log(err));

        const compensationReasonURL = "/api/order-service/compensation/reasons";
        RestService.getRequest(compensationReasonURL, (data) => setCompensationReasonOptions(data.reasons), (err) => console.log(err));

        const returnReasonURL = "/api/order-service/return/reasons";
        RestService.getRequest(returnReasonURL, (data) => setReturnReasonOptions(data.reasons), (err) => console.log(err));

        const replaceReasonURL = "/api/order-service/replacement/reasons";
        RestService.getRequest(replaceReasonURL, (data) => setReplacementReasonOptions(data.reasons), (err) => console.log(err));

    }, []);
    const onSubmit = () => {
         if (action == 'Cancel') {
            const itemsToCancel: any[] = [];
            orderData.tableContent.map((data: any, index: any) => {
                if (data.selected == true) {
                    const item = {
                        orderItemId: data.orderItemId,
                        quantity: data.cancelQuantity,
                        reason: data.reason
                    };
                    itemsToCancel.push(item);
                }

            });
            const cancelItems = {
                itemsToCancel: itemsToCancel,
                note: note,
            }
            //cancelOrder(cancelItems);
        } else if (action == 'Save') {
            const object = {
                "note": note,
                "reason": selectedCompensationReason,
                "amount": compensationAmount,
            }
            saveBooking();
        } 
        onClear();
    }
    const onClear = () => {
        setCanShow(false);
        if (action == 'Cancel' ) {
            orderData.tableContent.map((data: any) => {
                data.selected = false;
                data.cancelQuantity = 0;
                data.reason = '';
                data.notes = '';
            })
            setNote('');
            setCancelSubmitBoolean(true);
        } 
    }

  
    const cancelSubmitBooleanCheck = () => {
        setCancelSubmitBoolean(true);
        const checkList = orderData.tableContent.filter((x: any) => x.selected == true);
        const selectedLength = checkList.length;
        let checkedLength = 0;
        checkList.map((data: any, index: any) => {
            if (data.replaceQuantity > 0 && data.reason && data.reason != "") {
                checkedLength++;
            } else {
            }
        });
        selectedLength == checkedLength && selectedLength > 0 ? setCancelSubmitBoolean(false) : setCancelSubmitBoolean(true);
    }
    const onCancelBooking = () => {
        cancelBooking();
        setCanShow(false);
    }

    const onSaveBooking = () => {
        saveBooking();
        setCanShow(false);
    }

    if (action == 'Save' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <div style={{ marginBottom: '10px' }}>
                    Do you want to save the booking ?
                </div>
              

                <div className="action">
                    <button onClick={onSaveBooking} style={{ padding: '7px 12px' }}>
                        Save
                    </button>&nbsp;&nbsp;
                    <button onClick={onClear} style={{ padding: '7px 12px' }}>Close</button>
                </div>
            </Modal>
        );
    }
   
    if (action == 'Cancel' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <div style={{ marginBottom: '10px' }}>
                    Do you want to cancel the booking ?
                </div>
              

                <div className="action">
                    <button onClick={onCancelBooking} style={{ padding: '7px 12px' }}>
                        Cancel
                    </button>&nbsp;&nbsp;
                    <button onClick={onClear} style={{ padding: '7px 12px' }}>Close</button>
                </div>
            </Modal>
        );
    }

  

    return null;
};

export default ModalSection;