import ActionBar from "components/ActionBar/ActionBar";
import CustomList from "components/CustomList/CustomList";
import { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import RestService from "services/RestService";
import SortingDirection from "utils/enums/SortingDirection";
import "./OrdersPage.css";
import { COLUMN_HEADINGS } from "utils/constants/OrdersPageColumnHeadings";
import { format } from "date-fns"

const OrdersPage = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalNoOfPages, setTotalNoOfPages] = useState(1);
  const [ordersList, setOrdersList] = useState([]);
  const [sortingStatus, setSortingStatus] = useState({
    sortBy: "createDate",
    sortDirection: SortingDirection.DESC,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [finalFilterArray, setFinalFilterArray] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const onOrderClick = (orderNumber: string) => {
    if (orderNumber && orderNumber !== "") history.push("/orders/" + orderNumber);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [page, sortingStatus, finalFilterArray, searchQuery]);

  useEffect(() => {
    if (!!isLoading) fetchOrderList();
  }, [isLoading]);

  const onFilterChange = (filterArray: any[]) => {
    setFinalFilterArray(filterArray);
  };

  const fetchOrderList = () => {
    const filtersEncodedStr: string = encodeURIComponent(
      JSON.stringify(finalFilterArray)
    );
    const url =
      "/api/order-service/orders?pageNo=" +
      page +
      "&sortBy=" +
      sortingStatus.sortBy +
      "&sortDirection=" +
      sortingStatus.sortDirection +
      "&search=" +
      searchQuery +
      "&filter=" +
      filtersEncodedStr;

    const successCallback = (data: any) => {
      setTotalNoOfPages(data.totalNoOfPages);
      for(let item of data.items){
        if(item["orderType"] == "STH" && item["bookingStatus"] == null ){
          item["bookingStatus"] = "N/A"
        }
      }
      const items = data.items.map((item: any) => {
        const date = format(new Date(item.orderDate), "MMM dd, yyyy HH:mm");
        return {...item, orderDate: date};
      });
      setOrdersList(items);
      setIsLoading(false);
    };

    //TODO: Manage console.error
    RestService.getRequest(url, successCallback, (err) => console.log(err));
  };

  const changeSortingOrder = (headingKey: string) => {
    setSortingStatus((prevSortingStatus) => {
      if (headingKey === prevSortingStatus.sortBy) {
        switch (prevSortingStatus.sortDirection) {
          case SortingDirection.ASC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.DESC,
            };
          case SortingDirection.DESC:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
          default:
            return {
              ...prevSortingStatus,
              sortDirection: SortingDirection.ASC,
            };
        }
      } else {
        return {
          ...prevSortingStatus,
          sortBy: headingKey,
          sortDirection: SortingDirection.ASC,
        };
      }
    });
  };

  const changePage = (newPageNo: number) => {
    setPage(newPageNo);
  };

  const onSearchClick = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  return (
    <div className="OrdersPage Page">
      <ActionBar
        title="Customer Orders"
        onSearchClick={onSearchClick}
        columnHeadings={COLUMN_HEADINGS}
        displayCreateOrderButton={true}
        onFilterChange={onFilterChange}
      />
      {isLoading && <div className="loader"></div>}
      {!isLoading &&
        <CustomList
          columnHeadings={COLUMN_HEADINGS}
          listOfItems={ordersList}
          sortBy={sortingStatus.sortBy}
          sortDirection={sortingStatus.sortDirection}
          totalNoPages={totalNoOfPages}
          currentPage={page}
          changePage={changePage}
          changeSortingOrder={changeSortingOrder}
          onClickOrder={onOrderClick}
        />}
    </div>
  );
};

export default OrdersPage;
