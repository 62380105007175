import OrderDetailsSubSection from "./BookingDetailsSubSection/BookingDetailsSubSection";
import "./BookingDetailsSection.css";
import SubSectionData from "interfaces/SubSectionData";
import IProps from "interfaces/IProps";
import { useState } from "react";
import SubSectionRow from "./BookingDetailsSubSection/SubSectionRow";
import SubSectionRowData from "interfaces/SubSectionRowData";

interface Props extends IProps {
  activeHeader: any;
  subSectionDataList: SubSectionData[];
  setActiveHeader: (e: any) => void;
}

const BookingDetailsSection = ({ subSectionDataList, activeHeader, setActiveHeader }: Props) => {

  const { heading, subSectionRowDataList } = subSectionDataList.filter(
    (subSectionData) => subSectionData.heading === activeHeader
  )[0];

  const changeTab = (newTabKey: string) => {
    setActiveHeader(newTabKey);
  };

  return (
    // <div className="OrderDetailsSection">
    //   {subSectionDataList.map((subSectionData: SubSectionData) => (
    //     <OrderDetailsSubSection
    //       heading={subSectionData.heading}
    //       subSectionDataList={subSectionData.subSectionRowDataList}
    //     />
    //   ))}
    // </div>
    <div className="TabularSection BookingDetailsSection">
      <div className="TabHeaders">
        {subSectionDataList.map((subSectionData) => (
          <div
            className={`Container ${activeHeader === subSectionData.heading ? "active" : ""
              }`}
            onClick={() => changeTab(subSectionData.heading)}>
            {subSectionData.heading}
          </div>
        ))}
      </div>
      <div className="SectionContent">
        <table>
          {subSectionRowDataList.map((subSectionData: SubSectionRowData) => (
            <SubSectionRow subSectionRowData={subSectionData} />
          ))}
        </table>
      </div>
    </div>
  );
};

export default BookingDetailsSection;
