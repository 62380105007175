import { useState } from "react";
import CustomList from "components/CustomList/CustomList";
import CustomObjectType from "interfaces/CustomObjectType";
import ColumnHeadings from "interfaces/ColumnHeadings";
import "./SupplierInvoicesPage.css";
import SortingDirection from "utils/enums/SortingDirection";
import ActionBar from "components/ActionBar/ActionBar";

//TODO Get the below variables from the API
const supplierInvoicesList: CustomObjectType[] = [
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
  {
    type: "Invoice",
    category: "Invoice	Install",
    supplier: "Right Away Tire Corporation",
    orderNumber: "0000695",
    invoiceNumber: "INV11923333",
    date: "11/4/2020",
    dueDate: "11/4/2020",
    invoiceTotal: "405.53",
    status: "Unmatched",
  },
];

const columnHeadings: ColumnHeadings[] = [];

const SupplierInvoicesPage = () => {
  const [sortingStatus, setSortingStatus] = useState({
    sortedByHeadingKey: "",
    sortingOrder: SortingDirection.ASC,
  });
  const [finalFilterArray, setFinalFilterArray] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const changeSortingOrder = (headingKey: string) => {
    setSortingStatus((prevSortingStatus) => {
      if (headingKey === prevSortingStatus.sortedByHeadingKey) {
        switch (prevSortingStatus.sortingOrder) {
          case SortingDirection.ASC:
            return {
              ...prevSortingStatus,
              sortingOrder: SortingDirection.DESC,
            };
          case SortingDirection.DESC:
            return {
              ...prevSortingStatus,
              sortingOrder: SortingDirection.ASC,
            };
          default:
            return {
              ...prevSortingStatus,
              sortingOrder: SortingDirection.ASC,
            };
        }
      } else {
        return {
          ...prevSortingStatus,
          sortedByHeadingKey: headingKey,
          sortingOrder: SortingDirection.ASC,
        };
      }
    });
  };

  const changePage = (newPageNo: number) => {};
  const onSearchClick = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const onFilterChange = (filterArray: any[]) => {
    setFinalFilterArray(filterArray);
  };

  return (
    <div className="SupplierInvoicesPage Page">
      <ActionBar
        onSearchClick={onSearchClick}
        columnHeadings={columnHeadings}
        title="Supplier Invoices"
        onFilterChange={onFilterChange}
      />
      <CustomList
        columnHeadings={columnHeadings}
        listOfItems={supplierInvoicesList}
        sortBy={sortingStatus.sortedByHeadingKey}
        sortDirection={sortingStatus.sortingOrder}
        totalNoPages={10}
        currentPage={5}
        changePage={changePage}
        changeSortingOrder={changeSortingOrder}
      />
    </div>
  );
};

export default SupplierInvoicesPage;
