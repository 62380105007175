import DropdownOptions from "interfaces/DropdownOptions";

const INSTALLER_OPTIONS_PAGE_DROPDOWN_OPTIONS: DropdownOptions[] = [
  {
    propertyName: "locationNumber",
    displayName: "Location Number",
    id: 1,
    databasePropertyName: "locationNumber",
  }
];

export { INSTALLER_OPTIONS_PAGE_DROPDOWN_OPTIONS };
