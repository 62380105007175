import IProps from "interfaces/IProps";

interface Props extends IProps {
  totalNoPages: number;
  currentPage: number;
  changePage: (newPageNo: number) => void;
}

const TOTAL_NO_OF_PAGES_TO_DISPLAY = 10;

const Pagination = ({ totalNoPages, currentPage, changePage }: Props) => {
  return (
    <div className="pagination">
      <div className="pagination-inner">
        {totalNoPages <= TOTAL_NO_OF_PAGES_TO_DISPLAY
          ? Array.from(Array(totalNoPages).keys()).map((page) => (
              <span
                className={`page${currentPage === page + 1 ? " current" : ""}`}
                onClick={changePage.bind(this, page + 1)}
                key={page + 1}
              >
                {page + 1}
              </span>
            ))
          : currentPage <= 6
          ? Array.from(Array(TOTAL_NO_OF_PAGES_TO_DISPLAY).keys()).map(
              (page) => (
                <span
                  className={`page${
                    currentPage === page + 1 ? " current" : ""
                  }`}
                  onClick={changePage.bind(this, page + 1)}
                  key={page + 1}
                >
                  {page + 1}
                </span>
              )
            )
          : Array.from(Array(TOTAL_NO_OF_PAGES_TO_DISPLAY).keys()).map(
              (page) => {
                let pageNoToDisplay = page + (currentPage - 5);
                if (pageNoToDisplay <= totalNoPages)
                  return (
                    <span
                      className={`page${
                        currentPage === pageNoToDisplay ? " current" : ""
                      }`}
                      onClick={changePage.bind(this, pageNoToDisplay)}
                      key={pageNoToDisplay}
                    >
                      {pageNoToDisplay}
                    </span>
                  );
                else return null;
              }
            )}
      </div>
    </div>
  );
};

export default Pagination;
