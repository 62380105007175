import { useEffect, useState } from "react";
import IProps from "interfaces/IProps";
import Modal from 'react-modal';
import { notEqual } from "assert";
import "./ModalPopUp.css";
import RestService from "services/RestService";
import ColumnHeadings from "interfaces/ColumnHeadings";
import CustomObjectType from "interfaces/CustomObjectType";
import TabularSection from "pages/OrderDetailsPage/TabularSection/TabularSection";
import TabularSectionData from "interfaces/TabularSectionData";
import { COLUMN_HEADINGS } from "utils/constants/OrderDetailsPageColumnHeadings";
interface Props extends IProps {
    canShow: any;
    productNumber: any;
    supplierData: any;
    setCanShow: (e: any) => void;
    setProductNumber: (e: any) => void;
    setSupplierData: (e: any) => void;
    toggleShipmentInfo: (e: any) => void;
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: 'max-content',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const ModalPopUp = ({ canShow, productNumber, supplierData, setCanShow, setProductNumber,
    setSupplierData, toggleShipmentInfo }: Props) => {

    const onClear = () => {
        setCanShow(false);
        setProductNumber(0);
    }

    if (supplierData) {
        return <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>

            <div className="action">
                <h3 className='header'>Product number : {productNumber}</h3>
                <div className="CustomList">
                    <table>
                        {supplierData.map((supplierLineItem: CustomObjectType, index: number) => (
                            <div>
                                <table className='tableStyle'>
                                    <thead>
                                        <tr>
                                            {COLUMN_HEADINGS.supplierColumnHeadings.map((heading: ColumnHeadings) => (
                                                <th key={heading.id} className='tableCheck headerStyle'>
                                                    <div>
                                                        {heading.displayName}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {COLUMN_HEADINGS.supplierColumnHeadings.map((heading: ColumnHeadings) => (
                                                <td className='tableCheck' key={heading.id} >
                                                    {supplierLineItem[heading.propertyName]}</td>
                                            ))}
                                            {!supplierData[index].showShipment ?
                                                <td><button onClick={() => toggleShipmentInfo(index)} className="buttonClass" title='Shipment Info'
                                                >+</button></td> : <td><button onClick={() => toggleShipmentInfo(index)} className="buttonClass" title='Shipment Info'
                                                >-</button></td>}
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ display: supplierData[index].showShipment ? 'block' : 'none' }}>
                                    <thead>
                                        <tr>
                                            {COLUMN_HEADINGS.shipmentColumnHeadings.map((heading: ColumnHeadings) => (
                                                <th key={heading.id} className='tableCheck headerStyle'>
                                                    <div>
                                                        {(heading.displayName !== 'TrackingURL' && heading.displayName)}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead><tbody>
                                        {supplierLineItem.shipmentInfos?.map((order: CustomObjectType) => {
                                            return (
                                                <tr key={order["id"]}>
                                                    {COLUMN_HEADINGS.shipmentColumnHeadings.map((heading: ColumnHeadings) => (
                                                        <td className='tableCheck' key={heading.id} >
                                                            {(heading.displayName == 'TrackingNumber' &&
                                                                <div>
                                                                    <a href={`${order['trackingURL']}`} >  {order[heading.propertyName]}</a>
                                                                </div>
                                                            )}

                                                            {(heading.displayName !== 'TrackingNumber'
                                                                && heading.displayName !== 'TrackingURL' && <div>{order[heading.propertyName]}</div>)}
                                                        </td>
                                                    ))}
                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                                <br></br><br></br>
                            </div>
                        ))}
                    </table>
                </div>
                <button style={{ 'float': 'right' }} onClick={onClear}>Close</button>&nbsp;

            </div>
        </Modal>
    }
    else return null;
};

export default ModalPopUp;