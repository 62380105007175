import IProps from "../../../../interfaces/IProps";
import Modal from "react-modal";
import "./ModalReturns.css";
import { useEffect, useState } from "react";
import RestService from "services/RestService";
import { toast } from "react-toastify";

interface Props extends IProps {
  canShow: any;
  setCanShow: (e: any) => void;
  returnData: any;
  orderData: any;
  edit: any;
  afterClose: any;
  setAfterClose: (e: any) => void;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "45%",
    padding: "0px 20px 0 20px",
    // borderRadius:"25px",
    //marginRight: '-50%',
    transform: "translate(-50%, -50%)",
    height: "500px",
  },
};

const ModalPopUpReturns = ({
  canShow,
  setCanShow,
  returnData,
  orderData,
  edit,
  afterClose,
  setAfterClose,
}: Props) => {
  const [reason, setReason] = useState([] as any);
  const [notes, setNotes] = useState("");
  const [methods, setMethods] = useState([] as any);
  const [reasonChosen, setSelectedReason] = useState();
  const [methodChosen, setSelectedMethod] = useState();
  const [cost, setCost] = useState(0);
  const [dataList, setDataList] = useState<any[]>([]);
  const [itemsData, setItemsData] = useState<any[]>([]);
  const [cancelSubmitBoolean, setCancelSubmitBoolean] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>([]);
  const [rmaNumber, setRmaNumber] = useState<any>("");
  const [courierTrackingNumber, setCourierTrackingNumber] = useState<any>("");
  const [returnSpecialist, setReturnSpecialist] = useState<any>("");
  const [returnStatus, setReturnStatus] = useState<any>("ONGOING");
  const [returnFees, setReturnFees] = useState(returnData[0]?.returnFees || 0);

    const statusOptions = [
        "CREATED",
        "LABELS CREATED",
        "LOST IN TRANSIT",
        "COMPLETED WITH REFUND",
        "COMPLETED WITHOUT REFUND",
        "ONGOING",
        "COMPLETE",
        "CANCELLED"
    ];

  useEffect(() => {
    let lineItemsArray: any;
    if (edit === true) {
      returnData &&
        returnData.map((item: any) => {
          lineItemsArray = item.itemsToReturn;
          item.createRefund = true;
          item.reasonChosen = "";
          item.methodChosen = "";
          return item;
        });
      setItemsData(lineItemsArray);
      setDataList(returnData);
    } else {
      orderData.items.map((data: any) => {
        data.reasonChosen = "";
        data.methodChosen = "";
        data.returnQty = 0;
      });
      setItemsData(orderData.items);
    }

    const successCallbackReturns = (response: any) => {
      setReason(response.reasons);
      setMethods(response.methods);
    };
    const urlReturns = "/api/order-service/config/returns";
    RestService.getRequest(urlReturns, successCallbackReturns, (err) =>
      console.log(err),
    );
  }, [dataList, itemsData]);

  const onClear = () => {
    setCanShow(false);
    setCost(0);
    setReturnFees(0);
    setErrorMessage([]);
    setCancelSubmitBoolean(true);
    setAfterClose(true);
    returnData &&
      returnData.map((item: any) => {
        item.createRefund = false;
        item.edit = false;
        item.selected = false;
        item.returnQty = 0;
        return item;
      });
    itemsData[0].methodChosen = "";
    itemsData.map((item: any) => {
      item.returnQty = 0;
      item.selected = false;
    });
    edit = false;
  };

  const onSaveData = () => {
    const purchaseOrderNumber = orderData.orderNumber;
    const itemsToReturn: any[] = [];
    itemsData.map((data: any, index: any) => {
      if (data.selected == true) {
        const item = {
          lineItemId: data.orderItemId.toString(),
          qty: parseInt(data.returnQty),
        };
        itemsToReturn.push(item);
      }
    });

    if (edit) {
      const data = {
        method: methodChosen,
        note: notes,
        returnReason: reasonChosen,
        rmaNumber: rmaNumber.toString(),
        courierTrackingNumber: courierTrackingNumber,
        returnSpecialist: returnSpecialist,
        returnStatus: returnStatus.replaceAll(" ", "_").toUpperCase(),
      };
      const successCallback = () => {
        setCanShow(false);
        setAfterClose(true);
        window.location.reload();
        setCost(0);
        toast.success("Return data updated  successfully ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-success",
        });
      };
      const errorCallback = (err: any) => {
        toast.error("Return data updation failed ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error",
        });
        console.log(err);
      };
      const url =
        "/api/order-service/returns/" +
        returnData[0].returnId +
        "?purchaseOrderNumber=" +
        returnData[0].purchaseOrderNumber;
      RestService.putRequest(url, data, successCallback, (err) =>
        errorCallback(err),
      );
    } else {
      const data = {
        method: methodChosen,
        itemsToReturn: itemsToReturn,
        note: notes,
        reason: reasonChosen,
        returnFees: cost || 0,
        purchaseOrderNumber: purchaseOrderNumber,
      };
      const successCallback = (response: any) => {
        returnData.push({ ...response });
        window.location.reload();
        setAfterClose(true);
        onClear();
      };
      const errorCallback = (err: any) => {
        console.log(err);
      };
      const url =
        "/api/order-service/returns?purchaseOrderNumber=" + purchaseOrderNumber;
      RestService.postRequest(url, data, successCallback, (err) =>
        errorCallback(err),
      );
    }
  };

  const selectedReason = (e: any) => {
    setSelectedReason(e.target.value);
    itemsData[0].reasonChosen = e.target.value;
    if (!!edit) {
      setCancelSubmitBoolean(false);
    } else {
      cancelCheck();
    }
  };

  const editSave = () => {
    const purchaseOrderNumber = returnData[0].purchaseOrderNumber;
    const data = {
      returnReason: reasonChosen,
      note: notes,
      rmaNumber: rmaNumber,
      courierTrackingNumber: courierTrackingNumber,
      returnSpecialist: returnSpecialist,
      returnStatus: returnStatus.replaceAll(' ', '_').toUpperCase() || "ONGOING",
      returnFees: Number(returnFees)
    };
    const successCallback = () => {
      setCanShow(false);
      window.location.reload();
      setCost(0);
      setReturnFees(0);
      setAfterClose(true);
      toast.success("Return data updated  successfully ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-success",
      });
    };
    const errorCallback = (err: any) => {
      toast.error("Return data updation failed ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error",
      });
      console.log(err);
    };

    const postUrl = "/api/order-service/returns/" + returnData[0].returnId;
    RestService.putRequest(postUrl, data, successCallback, (err) =>
      errorCallback(err),
    );
  };

  const setCostValue = () => {
    let totalCost = 0;
    let eachCost = 0;
    let qty = 0;
    const checkList = itemsData.filter((x: any) => x.selected == true);
    for (let fee of methods) {
      if (fee["text"] == itemsData[0].methodChosen) {
        eachCost = fee["cost"];
        break;
      }
    }
    checkList.map((item: any) => {
      totalCost = totalCost + item["returnQty"] * eachCost;
    });

    setCost(totalCost);
  };

  const selectedMethod = (e: any) => {
    itemsData[0].methodChosen = e.target.value;
    setSelectedMethod(e.target.value);
    setCostValue();
    cancelCheck();
  };

  const setReplaceQty = (e: any, qty: any, index: any) => {
    const error = [];
    itemsData[index].returnQty = parseInt(e);
    if (e > qty) {
      error.push("Maximum quantity limit excedded.");
      setErrorMessage(error);
      setCancelSubmitBoolean(true);
      // cancelCheck();
    } else if (e == "" || e == 0 || e <= 0) {
      error.push("Return quantity can not be null.");
      setErrorMessage(error);
      setCost(0);
      setCancelSubmitBoolean(true);
    } else {
      setCostValue();
      setErrorMessage([]);
      setCancelSubmitBoolean(false);
    }
    cancelCheck();
  };

  const selectOptions = (e: any) => {
    setReturnStatus(e.target.value);
  };

  const checkedItem = (item: any, index: any) => {
    itemsData[index].selected = !item.selected;
    const checkList = itemsData.filter((x: any) => x.selected == true);
    // cancelCheck();
    setCostValue();
    if (itemsData[index].selected == false) {
      itemsData[index].returnQty = 0;
      setErrorMessage([]);
      // setCost(0);
    }
    cancelCheck();
  };

  const cancelCheck = () => {
    let error: any;
    setCancelSubmitBoolean(true);
    const checkList = itemsData.filter((x: any) => x.selected == true);
    const selectedLength = checkList.length;
    let checkedLength = 0;
    checkList.map((data: any, index: any) => {
      if (
        !edit &&
        !!data.selected &&
        data.returnQty > 0 &&
        !!itemsData[0].methodChosen &&
        itemsData[0].methodChosen != "" &&
        !!itemsData[0].reasonChosen &&
        itemsData[0].reasonChosen != ""
      ) {
        checkedLength++;
      } else {
      }
    });

    selectedLength == checkedLength && selectedLength > 0
      ? setCancelSubmitBoolean(false)
      : setCancelSubmitBoolean(true);
  };

  const handleEditChanges = (e: any, type: any) => {
    const error = [];
    setCancelSubmitBoolean(false);
    setErrorMessage([]);
    if (type == "rma") {
      if (!!e.target.value && e.target.value > 0) {
        setRmaNumber(e.target.value);
      } else {
        if (e.target.value == "") {
          setCancelSubmitBoolean(false);
          setRmaNumber(e.target.value);
        } else {
          error.push("Please enter a valid RMA Number.");
          setErrorMessage(error);
          setCancelSubmitBoolean(true);
        }
      }
    } else if (type == "returnSpecialist") {
      setReturnSpecialist(e.target.value);
    } else if (type == "trackingNumber") {
      setCourierTrackingNumber(e.target.value);
    } else if (type == "returnFees") {
      setReturnFees(e.target.value);
    }
  };

  return (
    <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
      <>
        {!edit && (
          <div>
            <h3 style={{ textDecorationLine: "underline" }}>Return Request</h3>
            <span
              style={{
                position: "absolute",
                right: "10px",
                top: "15px",
                display: "block",
                fontSize: "12px",
              }}
            >
              *Required Fields
            </span>
            <ul className="containerUl">
              <li>
                <div>
                  <h4>Goods to return*</h4>
                  <li>
                    <div className="fixed-header">
                      <div className="row">
                        <div className="first-column"> Product Description </div>
                        <div className="column"> Product Name </div>
                        <div className="column"> Quantity </div>
                        <div className="column"> Replace Quantity </div>
                      </div>
                    </div>
                  </li>
                  <ul className="orderedData-list">
                    {itemsData &&
                      itemsData.map(({ productNumber, quantity, productName }, index) => {
                        return (
                          <li key={index}>
                            <div className="orderedData-list-item">
                              <div className="first-column">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${index}`}
                                  name={productNumber}
                                  value={itemsData[index].selected}
                                  required
                                  onChange={(e) =>
                                    checkedItem(itemsData[index], index)
                                  }
                                />
                                <label htmlFor={`custom-checkbox-${index}`}>
                                  {productNumber}
                                </label>
                              </div>
                              <div className="column">{productName}</div>
                              <div className="column">{quantity}</div>
                              <div className="column">
                                <input
                                  type="number"
                                  required
                                  value={itemsData[index].returnQty}
                                  onChange={(e) =>
                                    setReplaceQty(
                                      e.target.value,
                                      quantity,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                  {errorMessage && (
                    <p
                      style={{
                        color: "red",
                        backgroundColor: "#fff",
                        border: 0
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}
                </div>
              </li>
              <li>
                <div className="listItems">
                  <h4> Reason for return*</h4>
                  <select
                    className="dropDown"
                    name="selectReturns"
                    onChange={(e) => selectedReason(e)}
                    required
                  >
                    <option disabled selected>
                      {"Select an reason "}
                    </option>
                    {reason.map(function (n: any) {
                      return <option value={n["text"]}>{n["text"]}</option>;
                    })}
                  </select>
                </div>
              </li>
              <li>
                <div className="listItems">
                  <h4>Return Method*</h4>
                  <select
                    className="dropDown"
                    name="selectMethod"
                    onChange={(e) => selectedMethod(e)}
                    required
                  >
                    <option disabled selected>
                      {"Select an method of return "}
                    </option>
                    {methods.map(function (n: any) {
                      return <option value={n["text"]}>{n["text"]}</option>;
                    })}
                  </select>
                </div>
              </li>
              <li>
                <div className="listItems">
                  <h4>Return Fee*</h4>
                  <p>${cost}</p>
                </div>
              </li>
              <li>
                <div>
                  <h4>Notes</h4>
                  <textarea
                    style={{ width: "100%", height: "70px" }}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </li>
            </ul>
            {/* <CustomList
                columnHeadings={ColumnHeadings}
                listOfItems={dataList}
                totalNoPages={1}
                currentPage={1}
                changePage={() => { }}
                changeSortingOrder={() => { }}
            /> */}
            <button
              style={{
                float: "right",
                background: "#fff",
                color: "#0071BC",
                borderRadius: "5px",
                border: "1px solid #0071BC",
              }}
              onClick={onClear}
            >
              Cancel
            </button>
            &nbsp;
            <button
              style={{
                float: "right",
                marginRight: "10px",
                borderRadius: "5px",
              }}
              onClick={onSaveData}
              disabled={cancelSubmitBoolean}
            >
              Save
            </button>
            &nbsp;
          </div>
        )}

        {/* Edit Popup--------------------------- */}

        {!!edit && (
          <div>
            <h3 style={{ textDecorationLine: "underline" }}>Return Request</h3>
            <ul className="containerUl">
              <li>
                <div className="listItems">
                  <h4>Goods to return</h4>
                  <div>
                    <span>Product Description</span>
                    <span style={{ position: "absolute", right: "20px" }}>
                      Replace Quantity
                    </span>
                  </div>
                  <ul className="orderedData-list">
                    {itemsData &&
                      itemsData.map(({ productNumber, qty }, index) => {
                        return (
                          <li key={index}>
                            <div className="orderedData-list-item">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${index}`}
                                  name={productNumber}
                                  value={itemsData[index].selected}
                                  required
                                  defaultChecked={true}
                                  checked
                                />
                                <label htmlFor={`custom-checkbox-${index}`}>
                                  {productNumber}
                                </label>
                              </div>
                              <div className="right-section">{qty}</div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
              <li>
                <div className="listItems">
                  <h4> Reason for return*</h4>
                  <select
                    className="dropDown"
                    name="selectReturns"
                    onChange={(e) => selectedReason(e)}
                    required
                  >
                    <option disabled selected>
                      {"Select an reason "}
                    </option>
                    {reason.map(function (n: any) {
                      return (
                        <option
                          value={n["text"]}
                          selected={reason === n["text"]}
                        >
                          {n["text"]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div
                  style={{ width: "50%", float: "left", marginBottom: "14px" }}
                >
                  <h4>Return Method</h4>
                  <span>{returnData[0].method}</span>
                </div>
                <div className="listItems">
                  <h4>Return Fee $</h4>
                  <input
                    type="text"
                    required
                    value={returnFees}
                    onChange={(e) => handleEditChanges(e, "returnFees")}
                  />
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div
                  style={{ width: "50%", float: "left", marginBottom: "14px" }}
                >
                  <h4>RMA Number</h4>
                  <input
                    type="text"
                    onChange={(e) => handleEditChanges(e, "rma")}
                  />
                  <br></br>
                  {errorMessage && (
                    <span
                      style={{
                        color: "red",
                        backgroundColor: "#fff",
                        border: 0,
                        fontSize: "12px",
                      }}
                    >
                      {errorMessage}
                    </span>
                  )}
                </div>
                <div className="listItems">
                  <h4>Return Specialist</h4>
                  <input
                    type="text"
                    onChange={(e) => handleEditChanges(e, "returnSpecialist")}
                  />
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div
                  style={{ width: "50%", float: "left", marginBottom: "14px" }}
                >
                  <h4>Courier Tracking Number</h4>
                  <input
                    type="text"
                    onChange={(e) => handleEditChanges(e, "trackingNumber")}
                  />
                </div>
                <div className="listItems">
                  <h4>Status</h4>
                  <select
                    className="dropDown"
                    name="selectOptions"
                    onChange={(e) => selectOptions(e)}
                    required
                  >
                    {statusOptions.map(function (n: any) {
                      return (
                        <option value={n} selected={returnStatus === n}>
                          {n}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </li>
              <li>
                <div className="listItems">
                  <h4>Notes</h4>
                  <textarea
                    style={{ width: "100%", height: "70px" }}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </li>
            </ul>
            <button
              style={{
                float: "right",
                background: "#fff",
                color: "#0071BC",
                borderRadius: "5px",
                border: "1px solid #0071BC",
              }}
              onClick={onClear}
            >
              Cancel
            </button>
            &nbsp;
            <button
              style={{
                float: "right",
                marginRight: "10px",
                borderRadius: "5px",
              }}
              onClick={editSave}
              disabled={cancelSubmitBoolean}
            >
              Save
            </button>
            &nbsp;
          </div>
        )}
      </>
    </Modal>
  );
};

export default ModalPopUpReturns;
