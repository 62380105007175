import IProps from "interfaces/IProps";
import Modal from 'react-modal';
import "./BookingModalSection.css";

interface Props extends IProps {
    canShow: any;
    action: any;
    orderData: any;
    setCanShow: (e: any) => void;
    saveBooking: () => void;
    cancelBooking: () => void;
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: 'max-content',
        transform: 'translate(-50%, -50%)',
    },
};
const BookingModalSection = ({canShow, action, orderData, setCanShow, saveBooking, cancelBooking}: Props) => {

    const onClear = () => {
        setCanShow(false);
        if (action == 'Cancel' ) {
            orderData.tableContent.map((data: any) => {
                data.selected = false;
                data.cancelQuantity = 0;
                data.reason = '';
                data.notes = '';
            })
        } 
    }

    const onCancelBooking = () => {
        cancelBooking();
        setCanShow(false);
    }

    const onSaveBooking = () => {
        saveBooking();
        setCanShow(false);
    }

    if (action == 'Save' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <div style={{ marginBottom: '10px' }}>
                    Do you want to save the booking ?
                </div>

                <div className="action">
                    <button onClick={onSaveBooking} style={{ padding: '7px 12px' }}>
                        Save
                    </button>&nbsp;&nbsp;
                    <button onClick={onClear} style={{ padding: '7px 12px' }}>Close</button>
                </div>
            </Modal>
        );
    }

    if (action == 'Cancel' && canShow) {
        return (
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <div style={{ marginBottom: '10px' }}>
                    Do you want to cancel the booking ?
                </div>

                <div className="action">
                    <button onClick={onCancelBooking} style={{ padding: '7px 12px' }}>
                        Cancel
                    </button>&nbsp;&nbsp;
                    <button onClick={onClear} style={{ padding: '7px 12px' }}>Close</button>
                </div>
            </Modal>
        );
    }

    return null;
};

export default BookingModalSection;
