import ColumnHeadings from "interfaces/ColumnHeadings";

const COLUMN_HEADINGS: ColumnHeadings[] = [
    {
        propertyName: "orderNo",
        displayName: "Order Number",
        id: "orderNo",
        internalPropertyName: "orderNo",
        filterOptions: [],
    },
    {
        propertyName: "customerName",
        displayName: "Customer Name",
        id: "Customer Name",
        internalPropertyName: "customerName",
        filterOptions: [],
    },
    {
        propertyName: "bookingReferenceNo",
        displayName: "Booking Reference",
        id: "bookingReferenceNo",
        internalPropertyName: "bookingReferenceNo",
        filterOptions: [],
    },
    {
        propertyName: "bookingDate",
        displayName: "Booking Date",
        id: "bookingDate",
        internalPropertyName: "bookingDate",
        filterOptions: [],
    },
    {
        propertyName: "bookingSlot",
        displayName: "Booking Slot",
        id: "bookingSlot",
        internalPropertyName: "bookingSlot",
        filterOptions: ["AM","PM"],
    },
    {
        propertyName: "bookingStatus",
        displayName: "Booking Status",
        id: "bookingStatus",
        internalPropertyName: "bookingStatus",
         filterOptions: ["CANCELLED", "RESERVED"],
    },
];

export { COLUMN_HEADINGS };