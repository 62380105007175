import CustomObjectType from "interfaces/CustomObjectType";
import ColumnHeadings from "interfaces/ColumnHeadings";
import IProps from "interfaces/IProps";
import "./FilterBox.css";
import FilterField from "./FilterField/FilterField";
import { useEffect } from "react";

interface Props extends IProps {
  columnHeadings: ColumnHeadings[];
  closeFilterBox: () => void;
  onFilterChange: (filterArray: any[]) => void;
  checkedStatus: any;
  setCheckedStatus: (data: CustomObjectType) => void;
  toggleCheckbox: (filterOption: string, propertyName: string, columnHeading: any) => void;
}

//TODO: Add Redux or context to solve the Filter bug
const FilterBox = ({
  columnHeadings,
  closeFilterBox,
  onFilterChange,
  checkedStatus, setCheckedStatus, toggleCheckbox
}: Props) => {
  const onFilterChangeCallback = () => {
    const filterArray: any = [];
    Object.keys(checkedStatus).forEach(
      (key) => {
        if (!!checkedStatus[key]) {
          columnHeadings.forEach(heading => {
            if (heading.filterOptions.includes(key)) {
              filterArray.push([{
                key: heading.internalPropertyName,
                operation: "EQUAL_TO",
                value: key,
              }]);
            }
          })
        }
      });
    let listOfFilterArrays: CustomObjectType = {};
    filterArray.forEach((element: any) => {
      const newListOfFilterArray: CustomObjectType = {
        [element[0].key]: element,
      };
      Object.keys(listOfFilterArrays).forEach(
        (key) =>
        (newListOfFilterArray[key] =
          key === element[0].key ? listOfFilterArrays[key].concat(element) : listOfFilterArrays[key])
      );
      listOfFilterArrays = newListOfFilterArray;
    })
    onFilterChange(Object.values(listOfFilterArrays));
  };
  const onClearFilters = () => {
    setCheckedStatus({});
    onFilterChange(Object.values({}));
    closeFilterBox();
  }

  return (
    <div className="FilterBox">
      <div className="top-bar">
        <span>Filter</span>
        <span className="close-button" onClick={closeFilterBox}></span>
      </div>
      <div className="content-box">
        <ul>
          {columnHeadings.map((columnHeading) =>
            columnHeading.filterOptions.length === 0 ? null : (
              <FilterField
                key={columnHeading.id}
                columnHeading={columnHeading}
                checkedStatus={checkedStatus}
                setCheckedStatus={setCheckedStatus}
                toggleCheckbox={toggleCheckbox}
              />
            )
          )}
        </ul>
      </div>
      <div className="action-bar">
        <button className="cancel-button" onClick={onClearFilters}>
          Reset
        </button>
        <button className="apply-button" onClick={onFilterChangeCallback}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterBox;
