import { useEffect, useState } from "react";
import IProps from "interfaces/IProps";
import Modal from 'react-modal';
import "./ModalPopUp.css";
interface Props extends IProps {
    canShow: any;
    note: any;
    setCanShow: (e: any) => void;
   
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: '50%',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const ModalNotes = ({ canShow,  setCanShow, note }: Props) => {

    const onClear = () => {
        setCanShow(false);
    }

    if (!!canShow) {
        return <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
            <div  style = {{ padding:"5px",overflowY:"scroll",height:'350px'}}>
            <h3 style={{ textDecorationLine:"underline"}}>Notes</h3>
            <div>    
            <p>{note}</p>
            </div>
            <button style={{ 'float': 'right' }} onClick={onClear}>Close</button>&nbsp;
            </div>           
        </Modal>
    }
    else return null;
};

export default ModalNotes;