import ColumnHeadings from "interfaces/ColumnHeadings";

const COLUMN_HEADINGS: ColumnHeadings[] = [
    {
        propertyName: "id",
        displayName: "Id",
        id: "id",
        internalPropertyName: "id",
        filterOptions: [],
    },
    {
        propertyName: "number",
        displayName: "Location Number ",
        id: "number",
        internalPropertyName: "number",
        filterOptions: [],
    },
    {
        propertyName: "name",
        displayName: "Installer Name ",
        id: "name",
        internalPropertyName: "name",
        filterOptions: [],
    }
];

export { COLUMN_HEADINGS };