class RestService {
  public static oauthAccessToken: string;

  public static setOAuthAccessToken(accessToken: string) {
    this.oauthAccessToken = accessToken;
  }

  public static getRequest(
    url: string,
    successCallback: (data: any) => void,
    errorCallback?: (error: any) => void
  ) {
    fetch(url, {
      headers: {
        Authorization: "Bearer " + this.oauthAccessToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => this.tokenValidityCheck(response))
      .then((data) => successCallback(data))
      .catch((error) => (!!errorCallback ? errorCallback(error) : null)); //TODO: Log all API errors together
  }

  public static postRequest(
    url: string,
    data: any,
    successCallback: (data: any) => void,
    errorCallback: (error: any) => void,
  ) {
    fetch(url, {
      headers: {
        Authorization: "Bearer " + this.oauthAccessToken,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then((response) => this.tokenValidityCheck(response))
      .then((data) => successCallback(data))
      .catch((error) => (!!errorCallback ? errorCallback(error) : null)); //TODO: Log all API errors together
  }

  public static putRequest(
    url: string,
    data: any,
    successCallback: () => void,
    errorCallback: (error: any) => void,
  ) {
    fetch(url, {
      headers: {
        Authorization: "Bearer " + this.oauthAccessToken,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status === 401) {
          window.location.reload();
        }
        else if (response.status === 200) successCallback();
        else if(response.status !== 200) errorCallback(response);
      })
      .catch((error) => (!!error ? errorCallback(error) : null)); //TODO: Log all API errors together
  }
  
  public static tokenValidityCheck(response: any) {
    if (response.status === 401) {
      window.location.reload();
    }
    return response.json();
  }
}

export default RestService;
