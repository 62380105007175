import { useState } from "react";
import "./ActionsSection.css";
import ModalSection from "../ModalSection/ModalSection";
import IProps from "interfaces/IProps";
interface Props extends IProps {
  noteData: any,
  orderData: any,
  orderStatus: any,
  bookingsData: any,
  saveBooking: () => void;
  cancelBooking: () => void;
  compensationRequest: (e: any) => void;
  returnRequest: (e: any) => void;
  replacementRequest: (e: any) => void;
}


const ActionsSection = ({
  noteData,
  orderData,
  orderStatus,
  bookingsData,
  saveBooking,
  cancelBooking,
  compensationRequest,
  returnRequest,
  replacementRequest,
}: Props) => {
  const [canShow, setCanShow] = useState(false);
  const [action, setAction] = useState('');

  const onCancelBooking = () => {
    setCanShow(true);
    setAction('Cancel');
  }

  const onSaveBooking = () => {
    setCanShow(true);
    setAction('Save');
  }
 
  return (
    <div className="ActionsSection">
      <div className="Actions">
      <div className="label">Actions:</div>
        {orderStatus && <div className="ActionsRow">
          
        <button className="ActionButton" onClick={onSaveBooking} disabled={orderStatus.toLowerCase() == 'saved'}>Confirm Booking    </button>

          <button className="ActionButton" onClick={onCancelBooking} disabled={orderStatus.toLowerCase() == 'cancelled' || bookingsData.bookingStatus.toLowerCase() == "cancelled"}>Cancel Booking    </button>

        </div>}

      </div>
      <ModalSection
        canShow={canShow}
        action={action}
        setCanShow={setCanShow}
        saveBooking={saveBooking}
        noteData={noteData}
        cancelBooking={cancelBooking}
        orderData={orderData}
        compensationRequest={compensationRequest}
        returnRequest={returnRequest}
        replacementRequest={replacementRequest}
      />
    </div>
  );
};

export default ActionsSection;
